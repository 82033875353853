import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Multiselect from "multiselect-react-dropdown";
import DashboardContext from "../../Context/DashboardContext";
import axios from "axios";
import { apiInstace } from "../../server";
import { toast } from "react-toastify";

function EditModal({ open, handleClose, krishiData, handleUpdate, data, setData }) {
  const [checked, setChecked] = React.useState(false);
  const [checkVideo, setCheckVideo] = React.useState(false);
  const context = useContext(DashboardContext);
  const [cropsData, setCropsData] = React.useState();

  const { crops, setLoading, getCrops, refresh, setRefresh } = context;
  const handleCropFilter = (selectedList, selectedValue, isRemove) => {
    if (!isRemove) {
      let arr = [...data.crops];
      arr.push(selectedValue.id);
      setData({
        ...data,
        crops: arr,
      });
    } else {
      let arr = [...data.crops];
      const newArr = arr.filter((item) => item !== selectedValue.id);
      console.log(newArr);
      setData({
        ...data,
        crops: newArr,
      });
    }
  };

  const handleChange = (event) => {
    setData({ ...data, content_type: "POST" });
    setChecked(event.target.checked);
    setCheckVideo(false);
  };

  const handleChangeVideo = (event) => {
    setData({ ...data, content_type: "VIDEO" });
    setCheckVideo(event.target.checked);
    setChecked(false);
  };

  const mainBody = {
    p: 2,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "#ffffff",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    height: "100%",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
  };

  const handleInput = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const parseCropsData = (data) => {
    let cropArr = [...data.crops];
    const newArr = [];
    cropArr.forEach((item) => {
      let info = crops.filter((crop) => crop.id === item);
      newArr.push(info[0]);
    });

    getCrops();
    setCropsData(newArr);
  };

  const updatePost = () => {
    setLoading(true);
    apiInstace
      .patch(`/post/updatepost/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((result) => {
        toast.success("Updated SuccessFully !");
        setLoading(false);
        handleClose();
        setRefresh(!refresh);
      })
      .catch((err) => {
        toast.error("Something Went wrong");
        setLoading(false);
        setRefresh(!refresh);
      });
  };

  useEffect(() => {
    if (data) {
      parseCropsData(data);
    }
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className='modal-head krishi-modal-head'>
            <p className='modal-title'>Create New Post</p>
            <button className='close-button-krishi' onClick={handleClose}>
              X
            </button>
          </div>
          <Box sx={mainBody}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <p className='Customer-info'>Brand Name</p>
                <input
                  placeholder='Enter Brand Name'
                  className='product-input'
                  style={{ width: "350px", margin: "5px 0px" }}
                />
                <p className='Customer-info'>Location</p>
                <input
                  placeholder='Enter Location'
                  value={data?.location}
                  onChange={(e) => handleInput("location", e.target.value)}
                  className='product-input'
                  style={{ width: "350px", margin: "5px 0px" }}
                />
                <br></br>
                <p className='Customer-info'>For Crops</p>
                <Multiselect
                  options={crops}
                  selectedValues={cropsData}
                  displayValue='name'
                  placeholder='Crop'
                  showArrow
                  onSelect={(list, value) => handleCropFilter(list, value, false)}
                  onRemove={(list, value) => handleCropFilter(list, value, true)}
                  style={{
                    searchBox: {
                      border: "none",
                    },
                    searchWrapper: {
                      border: "none",
                      "border-radius": "0px 8px 8px 0px",
                    },
                    inputField: {
                      padding: "5px 0px 5px 15px",
                      width: "180px",
                      fontSize: "16px",
                      cursor: "pointer",
                    },
                  }}
                />
                <br></br>
                <textarea
                  placeholder='Tags'
                  className='product-input'
                  style={{
                    width: "350px",
                    height: "70px",
                    margin: "5px 0px",
                  }}
                />
              </Box>
              <Box>
                <button className='upload-logo-button'>Upload Logo</button>
              </Box>
            </Box>

            <Box>
              <p className='Customer-info'>Select Content Type</p>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", mr: 4, mt: 2, mb: 2 }}>
                  <p style={{ margin: "0px 10px 0px 0px" }} className='Customer-info'>
                    Post
                  </p>
                  <input
                    checked={data?.content_type === "POST"}
                    onChange={handleChange}
                    type='checkbox'
                    value='checked'
                  />
                </Box>
                <Box sx={{ display: "flex", mr: 2, mt: 2, mb: 2 }}>
                  <p style={{ margin: "0px 10px 0px 0px" }} className='Customer-info'>
                    Video
                  </p>
                  <input
                    checked={data?.content_type === "VIDEO"}
                    onChange={handleChangeVideo}
                    type='checkbox'
                    value='checked'
                  />
                </Box>
              </Box>
            </Box>

            {data?.content_type === "POST" ? (
              <div>
                <p style={{ margin: "0px 0px 10px 0px" }} className='Customer-info'>
                  Upload Image
                </p>
                <button className='upload-image-button'>Upload Image</button>

                <p style={{ margin: "20px 0px 0px 0px" }} className='Customer-info'>
                  Title
                </p>
                <input
                  onChange={(e) => handleInput("title", e.target.value)}
                  placeholder='Enter Title'
                  className='product-input'
                  style={{ width: "350px" }}
                />
                <p style={{ margin: "0px 0px 0px 0px" }} className='Customer-info'>
                  Link on Share
                </p>
                <input
                  onChange={(e) => handleInput("share_link", e.target.value)}
                  placeholder='Enter Youtube video link'
                  className='product-input'
                  style={{ width: "350px" }}
                />
              </div>
            ) : (
              ""
            )}
            {data?.content_type === "VIDEO" ? (
              <div>
                <p style={{ margin: "0px 0px 0px 0px" }} className='Customer-info'>
                  Video Link
                </p>
                <input
                  value={data?.video}
                  onChange={(e) => handleInput("video", e.target.value)}
                  placeholder='Enter Youtube Video Link'
                  className='product-input'
                  style={{ width: "350px", margin: "5px 0px" }}
                />

                <p style={{ margin: "0px 0px 0px 0px" }} className='Customer-info'>
                  Title
                </p>
                <input
                  value={data?.title}
                  onChange={(e) => handleInput("title", e.target.value)}
                  placeholder='Enter Title'
                  className='product-input'
                  style={{ width: "350px", margin: "5px 0px" }}
                />
                <p style={{ margin: "0px 0px 0px 0px" }} className='Customer-info'>
                  Sharing message
                </p>
                <textarea
                  value={data?.share_link}
                  placeholder='yt video link:https://www.youtube.com/watch?v=niwtlWnMyms

                    text offer:Special offer get @ 50%
                    
                    '
                  onChange={(e) => handleInput("share_link", e.target.value)}
                  className='product-input'
                  style={{
                    width: "350px",
                    height: "100px",
                    margin: "5px 0px",
                  }}
                />
                <p style={{ margin: "0px 0px 0px 0px" }} className='Customer-info'>
                  Description
                </p>
                <textarea
                  value={data?.description}
                  placeholder='Description'
                  onChange={(e) => handleInput("description", e.target.value)}
                  className='product-input'
                  style={{
                    width: "350px",
                    height: "100px",
                    margin: "5px 0px",
                  }}
                />
              </div>
            ) : (
              ""
            )}

            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <button className='upload-image-button' onClick={() => updatePost()}>
                Update
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default EditModal;
