import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import Login from "./Components/Login";
import { useEffect, useState, createContext, useContext } from "react";
import server, { apiInstace } from "./Components/server";
import axios from "axios";
import { DrawerHeader } from "./Components/Navbar";
import { Box } from "@mui/material";
import Crop_add from "./Components/Content/Layout/Crop_add";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardState from "./Components/Context/DashboardState";
import ProfileUpdate from "./Components/Profile/profileupdate";
import Dashboard from "./Components/Dashboard/Dashboard";
import Orders from "./Components/Orders/Orders";
import Customers from "./Components/Customers/Customers";
import Inventory from "./Components/Inventory/Inventory";
// import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import NewProduct from "./Components/Inventory/NewProduct";
import ViewProduct from "./Components/Inventory/ViewProduct";
import CustomerOrder from "./Components/Customers/CustomerOrder";
// import DashNotActive from "../assets/Dash-not-active.svg";
import Content from "./Components/Content/Content";
import Settings from "./Components/Settings/Settings";
import ViewUserNetwork from "./Components/Settings/ViewUserNetwork";
import { lang } from "moment/moment";

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState(false);
  const [profileopen, setProfileopen] = useState(false);

  // useEffect(() => {
  //   if (!user) return;
  //   const minute = 1000 * 60;
  //   const intervalToken = setInterval(() => {
  //     const refreshToken = localStorage.getItem("refresh");

  //     axios
  //       .post(`${server}/auth/refresh`, { refreshToken })
  //       .then((res) => {
  //         console.log(res);
  //         localStorage.setItem("access", res.data.access);
  //         localStorage.setItem("refresh", res.data.refresh);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, minute);
  //   return () => {
  //     clearInterval(intervalToken);
  //   };
  // }, [user]);

  //   const [languages, setLanguages] = useState([]);

  //   const fetschandsetlanguages = () => {
  //     const requestOptions = {
  //       headers: {
  //         method: "GET",
  //         Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       },
  //     };
  //     apiInstace
  //       .get(`/translation/languages`, requestOptions)
  //       .then((res) => {
  //         setLanguages(res.data.results);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //       });
  //   };

  useEffect(() => {
    if (localStorage.getItem("refresh")) {
      setUser(true);
    }
    // fetschandsetlanguages();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <DashboardState>
        <div className='App'>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
          {user ? (
            <>
              <Navbar setOpen={setProfileopen} />
              <ProfileUpdate open={profileopen} setOpen={setProfileopen} />
              <Routes>
                <Route path='crop_add' element={<Crop_add />} />
              </Routes>
              <Routes>
                <Route path='/' element={<Navigate to={"/dashboard"} />} />
              </Routes>
              {/* <Box sx={{ display: "flex" }}>
             <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f4f5fa" }}>
             <DrawerHeader />
             <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/addproduct" element={<NewProduct />} />
            <Route path="/viewproduct" element={<ViewProduct />} />
            <Route path="/viewcustomer" element={<CustomerOrder />} />
            <Route path="/content" element={<Content />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/viewusernetwork" element={<ViewUserNetwork />} />
          </Routes>
          </Box>

             </Box> */}
            </>
          ) : (
            <Routes>
              <Route path='/' element={<Login />} />
            </Routes>
          )}
        </div>
      </DashboardState>
    </UserContext.Provider>
  );
}

export default App;
