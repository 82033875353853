import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Input, TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import Filter from "../../../assets/sort.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiInstace } from "../../server";
import server from "../../server";
import DashboardContext from "../../Context/DashboardContext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useDebounce } from "use-debounce";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "no_of_new_joiners",
    numeric: true,
    disablePadding: false,
    label: "Network Size",
  },
  {
    id: "no_of_sales_made",
    numeric: true,
    disablePadding: false,
    label: "No. of Orders",
  },
  {
    id: "profits_made",
    numeric: true,
    disablePadding: false,
    label: "Profits Made",
  },
  {
    id: "balance_amount",
    numeric: true,
    disablePadding: false,
    label: "Balance Amount",
  },
  // {
  //   id: "value",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Value Generated",
  // },
  // {
  //   id: "lastRevenue",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Last Revenue Date",
  // },
  {
    id: "joining_date",
    numeric: true,
    disablePadding: false,
    label: "Joining Date",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === "balance_amount" ? "center" : headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const query = props.query;
  const setQuery = props.setQuery;

  const roles = props.roles;
  const setRoles = props.setRoles;

  const nonZeroCust = props.nonZeroCust;
  const setNonZeroCust = props.setNonZeroCust;

  // const handlesearch = async (e) => {
  //   // const search_data=[];
  //   const validQuery = query != "";
  //   const validRoles = Boolean(roles.length);

  //   const filteredRows = await props.orirows.filter((row) => {
  //     const nameMatch = validQuery
  //       ? (row.first_name + row.last_name)
  //           ?.toLowerCase()
  //           ?.includes(query.toLowerCase().replace(" ", ""))
  //       : true;
  //     const balanceMatch = nonZeroCust ? row.balance_amount != 0 : true;
  //     const roleMatch = validRoles ? roles.includes(row.role) : true;

  //     return balanceMatch && nameMatch && roleMatch;
  //   });
  //   // setRows(filteredRows);
  //   // console.log(filteredRows);
  //   props.setRows(filteredRows);
  // };

  // useEffect(() => {
  //   // handlesearch();
  //   // console.log(roles);
  // }, [query, nonZeroCust, roles]);

  const roleOptions = ["BDO", "DEALER", "USER"];

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
            User Networks
          </Typography>
        )}
      </div>

      <div
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 16 }}>
        <FormControl size='small'>
          <InputLabel>Select Roles</InputLabel>
          <Select
            multiple
            value={roles}
            onChange={(e) => setRoles(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
            sx={{ width: 200 }}
            label='Select Roles'>
            {roleOptions.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <p>Filter 0 balance users</p>
          <Checkbox
            onChange={(e) => {
              setNonZeroCust(e.target.checked);
            }}
          />{" "}
        </div>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search'
                onChange={(e) => setQuery(e.target.value)}
              />
              {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
            </div>
          </Tooltip>
        )}
      </div>
      <DownloadTableExcel
        filename='User Network'
        sheet='users network'
        currentTableRef={props.tableRef.current}>
        <button className='table-head-buttons'> Export excel </button>
      </DownloadTableExcel>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const NetworkTable = () => {
  const nav = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = React.useState([]);
  const [oriusers, setOriUsers] = React.useState([]);
  const [formattedOrder, setFormattedOrder] = useState();
  const [orders, setOrders] = React.useState();
  const [updateBalace, setUpdateBalance] = useState(0);
  const [fetchAgain, setFetchAgain] = useState(false);

  const [reload, setReload] = React.useState(false);
  const [userArr, setUserArr] = React.useState();
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [roles, setRoles] = useState([]);
  const [query, setQuery] = useState("");
  const [nonZeroCust, setNonZeroCust] = useState(false);
  const [count, setCount] = useState();

  const [name] = useDebounce(query, 400);

  const getAllUsers = async (page = 0) => {
    try {
      setLoading(true);
      let user_info = [];

      const userData = await apiInstace.get(`/users/getUsers`, {
        params: {
          page: page + 1,
          size: rowsPerPage || undefined,
          name: name || undefined,
          roles: roles.join(","),
          zero_bal: nonZeroCust || undefined,
        },
      });
      // console.log(userData.data);
      let arr = await Promise.all(
        userData.data.results.map(async (user) => {
          const userResponse = await apiInstace.get(`/users/getUserNetwork/${user.id}`);
          user = { ...user, network: userResponse.data };
          user_info.push(user);
          return user;
        })
      );
      setCount(userData.data.count);
      // const userResults = await Promise.all(userPromises);
      // console.log(user_info);
      setUsers(arr);
      setOriUsers(arr);
      // console.log(user_info);

      // setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   console.log(users);
  // }, [users])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  const updateUser = async (id, data, prev) => {
    // console.log(data.balance_amount);
    if (prev <= 0) return;
    try {
      const data2 = await apiInstace.patch(`/users/updateuser/${id}`, data);
      setFetchAgain(!fetchAgain);
      toast.success("Balance Updated", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      toast.error("Something Went Wrong", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err);
    }
  };

  const [address, setaddress] = useState([]);
  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3ODgyMjg0LCJpYXQiOjE2ODc0MzU0OTMsImp0aSI6IjgyNmIxMGVlNzc2YTQwNzA4MjE2NmMwMDIzYjQzMTQyIiwidXNlcl9pZCI6OTB9.lsoyuOy800HQAPW35BY7SN5HiX6wQREE4e8Y1XKcKCM`,
      },
    };
    apiInstace
      .get(`/address/getAddress`)
      .then((res) => {
        setaddress(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const GetAddress = ({ id }) => {
    const rowaddress = address.filter((item) => {
      return item.id == id;
    });
    // console.log(rowaddress);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <p>{rowaddress[0]?.city}</p> */}
        <p>{rowaddress[0]?.state}</p>
        <p>{rowaddress[0]?.city}</p>
      </div>
    );
  };

  const getOrders = () => {
    setLoading(true);
    apiInstace
      .get(`/orders/getordersprofit`)
      .then((res) => {
        setOrders(res.data.results);
        // console.log("order data");
        // console.log(res.data.results);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setLoading(false);
  };

  const formatOrders = () => {
    let ordersFormatted = [];
    orders.forEach((order) => {
      const userId = order.order.user_id;
      const userDetail = users.filter((user) => user.id === userId);

      const newOrder = { ...order, userDetail: userDetail[0] };
      ordersFormatted.push(newOrder);
    });
    setFormattedOrder(ordersFormatted);
    setLoading(false);
  };

  const getProfits = (user) => {
    const userId = user.id;
    let profitsMade = 0;
    formattedOrder.map((order) => {
      if (order?.userDetail?.bdo === userId) {
        profitsMade += order.bdo_profit;
      }
      if (order?.userDetail?.parent === userId) {
        profitsMade += order.parent_profit;
      }
      if (order?.userDetail?.grand_parent === userId) {
        profitsMade += order.grand_parent_profit;
      }
      if (order?.userDetail?.dealer === userId) {
        profitsMade += order.dealer_profit;
      }
    });

    return profitsMade;
  };

  React.useEffect(() => {
    if (users && orders) {
      formatOrders();
    }
  }, [users, orders]);

  React.useEffect(() => {
    getOrders();
    getAllUsers(page);
  }, [page]);

  React.useEffect(() => {
    getOrders();
    getAllUsers();
    setPage(0);
  }, [fetchAgain, roles, name, nonZeroCust, rowsPerPage]);

  React.useEffect(() => {
    if (formattedOrder?.length > 0) {
      setLoading(false);
    }
  }, [formattedOrder]);

  const tableRef = useRef(null);

  return (
    <div className='order-table-main'>
      <ToastContainer />
      <Box sx={{ width: "100%", borderRadius: 10 }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            row={users}
            setRows={setUsers}
            orirows={oriusers}
            tableRef={tableRef}
            roles={roles}
            setRoles={setRoles}
            query={query}
            setQuery={setQuery}
            nonZeroCust={nonZeroCust}
            setNonZeroCust={setNonZeroCust}
          />

          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {
                  // rowsPerPage > 0
                  // ? users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // :
                  (formattedOrder && users)?.map((row, index) => {
                    // console.log(row)
                    const isItemSelected = isSelected(row.first_name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const userProfits = getProfits(row);
                    // console.log(row);
                    return row.is_superuser ? (
                      <></>
                    ) : (
                      <TableRow
                        hover
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index + 1}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        style={{ border: "1px solid transparent" }}>
                        <TableCell
                          onClick={() => {
                            nav("/usernetwork", { state: { user: row } });
                          }}
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          style={{ border: "1px solid transparent" }}>
                          {/* <Link
                          to="/viewusernetwork"
                          style={{ textDecoration: "none" }}
                          state={{data:row}}
                        > */}
                          {row.first_name + " " + row.last_name}
                          {/* </Link> */}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            nav("/usernetwork", { state: { user: row } });
                          }}
                          align='left'
                          style={{ border: "1px solid transparent" }}>
                          {/* {row.address[0]?.address} */}
                          {/* <GetAddress id={row.address[0]} /> */}
                          {row.phone}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            nav("/usernetwork", { state: { user: row } });
                          }}
                          align='left'
                          style={{ border: "1px solid transparent" }}>
                          {row.role}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            nav("/usernetwork", { state: { user: row } });
                          }}
                          align='left'
                          style={{ border: "1px solid transparent" }}>
                          {row?.no_of_new_joiners}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            nav("/usernetwork", { state: { user: row } });
                          }}
                          align='left'
                          style={{ border: "1px solid transparent" }}>
                          {row?.no_of_sales_made}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            nav("/usernetwork", { state: { user: row } });
                          }}
                          align='left'
                          style={{ border: "1px solid transparent" }}>
                          {/* {userProfits} */}
                          {row?.profits_made}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "5px",
                              }}>
                              Current Balance: {row?.balance_amount}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              {/* <div style={{ marginRight: "10px" }}>

                            </div> */}
                              {/* <button
                              style={{
                                padding: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid transparent",
                                color: "white",
                                fontWeight: "semibold",
                                borderRadius: "10px",
                                backgroundColor: "rgba(85, 112, 241, 1)",
                                marginRight: "5px"
                              }}
                              onClick={(e) => {
                                // console.log("clicked")
                                let balanceDifferenc = document.getElementById(
                                  `${row.id}`
                                ).value;
                                if (balanceDifferenc === "") return
                                updateUser(row.id, {
                                  balance_amount: row?.balance_amount - balanceDifferenc,
                                });
                                document.getElementById(
                                  `${row.id}`
                                ).value = ""
                              }}
                            >
                              Decrement
                            </button> */}
                              <input
                                style={{
                                  padding: "10px",
                                  width: "100px",
                                }}
                                type='text'
                                name=''
                                id={`balance-${row.id}`}
                                // placeholder={userProfits - row?.balance_amount}
                                placeholder='Balance'
                                //   step="1"
                                onChange={(e) => {
                                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                }}
                              />

                              <button
                                style={{
                                  padding: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "1px solid transparent",
                                  color: "white",
                                  fontWeight: "semibold",
                                  borderRadius: "10px",
                                  backgroundColor: "rgba(85, 112, 241, 1)",
                                  marginLeft: "5px",
                                }}
                                onClick={(e) => {
                                  // console.log("clicked")
                                  let balanceDifferenc = document.getElementById(
                                    `balance-${row.id}`
                                  ).value;
                                  // console.log("clicked", balanceDifferenc);
                                  if (balanceDifferenc === "") return;

                                  // console.log(row?.balance_amount + parseInt(balanceDifferenc))
                                  updateUser(
                                    row.id,
                                    {
                                      balance_amount:
                                        row?.balance_amount - parseInt(balanceDifferenc),
                                    },
                                    row?.balance_amount
                                  );
                                  document.getElementById(`balance-${row.id}`).value = "";
                                }}>
                                Deduct
                              </button>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell style={{ border: "1px solid transparent" }}>
                          <p>{moment(row?.date_joined).format("DD/MM/YY")}</p>
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
            <Table
              sx={{ minWidth: 750, display: "none" }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}
              ref={tableRef}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {
                  // rowsPerPage > 0
                  // ? users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // :
                  (formattedOrder && users)?.map((row, index) => {
                    const isItemSelected = isSelected(row.first_name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const userProfits = getProfits(row);
                    return row.is_superuser ? (
                      <></>
                    ) : (
                      <TableRow
                        hover
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index + 1}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        style={{ border: "1px solid transparent" }}>
                        <TableCell
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          style={{ border: "1px solid transparent" }}>
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.phone}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.role}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row?.no_of_new_joiners}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row?.no_of_sales_made}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row?.profits_made}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "5px",
                              }}>
                              Current Balance: {row?.balance_amount}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              <input
                                style={{
                                  padding: "10px",
                                  width: "100px",
                                }}
                                type='text'
                                name=''
                                id={`${row.id}`}
                                placeholder='Balance'
                              />
                            </div>
                          </div>
                        </TableCell>

                        <TableCell style={{ border: "1px solid transparent" }}>
                          <p>{moment(row?.date_joined).format("DD/MM/YY")}</p>
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>{" "}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25, 100]}
            component='div'
            count={count-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default NetworkTable;
