import { Box } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import server from "./server";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const context = useContext(UserContext);

  const [loginDetails, setLoginDetails] = useState({
    phone: "",
    password: "",
  });
  const [cust_data, setCust_data] = useState({
    first_name: "",
    last_name: "",
    role: "USER",
    phone: "",
    password: "",
    is_superuser: true,
  });
  const [login, setLogin] = useState(true);
  const handleInput = (key, value) => {
    setLoginDetails({
      ...loginDetails,
      [key]: value,
    });
  };
  const handleInput1 = (key, value) => {
    setCust_data({
      ...cust_data,
      [key]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    axios
      .post(`${server}/auth/login`, loginDetails)
      .then((res) => {
        console.log(res);
        localStorage.setItem("access", res.data.tokens.access);
        localStorage.setItem("refresh", res.data.tokens.refresh);
        localStorage.setItem("id", res.data.id);
        toast.success("Logged in successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/dashboard");
        context.setUser(true);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
      });

    console.log(loginDetails);
  };
  const handleSignup = (e) => {
    console.log(loginDetails, cust_data);
    e.preventDefault();

    axios
      .post(`${server}/auth/user_register`, { cust_data })
      .then((res) => {
        console.log(res);
        axios
          .post(`${server}/auth/login`, loginDetails)
          .then((res) => {
            console.log(res);
            toast.success("Signed up successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            localStorage.setItem("access", res.data.tokens.access);
            localStorage.setItem("refresh", res.data.tokens.refresh);
            localStorage.setItem("id", res.data.id);
            navigate("/dashboard");
            context.setUser(true);
          })
          .catch((err) => {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log(err);
          });
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
      });

    // console.log(signupDetails);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#d8d6d6",
      }}>
      {login ? (
        <Box className='main-login-div'>
          <h2>Welcome Back!</h2>
          <br />
          <h3 style={{ color: "grey" }}>Kamao Kisan</h3>
          <h4 style={{ color: "grey" }}>Login to your account</h4>
          <br />
          <form onSubmit={handleLogin}>
            <input
              className='login-input'
              placeholder='Phone Number'
              value={loginDetails?.phone}
              onChange={(e) => handleInput("phone", e.target.value)}
              required
            />
            <br />
            <input
              className='login-input'
              placeholder='Password'
              value={loginDetails?.password}
              onChange={(e) => handleInput("password", e.target.value)}
              required
            />
            <br />
            {/* <h5 style={{color:"grey"}}>Dont't have an account? <span onClick={()=>setLogin(false)} style={{color:"#5570f1",cursor:"pointer"}}>Signup</span></h5> */}

            <div className='login-button-div'>
              <button type='submit' className='login-button'>
                Submit
              </button>
            </div>
          </form>
        </Box>
      ) : (
        <Box className='main-login-div'>
          <h2>
            Get Started with <span style={{ color: "#5570f1" }}>Kamao Kisan</span>
          </h2>
          <br />
          <h4 style={{ color: "grey" }}>Create your account</h4>
          <form onSubmit={handleSignup}>
            <br />
            <input
              className='login-input'
              placeholder='First Name'
              value={cust_data?.first_name}
              onChange={(e) => handleInput1("first_name", e.target.value)}
              required
            />
            <br />
            <input
              className='login-input'
              placeholder='Last Name'
              value={cust_data?.last_name}
              onChange={(e) => handleInput1("last_name", e.target.value)}
              required
            />
            <br />
            <input
              className='login-input'
              placeholder='Phone Number'
              value={cust_data?.phone}
              onChange={(e) => {
                handleInput("phone", e.target.value);
                handleInput1("phone", e.target.value);
              }}
              required
            />
            <br />
            <input
              className='login-input'
              placeholder='Password'
              value={cust_data?.password}
              onChange={(e) => {
                handleInput("password", e.target.value);
                handleInput1("password", e.target.value);
              }}
              required
            />
            <br />
            <h5 style={{ color: "grey" }}>
              Already have an account?{" "}
              <span onClick={() => setLogin(true)} style={{ color: "#5570f1", cursor: "pointer" }}>
                Login
              </span>
            </h5>
            <div className='login-button-div'>
              <button type='submit' className='login-button'>
                Submit
              </button>
            </div>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default Login;
