import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import {apiInstace} from '../../server'
import { apiInstace } from "../server";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import KrishiVideos from "./KrishiVideos";
import { EnhancedTableToolbar } from "./Layout/KrishiVideoTable";
import Crop_add from "./Layout/Crop_add";
import Crop_table from "./Layout/Crop_table";
import { OfferTabs } from "./Layout/Offers";
import Farm_equip from "./Layout/Farm_equip";
import Brands from "./Layout/Brands";
import Tags from "./Layout/Tags";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Content = () => {
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [orirows, setOriRows] = useState([]);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  const getCrop = () => {
    apiInstace
      .get(`/crops/getCrops`, requestOptions)
      .then((res) => {
        const data = res.data.results.reverse();
        setRows(data);
        setOriRows(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCrop();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("contentind") == undefined ||
      localStorage.getItem("contentind") == null
    )
      localStorage.setItem("contentind", value);
    else setValue(JSON.parse(localStorage.getItem("contentind")));
  }, []);
  //   useEffect(() => {
  //     console.log(value);
  //   }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("contentind", newValue);
  };

  return (
    <Box>
      <Box>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            {/* <Tab
              sx={{ fontSize: 14, color: "#45464E", fontWeight: 500 }}
              label="Home Page "
              {...a11yProps(0)}
            /> */}
            <Tab label='Crops' {...a11yProps(0)} />
            <Tab label='Offers' {...a11yProps(1)} />
            {/* <Tab label="Store" {...a11yProps(3)} /> */}
            <Tab label='Krishi Videos' {...a11yProps(2)} />
            <Tab label='Farm Equipments' {...a11yProps(3)} />
            <Tab label='Brands' {...a11yProps(4)} />
            <Tab label='Tags' {...a11yProps(5)} />
          </Tabs>
        </Box>
        {/* <TabPanel value={value} index={0}>
          Comming Soon......
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <Crop_table rows={rows} setRows={setRows} orirows={orirows} getCrop={getCrop} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OfferTabs />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <KrishiVideos />

          {/* <EnhancedTableToolbar /> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Farm_equip />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Brands />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Tags />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Content;
