import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { UserContext } from "../../../App";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import server from "../../server";
import DashboardContext from "../../Context/DashboardContext";
import AddIcon from "@mui/icons-material/Add";
import AddModal from "./AddModal";
import {
  Box,
  Checkbox,
  TextareaAutosize,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { State } from "country-state-city";
import { City } from "../../../utils/country-state-city";
import { Select, MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { apiInstace } from "../../server";
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const NewProductForm = ({
  newProduct,
  setNewProduct,
  isEdit,
  add_data,
  setAdd_data,
  addUpdated,
  setAddUpdated,
  productDesc,
  setProductDesc,
}) => {
  const [selfShip, setSelfShip] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenBrand, setModalOpenBrand] = useState(false);
  const [modalOpenEquipment, setModalOpenEquipment] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  const [checked, setChecked] = useState(newProduct?.cash_on_delivery ? true : false);
  useEffect(() => {
    setNewProduct({ ...newProduct, cash_on_delivery: checked });
  }, [checked]);
  //   console.log(newProduct);
  const context = useContext(DashboardContext);
  const {
    crops,
    brands,
    getCrops,
    getBrands,
    getEquipment,
    tags,

    getTags,
    equipment,
    cropsSelected,
    brandsSelected,
    tagsSelected,
    equipmentsSelected,
    setLoading,
    languages,
  } = context;

  useEffect(() => {
    newProduct?.shipping_type == "SELF_SHIPPING" ? setSelfShip(true) : setSelfShip(false);
  }, []);
  const handleInput = (key, value) => {
    setNewProduct({
      ...newProduct,
      [key]: value,
    });
  };
  const handleInput1 = (key, value) => {
    !addUpdated && setAddUpdated(true);
    setAdd_data({
      ...add_data,
      [key]: value,
    });
  };

  const handleShippingType = () => {
    setSelfShip(!selfShip);
    setNewProduct({
      ...newProduct,
      shipping_type: "SELF_SHIPPING",
    });
  };

  const handleShippingTypeKamao = () => {
    setSelfShip(!selfShip);
    setNewProduct({
      ...newProduct,
      shipping_type: "KAMAOKISAN",
    });
  };

  const handleBrandInput = (selectedList, selectedValue, isRemove) => {
    if (!isRemove) {
      let arr = [...newProduct.brands];
      arr.push(selectedValue.id);
      setNewProduct({
        ...newProduct,
        brands: arr,
      });
    } else {
      let arr = [...newProduct.brands];
      const newArr = arr.filter((item) => item !== selectedValue.id);
      setNewProduct({
        ...newProduct,
        brands: newArr,
      });
    }
  };

  const handleTagInput = (selectedList, selectedValue, isRemove) => {
    // console.log(newProduct?.tags);
    if (!isRemove) {
      let arr = [...newProduct?.tags];
      arr.push(selectedValue.id);
      console.log(arr);
      setNewProduct({
        ...newProduct,
        tags: arr,
      });
    } else {
      let arr = [...newProduct?.tags];
      const newArr = arr.filter((item) => item !== selectedValue.id);
      setNewProduct({
        ...newProduct,
        tags: newArr[0],
      });
    }
  };

  const handleCropFilter = (selectedList, selectedValue, isRemove) => {
    if (!isRemove) {
      let arr = [...newProduct.crops];
      arr.push(selectedValue.id);
      setNewProduct({
        ...newProduct,
        crops: arr,
      });
    } else {
      let arr = [...newProduct.crops];
      console.log(arr);
      const newArr = arr.filter((item) => item !== selectedValue.id);
      console.log(newArr);
      setNewProduct({
        ...newProduct,
        crops: newArr,
      });
    }
  };

  const handleEquipmentInput = (selectedList, selectedValue, isRemove) => {
    if (!isRemove) {
      let arr = [...newProduct.farm_equipments];
      arr.push(selectedValue.id);
      setNewProduct({
        ...newProduct,
        farm_equipments: arr,
      });
    } else {
      let arr = [...newProduct.farm_equipments];
      const newArr = arr.filter((item) => item !== selectedValue.id);
      setNewProduct({
        ...newProduct,
        farm_equipments: newArr,
      });
    }
  };

  useEffect(() => {
    getCrops(isEdit, newProduct, setNewProduct);
    getBrands(isEdit, newProduct, setNewProduct);
    getEquipment(isEdit, newProduct, setNewProduct);
    getTags(isEdit, newProduct, setNewProduct);
  }, []);
  // console.log(crops,tags,brands);
  const theme = useTheme();
  const [crop_data, setCrop_data] = useState(newProduct?.crops);
  const [equi_data, setEqui_data] = useState(newProduct?.farm_equipments);
  const [brand_data, setBrand_data] = useState(newProduct?.brands);
  const [tag_data, setTag_data] = useState(newProduct?.tags);

  useEffect(() => {
    setNewProduct({
      ...newProduct,
      tags: tag_data,
      farm_equipments: equi_data,
      brands: brand_data,
      crops: crop_data,
    });
    // console.log({
    //   ...newProduct,
    //   tags: tag_data,
    //   farm_equipments: equi_data,
    //   brands: brand_data,
    //   crops: crop_data,
    // });
  }, [equi_data, crop_data, brand_data, tag_data]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCrop_data(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setEqui_data(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setBrand_data(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChange3 = (event) => {
    const {
      target: { value },
    } = event;
    setTag_data(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const center_flex = { display: "flex", justifyContent: "space-around", alignItems: "center" };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  const { id: productID, data: product } = useLocation().state;

  const [langRes, setLangRes] = useState([]);
  const fetchandsetLangRes = () => {
    apiInstace
      .get(`translation/product/${productID}`, requestOptions)
      .then((res) => {
        // const fetchedDescData = ;
        setLangRes(res.data.results ?? []);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  //   const [productDesc, setProductDesc] = useState({});

  useEffect(() => {
    setLoading(true);
    // fetchandsetlanguages();
  }, []);

  useEffect(() => {
    productID >= 0 && fetchandsetLangRes();
  }, [languages]);

  const langData = (fetchedDescData, languageID) => {
    // console.log("fetchedDescData", fetchedDescData, languageID);
    return {
      DESCRIPTION: (fetchedDescData ?? []).find(
        ({ type, language }) => type == "DESCRIPTION" && language == languageID
      ) ?? {
        content: `<p>Sample Description of ${product?.name} in language ${languageID}</p>`,
        type: "DESCRIPTION",
        language: languageID,
        new: true,
        product: productID,
      },

      SHARE_MESSAGE: (fetchedDescData ?? []).find(
        ({ type, language }) => type == "SHARE_MESSAGE" && language == languageID
      ) ?? {
        content: `Sample SHARE_MESSAGE of ${product?.name} in language ${languageID}`,
        type: "SHARE_MESSAGE",
        language: languageID,
        new: true,
        product: productID,
      },
    };
  };

  useEffect(() => {
    let tempRes = {};
    languages &&
      langRes &&
      languages?.forEach(({ id }) => (tempRes = { ...tempRes, [id]: langData(langRes, id) }));
    setProductDesc({ ...tempRes });
    // console.log(tempRes);
    setLoading(false);
  }, [langRes, languages]);

  const [selectedLang, setSelectedLang] = useState(1);
  const [translatedData, setTranslatedData] = useState({ DESCRIPTION: "", SHARE_MESSAGE: "" });

  //   useEffect(() => {
  //     console.log("selectedLang", selectedLang, productDesc?.[selectedLang]);
  //   }, [selectedLang]);

  //   useEffect(() => {
  //     console.log("productDesc", productDesc);
  //   }, [productDesc]);

  //   const handleDescInput = (prevSelectedLang, key, value) => {
  const handleDescInput = (key, data) => {
    let langID = selectedLang;
    // console.log("change", langID, data);
    if (Boolean(Object.keys(productDesc).length)) {
      let prodDesc = productDesc;
      let tempDesc = {
        ...prodDesc,
        [langID]: {
          ...prodDesc[langID],
          [key]: data,
          //   {
          //     ...prodDesc[langID][key],
          //     content: value,
          //   },
        },
      };
      //   const flatten = (obj) => Object.values(obj).flat();
      //   console.log("tempDesc", tempDesc, flatten(tempDesc));
      setProductDesc(tempDesc);
    }
  };

  const [fetchedCities, setFetchedCities] = useState([]);

  useEffect(() => {
    const cityUp = async () =>
      setFetchedCities((await City.getCitiesOfState("IN", add_data.state.split("-")[0])) ?? []);
    if (add_data.state) cityUp();
  }, [add_data.state]);

  return (
    <>
      <div className='product-form'>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className='product-side-left' style={{ width: "40%", gap: 16 }}>
            <div className='flex-div'>
              {/* <p className="Customer-info">Name</p> */}

              <TextField
                // className="product-input"
                // placeholder="Product Name"
                label='Product Name'
                variant='outlined'
                style={{ width: "100%", marginBottom: "5px" }}
                value={newProduct?.name}
                onChange={(e) => {
                  handleInput("name", e.target.value);
                }}
              />
            </div>
            <div
              className='flex-div'
              style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
              <div style={{ width: "49%", ...center_flex }}>
                <span id='demo-multiple-name-label'>All Crops</span>
                <Checkbox
                  checked={crop_data?.length == crops?.length}
                  onChange={(e) => {
                    e.target.checked
                      ? setCrop_data(crops?.map((equi) => equi.id))
                      : setCrop_data([]);
                  }}
                />
              </div>
              <div style={{ width: "49%", ...center_flex }}>
                <span id='demo-multiple-name-label'>All Equipments</span>
                <Checkbox
                  checked={equi_data?.length == equipment?.length}
                  onChange={(e) => {
                    e.target.checked
                      ? setEqui_data(equipment?.map((equi) => equi.id))
                      : setEqui_data([]);
                  }}
                />
              </div>{" "}
            </div>
            <div
              className='flex-div'
              style={{ justifyContent: "space-between", width: "100%", gap: 10 }}>
              {/* <select className="product-select1">
              <option>Select Crop</option>
              <option>Category</option>
              <option>Category</option>
            </select> */}

              {/* <div
                className='select-multi'
                style={{ justifyContent: "space-between", width: "100%" }}> */}
              {/* <p className="Customer-info">Crop</p> */}

              <FormControl>
                <InputLabel id='demo-multiple-name-label'>Crops</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple
                  value={crop_data}
                  onChange={handleChange}
                  input={<OutlinedInput label='Name' />}
                  MenuProps={MenuProps}>
                  {crops?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={getStyles(item, crop_data, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id='demo-multiple-name-label'>Equipments</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple
                  value={equi_data}
                  onChange={handleChange1}
                  input={<OutlinedInput label='Name' />}
                  MenuProps={MenuProps}>
                  {equipment?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={getStyles(item, equi_data, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Multiselect
                options={crops}
                // selectedValues={isEdit ? cropsSelected : []}
                // displayValue="name"
                // placeholder="Crop"
                // showArrow
                // onSelect={(list, value) => handleCropFilter(list, value, false)}
                // onRemove={(list, value) => handleCropFilter(list, value, true)}
                style={{
                  searchBox: {
                    border: "none",
                  },
                  searchWrapper: {
                    border: "none",
                    "border-radius": "0px 8px 8px 0px",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    width: "180px",
                    fontSize: "16px",
                    cursor: "pointer",
                  },
                }}
              /> */}
              {/* <AddModal
                modalIsOpen={modalOpen}
                modalFor="Crops"
                closeModal={() => {
                  setModalOpen(false);
                }}
              />
              <button className="add-btn" onClick={() => setModalOpen(true)}>
                <AddIcon />
              </button> */}
              {/* </div> */}
              {/* <div className='select-multi'> */}
              {/* <Multiselect
                options={equipment}
                displayValue="name"
                selectedValues={isEdit ? equipmentsSelected : []}
                placeholder="Farm Equipments"
                showArrow
                onSelect={(list, value) =>
                  handleEquipmentInput(list, value, false)
                }
                onRemove={(list, value) =>
                  handleEquipmentInput(list, value, true)
                }
                style={{
                  searchBox: {
                    border: "none",
                  },
                  searchWrapper: {
                    border: "none",
                    "border-radius": "0px 8px 8px 0px",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    width: "180px",
                    fontSize: "16px",
                    cursor: "pointer",
                  },
                }}
              /> */}
              {/* </div> */}
              {/* <AddModal
              modalIsOpen={modalOpenEquipment}
              modalFor="Equipment"
              closeModal={() => {
                setModalOpenEquipment(false);
              }}
            /> */}
              {/* <button
              className="add-btn"
              onClick={() => setModalOpenEquipment(true)}
            >
              <AddIcon />
            </button> */}
              {/* <select className="product-select2">
              <option>Farm-Equipment</option>
              <option>Category</option>
              <option>Category</option>
            </select> */}
            </div>
            <div
              className='flex-div'
              style={{ justifyContent: "space-between", width: "100%", marginTop: "5px" }}>
              <TextField
                value={newProduct?.mrp}
                // className="product-input1"
                label='Product MRP'
                variant='outlined'
                style={{ width: "49%" }}
                // placeholder="Product MRP"
                onChange={(e) => {
                  handleInput("mrp", e.target.value);
                  // handleInput("base_price", e.target.value);
                }}
              />
              <TextField
                value={newProduct?.price}
                // className="product-input2"
                // placeholder="Selling Price"
                label='Selling Price'
                variant='outlined'
                style={{ width: "49%" }}
                onChange={(e) => {
                  handleInput("price", e.target.value);
                }}
              />
            </div>
            <div className='flex-div'>
              <TextField
                value={newProduct?.base_price}
                // className="product-input1"
                label='Base Price'
                variant='outlined'
                style={{ width: "100%" }}
                // placeholder="Product MRP"
                onChange={(e) => {
                  // handleInput("", e.target.value);
                  handleInput("base_price", e.target.value);
                }}
              />
              <TextField
                value={newProduct?.amount_after_shares}
                // className="product-input"
                // placeholder="Amount After Shares"
                label='Amount After Shares'
                variant='outlined'
                style={{ width: "100%", marginLeft: "5px" }}
                onChange={(e) => {
                  handleInput("amount_after_shares", e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "5px",
                justifyContent: "space-between",
              }}>
              <div
                className='select-multi'
                style={{ width: "100%", justifyContent: "space-around" }}>
                <FormControl sx={{ width: "90%" }}>
                  <InputLabel id='demo-multiple-name-label'>Brands</InputLabel>
                  <Select
                    labelId='demo-multiple-name-label'
                    id='demo-multiple-name'
                    multiple
                    value={brand_data}
                    onChange={handleChange2}
                    input={<OutlinedInput label='Name' />}
                    MenuProps={MenuProps}
                    // sx={{ width: "100%" }}
                  >
                    {brands?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        style={getStyles(item, brand_data, theme)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* <Multiselect

                options={brands}
                selectedValues={isEdit ? brandsSelected : []}
                displayValue="name"
                placeholder="Brand"
                showArrow
                onSelect={(list, value) => handleBrandInput(list, value, false)}
                onRemove={(list, value) => handleBrandInput(list, value, true)}
                style={{
                  searchBox: {
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "180px",
                  },
                  searchWrapper: {
                    border: "none",
                    "border-radius": "0px 8px 8px 0px",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    maxWidth: "150px",
                    fontSize: "16px",
                    cursor: "pointer",
                  },
                }}
              />
             
              */}
                <AddModal
                  modalIsOpen={modalOpenBrand}
                  modalFor='Brands'
                  closeModal={() => {
                    setModalOpenBrand(false);
                  }}
                  style={{ zIndex: "1000" }}
                />
                <button className='add-btn' onClick={() => setModalOpenBrand(true)}>
                  <AddIcon />
                </button>
              </div>
              {/* <select className="product-select2">
              <option>Brand</option>
              <option>Category</option>
              <option>Category</option>
            </select> */}
            </div>
            <div className='flex-div'>
              <TextField
                value={newProduct?.no_of_shares}
                // className="product-input"
                // placeholder="No. of Shares"
                label='No. of Shares'
                variant='outlined'
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(e) => {
                  handleInput("no_of_shares", e.target.value);
                }}
              />
            </div>
            <div className='flex-div'>
              <TextField
                value={
                  (newProduct?.price - newProduct?.amount_after_shares) / newProduct?.no_of_shares
                }
                // className="product-input"
                // placeholder=""
                label='Cost per Share'
                variant='outlined'
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(e) => {
                  handleInput("cost_per_share", e.target.value);
                }}
                disabled={isEdit}
              />
            </div>
            <div className='flex-div'>
              <TextField
                value={newProduct.bdo_share}
                // className="product-input"
                // placeholder="% for BDO
                //                                      E.g. Remaining"
                label='BDO share (INR)'
                variant='outlined'
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(e) => {
                  handleInput("bdo_share", e.target.value);
                }}
              />
            </div>
            <div className='flex-div'>
              <TextField
                value={newProduct.dealer_share}
                // className="product-input"
                // placeholder="% for Dealer                                              E.g. $10"
                label='Dealer share (INR)'
                variant='outlined'
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(e) => {
                  handleInput("dealer_share", e.target.value);
                }}
              />
            </div>
            <div className='flex-div'>
              <TextField
                value={newProduct.parent_share}
                // className="product-input"
                // placeholder='% for Parent                                 E.g. $50'
                label='Parent Share (INR)'
                variant='outlined'
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(e) => {
                  handleInput("parent_share", e.target.value);
                }}
              />
            </div>
            <div className='flex-div'>
              <TextField
                value={newProduct.grand_parent_share}
                // className="product-input"
                // placeholder="% for Grand Parent                           E.g. $50"
                label='Grand Parent Share (INR)'
                variant='outlined'
                style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
                onChange={(e) => {
                  handleInput("grand_parent_share", e.target.value);
                }}
              />
            </div>
            <div className='cash-on-delivery'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <p className='cash-text'>Cash on Delivery</p>
                <AntSwitch
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Stack>
            </div>
          </div>
          <div className='product-side-right' style={{ width: "40%", gap: 16 }}>
            {/* <div className="flex-div"> */}
            <Box
              sx={{
                display: "flex",
                paddingBottom: 2,
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}>
              <ToggleButtonGroup
                color='primary'
                defaultValue={1}
                value={selectedLang}
                exclusive
                onChange={(e, value) => setSelectedLang((prev) => value ?? prev)}
                aria-label='Platform'
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "100%",
                }}>
                {languages &&
                  languages?.map((lang) => (
                    <ToggleButton
                      key={lang.id}
                      value={lang.id}
                      sx={{ display: "flex", width: "100%" }}>
                      {lang.language}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </Box>
            {/* <Multiselect
              options={tags}
              selectedValues={isEdit ? tagsSelected : []}
              displayValue="name"
              placeholder="Tags"
              showArrow
              onSelect={(list, value) => handleTagInput(list, value, false)}
              onRemove={(list, value) => handleTagInput(list, value, true)}
              style={{
                searchBox: {
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "180px",
                },
                searchWrapper: {
                  border: "none",
                  "border-radius": "0px 8px 8px 0px",
                },
                inputField: {
                  padding: "5px 0px 5px 15px",
                  maxWidth: "150px",
                  fontSize: "16px",
                  cursor: "pointer",
                },
              }}
            /> */}
            {/* </div> */}

            <div className='flex-div'>
              {/* <TextField
                label='Product Description'
                multiline
                rows={4}
                value={translatedData.DESCRIPTION ?? ""}
                className=' desc-textarea'
                placeholder='Product Description'
                onChange={(e) => {
                  handleInput("description", e.target.value);
                }}
              /> */}
              <ReactQuill
                theme='snow'
                // style={{ width: "25rem", height: "10rem" }}
                placeholder='Product Description'
                value={
                  productDesc?.[selectedLang]?.DESCRIPTION?.content ??
                  `<p>Sample Description of ${product?.name} in language ${selectedLang}</p>`
                }
                onChange={(value) =>
                  handleDescInput("DESCRIPTION", {
                    ...productDesc?.[selectedLang]?.DESCRIPTION,
                    content: value,
                  })
                }
              />
            </div>
            <div className='flex-div'>
              <TextField
                label='Sharing Message'
                value={
                  productDesc?.[selectedLang]?.SHARE_MESSAGE?.content ??
                  `Sample SHARE_MESSAGE of ${product?.name} in language ${selectedLang}`
                }
                className=' desc-textarea'
                multiline
                rows={3}
                placeholder='Sharing Message'
                onChange={(e) => {
                  handleDescInput("SHARE_MESSAGE", {
                    ...productDesc?.[selectedLang]?.SHARE_MESSAGE,
                    content: e.target.value,
                  });
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <FormControl sx={{ width: "70%" }}>
                <InputLabel id='demo-multiple-name-label'>Tags</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple
                  value={tag_data}
                  onChange={handleChange3}
                  input={<OutlinedInput label='Name' />}
                  MenuProps={MenuProps}>
                  {tags?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={getStyles(item, tag_data, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
              <div
                className='cash-on-delivery'
                style={{
                  display: "flex",
                  marginLeft: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 16,
                }}>
                <p>Is Featured:</p>
                <AntSwitch
                  checked={newProduct.is_featured}
                  onChange={(e) => setNewProduct({ ...newProduct, is_featured: e.target.checked })}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </div>
            </div>

            <p className='ship-type-text'>Select Shipping Type:-</p>
            <div className='ship-type-div'>
              {selfShip === false ? (
                <button className='self-ship-button' onClick={handleShippingType}>
                  Self-Shipping
                </button>
              ) : (
                <button className='self-ship-selected'>Self-Shipping</button>
              )}
              {selfShip === false ? (
                <button className='kamao-kisan-selected'>KamaoKisan</button>
              ) : (
                <button className='kamao-kisan-button' onClick={handleShippingTypeKamao}>
                  KamaoKisan
                </button>
              )}
            </div>
            {!selfShip ? (
              <>
                <p className='select-text'>Dimensions</p>
                <div className='flex-div'>
                  <TextField
                    // className="dimension-input1"
                    // placeholder="cm"
                    value={newProduct?.length}
                    label='length (cm)'
                    variant='outlined'
                    style={{ marginRight: "10px" }}
                    onChange={(e) => {
                      handleInput("length", e.target.value);
                    }}
                  />
                  <TextField
                    value={newProduct?.breadth}
                    // className="dimension-input2"
                    // placeholder="cm"
                    label='breadth (cm)'
                    variant='outlined'
                    style={{ marginRight: "10px" }}
                    onChange={(e) => {
                      handleInput("breadth", e.target.value);
                    }}
                  />
                  <TextField
                    value={newProduct?.height}
                    // className="dimension-input3"
                    // placeholder="cm"
                    label='height (cm)'
                    variant='outlined'
                    style={{ marginRight: "10px" }}
                    onChange={(e) => {
                      handleInput("height", e.target.value);
                    }}
                  />
                </div>
                <div className='flex-div'>
                  <TextField
                    value={newProduct?.weight}
                    // className="product-input"
                    // placeholder="Product Weight
                    // E.g. 240 gm"
                    label='Product Weight (kg)'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={(e) => {
                      handleInput("weight", e.target.value);
                    }}
                  />
                </div>
                <p className='select-text' style={{ margin: "10px 0" }}>
                  Pick-up Address
                </p>
                <div className='flex-div'>
                  <TextField
                    value={add_data?.address}
                    // className="product-input"
                    // placeholder="Building No., Street Address"
                    label='Building No., Street Address'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={(e) => {
                      handleInput1("address", e.target.value);
                    }}
                  />
                </div>
                <div className='flex-div'>
                  <TextField
                    // className="product-input" placeholder="Pincode"
                    label='Pincode'
                    variant='outlined'
                    value={add_data?.pincode}
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={(e) => {
                      handleInput1("pincode", e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "5px",
                  }}>
                  <FormControl sx={{ my: 1, width: "49%" }}>
                    <InputLabel id='demo-name-label'>State</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={add_data?.state}
                      label='State'
                      onChange={(e) => {
                        handleInput1("state", e.target.value);
                      }}>
                      {State.getStatesOfCountry("IN").map((item) => {
                        return (
                          <MenuItem
                            value={[item.isoCode, item.name].join("-")}
                            onClick={() => setSelectedState(item)}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ my: 1, width: "49%" }}>
                    <InputLabel id='demo-name-label'>City</InputLabel>
                    <Select
                      value={add_data?.city}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='City'
                      disabled={!add_data?.state}
                      onChange={(e) => handleInput1("city", e.target.value)}>
                      {fetchedCities.map((item) => {
                        return <MenuItem value={item.name}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProductForm;
