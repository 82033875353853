import React, { useState, useEffect, useContext } from "react";
// import * as React from 'react';
import PropTypes from "prop-types";
import Plus from "../../../assets/plus.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// import {Box} from '@mui/material/Box';
import Banners from "./Banners";
import { apiInstace } from "../../server";
import axios from "axios";
import { Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import handleImageUpload from "../../Inventory/Utils/HandleImageUpload";
import { setISODay } from "date-fns";
import DashboardContext from "../../Context/DashboardContext";
import { toast } from "react-toastify";
// import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@mui/material";
// import { Box, Button } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  //   {
  //     id: "image",
  //     numeric: false,
  //     disablePadding: false,
  //     label: "Image",
  //   },
  //   {
  //     id: "discount",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Discount",
  //   },
  //   {
  //     id: "product",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Assigned Product",
  //   },
  //   {
  //     id: "status",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Status",
  //   },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorder);
      const filteredRows = await props.orirows.filter((row) => {
        return row.name.toLowerCase()?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
            Tags{" "}
          </Typography>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search Tags'
                onChange={handlesearch}
              />
              {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
            </div>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Brands() {
  const [offer_update_data, setOffer_update_data] = useState([]);
  const [products, setProducts] = useState([]);
  const [offers, setOffers] = useState([]);
  const [orioffers, setOriOffers] = useState([]);
  const navigate = useNavigate();
  const context = useContext(DashboardContext);
  const { setLoading, tags, getTags } = context;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [active_users, setActive_users] = React.useState(0)
  useEffect(() => {
    getTags();
  }, []);
  useEffect(() => {
    // console.log(brands);
    setOffers(tags);
    setOriOffers(tags);
  }, [tags]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = offers.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   useEffect(()=>{
  //     // const requestOptions = {
  //     //     headers: {
  //     //       method: "GET",
  //     //       Authorization: `Bearer ${localStorage.getItem("access")}`,
  //     //     },
  //     //   };
  //     // apiInstace.get(`/offers/getOffers/`,requestOptions)
  //     // .then((res)=>{
  //     //     console.log(res.data.results);
  //     //     setOffers([...res.data.results]);
  //     //     setOriOffers([...res.data.results])
  //     // })
  //     // .catch((err)=>{
  //     //     console.log(err);

  //     // })
  //     // apiInstace.get(`/products/getProducts/`,requestOptions)
  //     // .then((res)=>{
  //     //     console.log(res.data.results);
  //     //     let prod_id=[];
  //     //     res.data.results?.map((item)=>{prod_id.push({id:item.id,name:item.name})})
  //     //     setProducts([...prod_id]);
  //     // })
  //     // .catch((err)=>{
  //     //     console.log(err);

  //     // })

  //   },[])

  const GetProduct = ({ id }) => {
    // console.log(id);
    // console.log(products);
    const product = products.filter((item) => item.id == id);
    // console.log(product);
    return <p>{product[0]?.name}</p>;
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [offer_data, setOffer_data] = useState({
    // "image":"",
    name: "",
  });
  const refresh = () => {
    for (const key in offer_data) offer_data[key] = "";
  };
  const handleInput = (key, value) => {
    setOffer_data({ ...offer_data, [key]: value });
  };
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);

  const handleOpen = (i) => {
    setId(i);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [images, setImages] = useState([]);

  //   const handleupload=()=>{
  //     const  imginput=document.getElementById("brand_img");
  //     imginput.click();
  //     const geturl=(e)=>{
  //      const file=e.target.files[0];
  //     //  console.log(file)
  //      if(!file) return;
  //      setImages([file]);

  //     }
  //     imginput.addEventListener("change",geturl)

  //   }
  //   useEffect(()=>{
  //     handleImageUpload({images:images,setImages:setImages})
  //     .then((res)=>{
  //       const img_url=res;
  //       // console.log(img_url);
  //       setOffer_data({...offer_data,"image":img_url})
  //       document.querySelector(".brand_img1").src=img_url

  //       })
  //     .catch((err)=>console.log(err))

  //   },[images])

  useEffect(() => {
    // console.log(id);
    if (id >= 0) {
      // console.log(id);

      // const offer_data=(res.data.results);
      const offers_id_data = offers?.filter((item) => {
        return item.id == id;
      });
      // console.log(offers_id_data[0]);
      // const offers_data=offers_data;
      let offer = offer_data;
      for (const key in offers_id_data[0]) {
        if (offer[key] != undefined) offer[key] = offers_id_data[0][key];
      }
      // console.log(offers);
      setOffer_data({ ...offer });
      setOffer_update_data({ ...offer });
      // setoffers_data({...offers_id_data[0]});

      console.log(offer_data);
    }
  }, [id]);
  // console.log(cropsid);

  const handlesubmit = () => {
    setLoading(true);
    console.log(offer_data);
    // console.log(offer_update_data);
    // const token = `Bearer ${localStorage.getItem("access")}`;
    const requestOptions = {
      headers: {
        //   method: "DELETE",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    if (id >= 0) {
      let offer_update = {};
      for (const key in offer_data) {
        if (offer_update_data[key] != offer_data[key])
          offer_update = { ...offer_update, [key]: offer_data[key] };
      }
      console.log(offer_update);
      if (Object.keys(offer_update).length) {
        apiInstace
          .patch(`/tags/updateTag/${id}`, { tag_update: offer_update }, requestOptions)
          .then((res) => {
            console.log(res);
            toast.success("Tag Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleClose();
            setLoading(false);
            navigate("/content");
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            navigate("/content");
          });
      }
    } else {
      apiInstace
        .post(`/tags/createTag`, { tag_data: offer_data }, requestOptions)
        .then((res) => {
          console.log(res);
          toast.success("Tag Published!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          navigate("/content");
          handleClose();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
      setLoading(false);
      // navigate("/content")
    }
  };

  // const handlesubmit=()=>{
  //   console.log(offer_data);
  //   const token= `Bearer ${localStorage.getItem("access")}`
  //   apiInstace.post(`/offers/createOffer`,{offer_data,token})
  //   .then((res)=>{console.log(res.data);handleClose();})
  //   .catch((err)=>console.log(err))
  // }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - offers.length) : 0;
  //   const [status, setStatus] = useState({})

  //    useEffect(()=>{
  //      let status={};
  //      offers.map((item,index)=> {
  //        status={...status,[index]:item.status};

  //      }
  //      )
  //      console.log(status);
  //      setStatus({...status});
  // },[offers])
  // useEffect(()=>{
  //   console.log(status);
  // },[status])
  // const handleStatus=(key,value,id)=>{
  //   const token= `Bearer ${localStorage.getItem("access")}`
  //   setLoading(true);
  //   const offer_update={"status":value}
  //   console.log(offer_update);
  //   apiInstace.patch(`/offers/updateOffer/${id}`,
  //   {offer_update,token})
  //   .then((res) => {
  //     console.log(res);
  //     toast.success("Status Updated!", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     setLoading(false)

  //   })
  //   .catch((err) =>{
  //     toast.error("Something Went Wrong", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(err)
  //     setLoading(false)

  //   });
  // // navigate("/content")

  //   setStatus({...status,[key]:value})

  // }

  const handleDelete = (orderid, index) => {
    if (orderid >= 0) {
      console.log(orderid);

      const requestOptions = {
        headers: {
          method: "DELETE",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      apiInstace
        .delete(`/tags/deleteTag/${orderid}/`, requestOptions)
        .then((res) => {
          console.log(res);
          toast.success("Tag Deleted! It may take few minutes to reflect the changes", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false)
          console.log(index);
          // setLoading(false)
          document.querySelector(`.deloffbtn${index}`).disabled = true;
          handleClose1();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [ind, setIndex] = useState(-1);
  const [open1, setOpen1] = React.useState(false);
  const [offerid, setOfferId] = useState(-1);
  const handleOpen1 = (id, index) => {
    setOfferId(id);
    setIndex(index);
    setOpen1(true);
  };
  const handleClose1 = () => setOpen1(false);
  return (
    // <>
    // </>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <p>All Tags</p>
        <button
          onClick={() => {
            handleOpen(-1);
            refresh();
          }}
          className='create-new-post-button'>
          <img className='create-post-plus' alt='plus' src={Plus} />
          Create New Tag
        </button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className='modal-head'>
            <p className='modal-title'>Add a New Tag</p>
            <button className='close-button' onClick={handleClose}>
              X
            </button>
          </div>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <p className='Customer-info'>Name</p>
              <input
                placeholder='Enter Name'
                className='product-input'
                style={{ width: "350px", margin: "5px 0px" }}
                value={offer_data?.name}
                onChange={(e) => handleInput("name", e.target.value)}
              />
              {/* <p className="Customer-info">Discount</p>
                  <input
                    placeholder="Enter Discount"
                    className="product-input"
                    style={{ width: "350px", margin: "5px 0px" }}
                    value={offer_data?.percentage_off}
                    onChange={(e) => handleInput("percentage_off", e.target.value)}
                  />
                  <p className="Customer-info">Product</p>

                  <Select className='product-select1' label="Products" value={offer_data.product_id} onChange={(e) => handleInput("product_id", e.target.value)}>
                     {products?.map((item)=>(
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                     ))}
                  </Select> */}
              {/* <p
                    style={{ margin: "0px 0px 10px 0px" }}
                    className="Customer-info"
                  >
                    Upload Image
                  </p>
                  {offer_data?.image!=""?<img style={{width:"3rem",height:"3rem",marginBottom:"10px"}} src={offer_data?.image} className='brand_img1'/>:<></>}

                  <button className="upload-image-button" onClick={handleupload} style={{marginBottom:"1rem"}}>
                    <input type="file" accept="image/*" hidden id="brand_img"/>
                    Upload image
                  </button> */}
              <button
                className='upload-image-button'
                style={{ marginTop: "1rem" }}
                onClick={handlesubmit}>
                Save
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/*     
    <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Title</TableCell>
        <TableCell>Image</TableCell>
        <TableCell>Discount</TableCell>
        <TableCell>Assigned Product</TableCell>
        <TableCell>Status</TableCell>
        
      </TableRow>
    </TableHead>
    <TableBody>
      {offers?.map((row) => (
        <TableRow
          key={row.name}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        
          <TableCell>{row.offer_message}</TableCell>
          <TableCell><img style={{width:"5rem",height:"5rem"}} src={row.image} /></TableCell>
          <TableCell>{row.percentage_off}</TableCell>
          <TableCell>
           {row.product_id}
          </TableCell>
          <TableCell>
            <Select value={row?.status} className='product-select'>
              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem value="INACTIVE">INACTIVE</MenuItem>

            </select>
          </TableCell>
          <TableCell><button className='edit-button' onClick={()=>{handleOpen(row.id);}}>Edit</button></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer> */}
      <div className='order-table-main'>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'>
              <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Are you Sure, You want to Delete?
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <button
                    className='create-new-post-button'
                    style={{ margin: "10px 20px" }}
                    onClick={() => handleDelete(offerid, ind)}>
                    Delete
                  </button>
                  <button
                    className='create-new-post-button'
                    style={{ margin: "10px 20px" }}
                    onClick={handleClose1}>
                    Cancel
                  </button>
                </div>
              </Box>
            </Modal>
            <EnhancedTableToolbar
              numSelected={selected.length}
              rows={offers}
              setRows={setOffers}
              orirows={orioffers}
            />
            <TableContainer style={{ padding: "0px 20px" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={offers?.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? offers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : offers
                  )?.map((row, index) => {
                    // const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // if(row?.is_active=="true")
                    // props.setActive_users(props.active_users+1);

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role='checkbox'
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                        sx={{ cursor: "pointer" }}>
                        <TableCell component='th' id={labelId} scope='row' padding='none'>
                          {row.name}
                        </TableCell>
                        {/* <TableCell
                        align="left"
                        
                      >
                       <img style={{width:"5rem",height:"5rem"}} src={row.image} />
                      </TableCell> */}
                        {/* <TableCell
                        align="left"
                        
                      >
                      {row.percentage_off}
                      </TableCell>
    
                      <TableCell
                        align="left"
                        
                      >
                        <GetProduct id={row.product_id} />
                      </TableCell>
                      
                      <TableCell>
            <select value={status[index]} className='product-select' onChange={(e)=>handleStatus(index,e.target.value,row.id)}>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>

            </select>
          </TableCell> */}
                        <TableCell>
                          <button
                            className='edit-button'
                            onClick={() => {
                              handleOpen(row.id);
                            }}>
                            Edit
                          </button>
                        </TableCell>
                        <TableCell>
                          <DeleteIcon
                            className={`deloffbtn${index}`}
                            onClick={() => handleOpen1(row.id, index)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component='div'
              count={offers?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      </div>
    </Box>
  );
}

export default Brands;
