import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const RecentOrders = ({ orders }) => {
  // console.log(orders);
  let pending = 0,
    IN_PROGESS = 0,
    complete = 0,
    reject = 0;
  orders?.map((item) => {
    // console.log(item.status, item.status == "PENDING", typeof item.status);
    if (item.order.status == "PENDING") ++pending;
    else if (item.order.status == "IN_PROGESS") ++IN_PROGESS;
    else if (item.order.status == "DELIVERED") ++complete;
    else if (item.order.status == "REJECTED") ++reject;
  });
  const data = {
    labels: ["Delivered", "Pending", "In Progress", "Rejected"],
    datasets: [
      {
        label: "Orders",
        data: [complete, pending, IN_PROGESS, reject],
        backgroundColor: ["rgb(85, 111, 241)", "rgb(85, 111, 241,0.5)", "#fddb7c", "red"],
        hoverOffset: 1,
        weight: 0.3,
      },
    ],
  };
  var options = {
    cutout: 150,
  };

  return (
    <div
      style={{
        width: "30rem",
        height: "30rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Doughnut options={options} data={data} />
    </div>
  );
};

export default RecentOrders;
