import React, { useEffect, useState, useContext } from "react";
import Plus from "../../assets/plus.svg";
import AllCustomers from "./Layout/AllCustomers";
import NewCustomers from "./Layout/NewCustomers";
import CustomerTable from "./Layout/CustomerTable";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import { SettingsPowerRounded } from "@mui/icons-material";
import axios from "axios";
import { apiInstace } from "../server";
import DashboardContext from "../Context/DashboardContext";
import { toast } from "react-toastify";
import { State } from "country-state-city";
import { City } from "../../utils/country-state-city";
import { Select, MenuItem } from "@mui/material";
import { Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { useDebounce } from "use-debounce";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width:"100%",
  // width: 400,
  bgcolor: "#ffffff",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 1,
  backdropfilter: "blur(4px)",
};

const Customers = () => {
  // console.log(State.getAllStates());
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(-1);
  const [oldRole, setOldRole] = useState("");
  const [fetchAgain, setFetchAgain] = useState(false);

  const handleOpen = (i) => {
    setId(i);
    refreshdata();
    setOpen(true);
    setAddUpdated(false);
  };
  const handleClose = () => {
    setId(-1);
    setOpen(false);
    setLoading(false);
    setTempAadhaar({ part1: "", part2: "", part3: "" });
    delete cust_data["aadhar_number"];
  };
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const { setLoading } = context;
  // const [customers, setCustomers] = useState();
  // const [firstname, setFirstname] = useState("");
  // const [lastname, setLastname] = useState("");

  // const [Number, setNumber] = useState("");
  // const [address, setAddress] = useState("");
  // // const [Pin, setPin] = useState("")
  // const [state, setState] = useState("");
  // const [city, setCity] = useState("");
  // const [pin, setPin] = useState("")
  // const [billaddress, setBilladdress] = useState("");
  // const [Taluka, setTaluka] = useState("");
  // const [gaon, setGaon] = useState("");
  // const [user_role, setUser_role] = useState("");
  // const [under, setUnder] = useState("");
  // const [Adhaar, setAdhaar] = useState("");
  // const [pan, setPan] = useState("");
  // const [accnt_no, setAccnt_no] = useState("");
  // const [iifsc_no, setIifsc_no] = useState("");
  // const [pay_app, setPay_app] = useState("");
  // const [pay_phnno, setPay_phnno] = useState("");
  // const [gstno, setGstno] = useState("");

  const [checked, setChecked] = useState(false);
  const [role, setRole] = useState("");
  const [cust_data1, setCust_data1] = useState({});
  const [add_data1, setAdd_data1] = useState({});

  const [cust_data, setCust_data] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    role: "USER",
    bdo: "",
    // aadhar_number: "",
    // pan_number: "",
    // bank_account_number: "",
    // bank_ifsc_code: "",
    // payment_app_name: "",
    // payment_phone_number: "",
    // gst_number: "",
    image: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg",
  });
  const [add_data, setAdd_data] = useState({
    pincode: "",
    address: "",
    address_type: "SHIPPING",
    state: "",
    city: "",
    // billaddress,
    taluka: "",
    gaon: "",
    receiver_name: "",
    receiver_contact: "",
  });

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState([]);
  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    apiInstace
      .get(`/address/getAddress`, requestOptions)
      .then((res) => {
        setAddress(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   useEffect(() => {
  //     console.log("cust_data", cust_data);
  //   }, [cust_data]);

  const handleInput = (key, value) => {
    setCust_data((prev) => ({
      ...prev,
      image: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg",
      [key]: value,
    }));
  };

  const [addressUpdated, setAddUpdated] = useState(false);
  const handleInput1 = (key, value) => {
    !addressUpdated && setAddUpdated(true);
    setAdd_data({
      ...add_data,
      [key]: value,
    });
  };

  const [addid, setAddid] = useState();

  const [tempAadhaar, setTempAadhaar] = useState({ part1: "", part2: "", part3: "" });

  useEffect(() => {
    const update = [tempAadhaar.part1, tempAadhaar.part2, tempAadhaar.part3].join(" ");
    // update &&
    // Object.values(tempAadhaar).some((val) => Boolean(val))
    //   ?
    setCust_data({
      ...cust_data,
      aadhar_number: update,
    });
    // : delete cust_data["aadhar_number"];

    // console.log("updating aadhaar");
  }, [tempAadhaar]);

  // useEffect(() => {
  //   console.log(cust_data.aadhar_number);
  // }, [cust_data.aadhar_number]);

  const [fetchedCities, setFetchedCities] = useState([]);

  useEffect(() => {
    const cityUp = async () =>
      setFetchedCities((await City.getCitiesOfState("IN", add_data.state.split("-")[0])) ?? []);
    cityUp();
  }, [add_data.state]);

  const handlesubmit = async () => {
    if (
      (tempAadhaar.part1 || tempAadhaar.part2 || tempAadhaar.part3) &&
      (tempAadhaar.part1 + tempAadhaar.part2 + tempAadhaar.part3).length != 12
    ) {
      toast.error("Invalid Aadhaar number entered");
      return;
    }

    if (add_data["pincode"] && add_data["pincode"].length != 6)
      toast.error("Invalid pincode entered");

    const regexValidator = {
      regex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
      message: "Pan number must be entered in the format: 'ABCDE1234F'. Up to 10 digits allowed.",
    };
    // console.log(id);
    // console.log(names[0]);
    // console.log(lastname);

    const jsRegex = new RegExp(regexValidator.regex);
    if (
      // !(cust_data?.pan_number?.length == 10) ||
      cust_data?.pan_number &&
      !jsRegex.test(cust_data?.pan_number)
    ) {
      toast.error(regexValidator.message);
      return;
    }

    if (id >= 0) {
      if (cust_data["role"] === "DEALER" && cust_data["bdo"] === null) {
        toast.error("Please Select BDO", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setLoading(true);

        !Object.values(tempAadhaar).some((val) => Boolean(val)) &&
          delete cust_data["aadhar_number"];

        let cust_update = {};
        let add_update = {};
        for (const key in cust_data) {
          if (cust_data1[key] != cust_data[key])
            cust_update = { ...cust_update, [key]: cust_data[key] };
        }

        let addressChanged = false;
        for (const key in add_data) {
          if (add_data1[key] != add_data[key]) add_update = { ...add_update, [key]: add_data[key] };
        }
        // console.log({ "addupdate": add_update });
        const token = `Bearer ${localStorage.getItem("access")}`;
        console.log(add_update, cust_update);

        if (Object.keys(cust_update).length) {
          console.log(cust_update);

          try {
            const response = await apiInstace.patch(`/auth/user_update/${id}`, {
              cust_update,
              token,
            });

            toast.success("Customer Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            fetchUsers();
          } catch (err) {
            let errorMessages = err.response.data.message
              .replace(/\[ErrorDetail\(string='|', code='blank'\)\]|{|}/g, "")
              ?.split(", ");

            // Display each error message
            errorMessages.forEach((errorMessage) => {
              toast.error(`${errorMessage}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });

            return;
          }
        }

        if (Object.keys(add_update).length) {
          console.log(addid, add_update);
          if (addid != undefined) {
            apiInstace
              .patch(`/auth/address_update/${addid}`, { add_update, token })
              .then((res) => {
                toast.success("Address Updated!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                console.log(res);
                // setLoading(false);
                // setFetchAgain(!fetchAgain)
                // again();
                fetchUsers();
              })
              .catch((err) => {
                toast.error("Something Went Wrong", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                console.log(err);
              });
          } else {
            apiInstace
              .post(`/auth/address_register`, { add_data, token })
              .then((res1) => {
                console.log(res1.data);
                const cust_update = { address: [res1.data.id] };
                console.log(cust_update);

                !Object.values(tempAadhaar).some((val) => Boolean(val)) &&
                  delete cust_data["aadhar_number"];

                apiInstace
                  .patch(`/auth/user_update/${id}`, { cust_update, token })
                  .then((res3) => {
                    console.log(res3.data);
                    toast.success("Address Added!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setLoading(false);
                    // setFetchAgain(!fetchAgain)
                    // again();
                    // navigate("/customers")
                    fetchUsers();
                  })
                  .catch((err) => {
                    // toast.error("Something Went Wrong", {
                    //   position: "top-right",
                    //   autoClose: 5000,
                    //   hideProgressBar: true,
                    //   closeOnClick: true,
                    //   pauseOnHover: true,
                    //   draggable: true,
                    //   progress: undefined,
                    //   theme: "light",
                    // });
                    console.log(err);
                    setLoading(false);
                  });
              })
              .catch((err) => {
                toast.error(
                  `${JSON.stringify(
                    err?.response?.data.message?.replace(
                      /\[ErrorDetail\(string='|', code='blank'\)\]|{|}/g,
                      ""
                    )
                  )}`,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                console.log(err);
                setLoading(false);
              });
          }
        }
        // navigate("/customers")
      }
      // again();
      fetchUsers();
      // setLoading(false);
      setOpen(false);
      setId(-1);
    } else {
      console.log(cust_data, add_data);
      const token = `Bearer ${localStorage.getItem("access")}`;

      //  console.log(add_data);
      // setAdd_data({...add_data,"user":res.data.id})
      addressUpdated
        ? apiInstace
            .post(`/auth/address_register`, { add_data, token })
            .then(async (res1) => {
              console.log(res1.data);
              // let data={...cust_data,address:[res.data.id]}
              //  const address=res1.data;
              // console.log(data);
              regUser([res1.data.id]);

              setLoading(false);
            })
            .catch((err) => {
              toast.error(
                `${
                  err?.response?.data?.message
                    ? err.response.data.message.replace(
                        /\[ErrorDetail\(string='|', code='blank'\)\]|{|}/g,
                        ""
                      )
                    : // .replace(//g, "")
                      // .replace(/\\}/g, "")
                      "Default Message"
                }`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
              setLoading(false);
              //   console.log(err.response?.data.message);
              //   const matches = err.response?.data.message
              //     // .matchAll(/(\b\w+\b)\s*:\s*\[ErrorDetail\(string='(.*?)'/g)
              //     .replace(/\[ErrorDetail\(string='/g, "")
              //     .replace(/', code='blank')],/g, "");

              //   const newString = err?.response?.data?.message
              //     ? err.response.data.message
              //         .replace(/\[ErrorDetail\(string='/g, "")
              //         .replace(/', code='blank'\)\]/g, "")
              //         .replace(/\{\}/g, "")
              //     : "Default Message";

              //   console.log(newString);
            })
        : regUser();

      // await again();
      // setOpen(false);
      // setId(-1);

      // console.log(cust_data);
    }
    setFetchAgain(!fetchAgain);
    // again()
    // console.log("fetch again bro")

    // navigate("/customers")
  };

  const regUser = (addressIDs = []) => {
    !Object.values(tempAadhaar).some((val) => Boolean(val)) && delete cust_data["aadhar_number"];
    apiInstace
      .post(`/auth/user_register`, {
        cust_data: { ...cust_data, address: addressIDs },
        token: `Bearer ${localStorage.getItem("access")}`,
      })
      .then((res2) => {
        console.log(res2.data);
        // const id = res2.data.id;
        //   const cust_update = { address: [res1.data.id] };
        //   console.log(cust_update);
        toast.success("Customer Added!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleClose();
      })
      .catch((err) => {
        toast.error(
          `${JSON.stringify(
            err?.response?.data?.message.replace(
              /\[ErrorDetail\(string='|', code='blank'\)\]|{|}/g,
              ""
            )
          )}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        //   console.log(err.response.data);
      });
  };

  const [rows, setRows] = useState([]);
  const [orirows, setOriRows] = useState([]);

  const [tot_users, setTot_users] = useState();
  const [active_users, setActive_users] = useState(0);
  const [bdos, setBdos] = useState([]);
  const refreshdata = () => {
    for (const key in cust_data) cust_data[key] = "";
    for (const key in add_data) add_data[key] = "";
    setAdd_data({ ...add_data, address_type: "SHIPPING" });
  };

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [active_users, setActive_users] = React.useState(0)
  const [roles, setRoles] = useState([]);
  const [query, setQuery] = useState("");
  const [nonZeroCust, setNonZeroCust] = useState(false);
  // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3ODgyMjg0LCJpYXQiOjE2ODc0MzU0OTMsImp0aSI6IjgyNmIxMGVlNzc2YTQwNzA4MjE2NmMwMDIzYjQzMTQyIiwidXNlcl9pZCI6OTB9.lsoyuOy800HQAPW35BY7SN5HiX6wQREE4e8Y1XKcKCM`,
  const [locationQuery, setLocationQuery] = useState({ city: "", state: "" });

  const [name] = useDebounce(query, 600);

  const fetchUsers = async (page) => {
    setLoading(true);

    apiInstace
      .get(`/users/getUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
        params: {
          page: page + 1,
          size: rowsPerPage || undefined,
          name: name || undefined,
          roles: roles.length > 0 ? roles.join(",") : undefined,
          zero_bal: nonZeroCust || undefined,
          city: locationQuery.city || undefined,
          state: locationQuery.state || undefined,
        },
      })
      .then((res) => {
        // if (!res) return;
        // if (rowsPerPage > res?.data?.count && page !== 1) {
        //   setPage(0);
        //   return;
        // }
        setRows(res.data.results);
        setOriRows([...res.data.results]);
        let bdo = [];
        let active = 0;
        res.data.results.map((item) => {
          if (item.role == "BDO") bdo.push({ id: item.id, name: item.first_name });
          if (item.is_active) active++;
        });
        setActive_users(res.data.count);
        // console.log(bdo);
        setBdos([...bdo]);
        setTot_users(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);

        if (err?.response?.data?.error?.detail === "Invalid page.") setPage(0);
        // console.log(err);
      });
  };

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  useEffect(() => {
    // console.log(first)
    // setPage(0);
    fetchUsers(0);
    setPage(0);
  }, [fetchAgain, rowsPerPage, name, roles, nonZeroCust, locationQuery]);

  // const again = async () => {
  //   const requestOptions = {
  //     headers: {
  //       method: "GET",
  //       Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3ODgyMjg0LCJpYXQiOjE2ODc0MzU0OTMsImp0aSI6IjgyNmIxMGVlNzc2YTQwNzA4MjE2NmMwMDIzYjQzMTQyIiwidXNlcl9pZCI6OTB9.lsoyuOy800HQAPW35BY7SN5HiX6wQREE4e8Y1XKcKCM`,
  //     },
  //   };
  //   apiInstace
  //     .get(`/users/getUsers`, requestOptions)
  //     .then((res) => {
  //       // console.log(res.data.users);
  //       setRows(res.data.users);
  //       setOriRows([...res.data.users]);
  //       let bdo = [];
  //       let active = 0;
  //       res.data.users.map((item) => {
  //         if (item.role == "BDO") bdo.push({ id: item.id, name: item.first_name });
  //         if (item.is_active) active++;
  //       });
  //       setActive_users(active);
  //       // console.log(bdo);
  //       setBdos([...bdo]);
  //       setTot_users(res.data.users.length);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);

  //       console.log(err);
  //     });

  //   apiInstace
  //     .get(`/address/getAddress`, requestOptions)
  //     .then((res) => {
  //       setAddress(res.data.results);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    if (id >= 0) {
      // console.log(id);
      setLoading(true);

      const row_data = rows.filter((item) => {
        return item.id == id;
      });
      //   console.log(row_data[0]);
      const row_datas = row_data[0];
      // refreshdata();
      let tempcust = cust_data;
      for (const key in row_datas) {
        // console.log(key,row_datas[key]);
        if (cust_data[key] != undefined) {
          // console.log(key,cust_data[key]);
          tempcust[key] = row_datas[key];
        }
      }
      //   console.log(tempcust);
      setCust_data(tempcust);
      //   console.log(tempcust?.aadhar_number?.split(" "));
      setTempAadhaar({
        part1: tempcust?.aadhar_number?.split(" ")[0] ?? "",
        part2: tempcust?.aadhar_number?.split(" ")[1] ?? "",
        part3: tempcust?.aadhar_number?.split(" ")[2] ?? "",
      });
      //   console.log("handleAadharInput", {
      //     part1: tempcust?.aadhar_number?.split(" ")[0] ?? "",
      //     part2: tempcust?.aadhar_number?.split(" ")[1] ?? "",
      //     part3: tempcust?.aadhar_number?.split(" ")[2] ?? "",
      //   });
      setRole(tempcust?.role);
      setOldRole(tempcust?.role);
      tempcust = add_data;
      if (row_datas?.address?.length != 0) {
        const add_id = row_datas?.address[row_datas?.address?.length - 1];
        const address_data = address?.filter((item) => {
          return item.id == add_id;
        });
        for (const key in address_data[0]) {
          // console.log(row_data[key]);
          if (add_data[key] != undefined) tempcust[key] = address_data[0][key];
        }
        setAddid(address_data[0]?.id);
        // console.log({ "something": tempcust })
        setAdd_data({ ...tempcust });
        setAdd_data1({ ...tempcust });
      } else {
        for (const key in add_data) add_data[key] = "";
        setAdd_data({ ...add_data, address_type: "SHIPPING" });
        setAdd_data1({ ...add_data, address_type: "SHIPPING" });
      }
      // console.log(add_data);
      // console.log(cust_data);
      setCust_data1({ ...cust_data });

      setLoading(false);
    }
  }, [id]);

  // useEffect(()=>{
  //   console.log(add_data1);
  //     console.log(cust_data1);
  // },[add_data1])
  // console.log(selectedState);
  //  console.log(role);
  // console.log(cust_data,add_data);const AadharNumberInput = ({ value, onChange }) => {

  const handleAadharInput = (value, index) => {
    // Remove non-numeric characters
    let numericValue = value.replace(/\D/g, "");

    // Ensure the final Aadhar number does not exceed 12 digits
    if (numericValue.length > 4) {
      numericValue = numericValue.slice(0, 4);
    }

    setTempAadhaar((prevTempAadhaar) => ({
      ...prevTempAadhaar,
      [`part${index + 1}`]: numericValue,
    }));

    // const newAadhar = `${tempAadhaar.part1.padEnd(4, "_")} ${tempAadhaar.part2.padEnd(
    //   4,
    //   "_"
    // )} ${tempAadhaar.part3.padEnd(4, "_")}`.replace(/_/g, "");

    // handleInput("aadhar_number", newAadhar);
  };

  //   useEffect(() => {
  //     console.log("aadhar_number", cust_data["aadhar_number"]);
  //   }, [cust_data]);

  return (
    <div>
      <div className='order-head'>
        <p className='order-title'>Customers Summary</p>
        <button
          onClick={() => {
            refreshdata();
            handleOpen(-1);
          }}>
          <img alt='plus' src={Plus} />
          Add New Customer
        </button>
      </div>
      {/* <div className='customer-grid' style={{ width: "100%", display: "flex" }}>
        <div className='allcustomers' style={{ width: "100%" }}>
          <AllCustomers no_users={tot_users} active_users={active_users} />
        </div>  
      </div> */}
      <div>
        <CustomerTable
          rows={rows}
          setRows={setRows}
          orirows={orirows}
          handleOpen={handleOpen}
          active_users={active_users}
          setActive_users={setActive_users}
          tot_users={tot_users}
          roles={roles}
          setRoles={setRoles}
          query={query}
          setQuery={setQuery}
          nonZeroCust={nonZeroCust}
          setNonZeroCust={setNonZeroCust}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          locationQuery={locationQuery}
          setLocationQuery={setLocationQuery}
        />
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <div className='modal-head'>
              {id == -1 ? (
                <p className='modal-title'>Add a New Customer</p>
              ) : (
                <p className='modal-title'>Update Customer</p>
              )}
              <button className='close-button' onClick={handleClose}>
                X
              </button>
            </div>
            {/* <p className="Customer-info">Customer Information</p> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "start",
                width: "100%",
                height: "auto",
              }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "40%",
                  mx: 2,
                  gap: 1.2,
                }}>
                <TextField
                  // placeholder="First Name"
                  // className="product-input"
                  label='First Name*'
                  variant='outlined'
                  style={{ width: "100%", marginTop: "5px" }}
                  value={cust_data?.first_name}
                  onChange={(e) => handleInput("first_name", e.target.value)}
                />
                <TextField
                  // placeholder="Last Name"
                  // className="product-input"
                  label='Last Name*'
                  variant='outlined'
                  style={{ width: "100%", marginTop: "5px" }}
                  value={cust_data?.last_name}
                  onChange={(e) => handleInput("last_name", e.target.value)}
                />
                <TextField
                  // placeholder="Mobile Number"
                  // className="product-input"
                  label='Phone*'
                  variant='outlined'
                  style={{ width: "100%", marginTop: "5px" }}
                  value={cust_data?.phone}
                  onChange={(e) => handleInput("phone", e.target.value)}
                  disabled={cust_data?.phone && id > -1}
                />
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  <div className='flex-div'>
                    <TextField
                      // className="product-input"
                      label='Address'
                      variant='outlined'
                      style={{ width: "100%", marginTop: "5px" }}
                      // placeholder="Building No., Street Address"
                      value={add_data?.address}
                      onChange={(e) => handleInput1("address", e.target.value)}
                      // required
                    />
                  </div>
                  <div className='flex-div'>
                    <TextField
                      // className="product-input"
                      label='Pin'
                      variant='outlined'
                      style={{ width: "100%", marginTop: "5px" }}
                      // placeholder="Pincode"
                      value={add_data?.pincode}
                      onChange={(e) => handleInput1("pincode", e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    {/* <div style={{display:"flex" ,flexDirection:"column",width:"40%"}}>
                  <p className="Customer-info">State</p> */}
                    <FormControl sx={{ my: 1, width: "49%" }}>
                      <InputLabel id='demo-name-label'>State</InputLabel>
                      <Select
                        // className="product-select1"
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={add_data?.state}
                        label='State'
                        // style={{marginTop:"15px" }}
                        // required
                        onChange={(e) => {
                          handleInput1("state", e.target.value);
                        }}>
                        {State.getStatesOfCountry("IN").map((item) => {
                          //   {
                          //     console.log(item);
                          //   }
                          return (
                            <MenuItem
                              value={[item.isoCode, item.name].join("-")}
                              onClick={() => setSelectedState(item)}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {/* </div> */}
                    {/* <div style={{display:"flex",flexDirection:"column"}}> */}

                    {/* <p className="Customer-info"></p>City */}
                    <FormControl sx={{ my: 1, width: "49%" }}>
                      <InputLabel id='demo-name-label'>City</InputLabel>
                      <Select
                        //  className="product-select2"

                        value={add_data?.city}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='City'
                        // required
                        onChange={(e) => handleInput1("city", e.target.value)}>
                        {fetchedCities.length ? (
                          fetchedCities.map((item) => {
                            return <MenuItem value={item.name}>{item.name}</MenuItem>;
                          })
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                    {/* </div> */}
                  </div>
                  {/* <div className="billing-add-div" style={{display:"flex",justifyContent:"start"}}>
                  <p className="billing-address-text" style={{textAlign:"left"}}>Billing Address</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="same-customer-add" style={{textAlign:"left"}}>
                      Same as Customer Address
                    </p>
                    <Switch
                      checked={checked}
                      onChange={(e)=>setChecked(e.target.value)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div> 
                </div>  */}
                  <div className='flex-div'>
                    <TextField
                      // className="product-input"
                      // placeholder="Taluka"
                      label='Taluka'
                      variant='outlined'
                      style={{ width: "100%" }}
                      value={add_data?.taluka}
                      onChange={(e) => handleInput1("taluka", e.target.value)}
                      // style={{width:"20%"}}
                    />
                    <TextField
                      // className="product-input"
                      // placeholder="Gaon"
                      label='Gaon'
                      variant='outlined'
                      style={{ width: "100%", marginLeft: "5px" }}
                      value={add_data?.gaon}
                      onChange={(e) => handleInput1("gaon", e.target.value)}
                      // style={{width:"20%"}}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    <FormControl sx={{ my: 1, width: "49%" }}>
                      <InputLabel id='demo-name-label'>Role</InputLabel>
                      <Select
                        // className="product-select1"
                        disabled={id > 0 && oldRole !== "USER" ? true : false}
                        value={cust_data?.role}
                        onChange={(e) => {
                          setRole(e.target.value);
                          handleInput("role", e.target.value);
                          if (e.target.value != "DEALER") handleInput("bdo", null);
                        }}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Role'>
                        <MenuItem value='BDO'>BDO</MenuItem>
                        <MenuItem value='USER'>USER</MenuItem>
                        <MenuItem value='DEALER'>Dealer</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ my: 1, width: "49%" }}>
                      <InputLabel id='demo-name-label'>Under</InputLabel>
                      <Select
                        // className="product-select2"
                        disabled={id != -1 && cust_data?.bdo}
                        value={cust_data?.bdo ? cust_data?.bdo : "USER"}
                        onChange={(e) => handleInput("bdo", e.target.value)}>
                        <MenuItem value='null'>None</MenuItem>
                        {role != "DEALER"
                          ? []
                          : bdos.map((item) => {
                              return <MenuItem value={item.id}>{item.name}</MenuItem>;
                            })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  mx: 2,
                  gap: 1.2,
                }}>
                {/* <div className="flex-div"> */}
                <p style={{ marginBottom: "5px" }}>Aadhar Number</p>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {[0, 1, 2].map((index) => (
                    <TextField
                      key={index}
                      // label={`(${index + 1})`}
                      variant='outlined'
                      style={{ width: "30%", margin: "0 5px" }}
                      value={tempAadhaar[`part${index + 1}`]}
                      onChange={(e) => handleAadharInput(e.target.value, index)}
                    />
                  ))}
                </div>
                {/* </div> */}
                <div className='flex-div'>
                  <TextField
                    label='Pan number'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "5px" }}
                    value={cust_data?.pan_number}
                    onChange={(e) => handleInput("pan_number", e.target.value.toUpperCase())}
                    // required
                  />
                </div>
                <div className='flex-div'>
                  <TextField
                    // className="product-input"
                    // placeholder="Bank Account No."
                    label='Account number'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "5px" }}
                    value={cust_data?.bank_account_number}
                    onChange={(e) => handleInput("bank_account_number", e.target.value)}
                  />
                </div>
                <div className='flex-div'>
                  <TextField
                    // className="product-input"
                    // placeholder="Bank IFSC Code"
                    label='Ifsc code'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "5px" }}
                    value={cust_data?.bank_ifsc_code}
                    onChange={(e) => handleInput("bank_ifsc_code", e.target.value)}
                  />
                </div>
                <div className='flex-div'>
                  <TextField
                    // className="product-input"
                    // placeholder="Payment App Name"
                    label='Pay App'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "5px" }}
                    value={cust_data?.payment_app_name}
                    onChange={(e) => handleInput("payment_app_name", e.target.value)}
                  />
                  <TextField
                    // className="product-input"
                    // placeholder="Payment Phone no."
                    label='Pay phone'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "5px" }}
                    value={cust_data?.payment_phone_number}
                    onChange={(e) => handleInput("payment_phone_number", e.target.value)}
                  />
                </div>
                <div className='flex-div'>
                  <TextField
                    // className="product-input"
                    // placeholder="GST no."
                    label='GST no.'
                    variant='outlined'
                    style={{ width: "100%", marginTop: "5px" }}
                    value={cust_data?.gst_number ?? ""}
                    onChange={(e) => handleInput("gst_number", e.target.value)}
                  />
                </div>
                <div className='section-button-add' style={{ marginTop: "88px" }}>
                  <button
                    className='cancel-add-button'
                    onClick={(e) => {
                      e.preventDefault();
                      //   setId(-1);
                      //   setOpen(false);
                      handleClose();
                    }}>
                    Cancel
                  </button>
                  <button
                    className='add-add-button'
                    onClick={(e) => {
                      handlesubmit();
                      // setId(-1)
                      // setFetchAgain(!fetchAgain)
                    }}>
                    {id == -1 ? "Create" : "Update"}
                  </button>
                </div>
              </Box>
            </Box>

            {/* <div className="switch-section">
              <p className="add-address-text">Add Address</p>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div> */}
            {/* {checked ? ( */}

            {/* ) : ( */}

            {/* )} */}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Customers;
