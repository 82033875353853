import axios from "axios";

// const server = "http://localhost:8000";
// If you are on local uncomment above line and comment below, before pushing revert changes to this file
const server = "https://admin.kamaokisan.com";

// Keep this on server

const apiInstace = axios.create({
  baseURL: server,
});

apiInstace.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstace.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const orignalRequest = error.config;

    if (error.response.status === 401 && !orignalRequest._retry) {
      orignalRequest._retry = true;

      const refreshToken = localStorage.getItem("refresh");

      if (refreshToken) {
        try {
          const formData = new FormData();
          formData.append("refresh", refreshToken);

          const refreshResponse = await axios.post(`${server}/auth/refresh`, {
            refreshToken: refreshToken,
          });

          const newAccessToken = refreshResponse.data.access;
          console.log("successfull refresh");
          localStorage.setItem("access", newAccessToken);
          localStorage.setItem("token", newAccessToken);

          orignalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return apiInstace(orignalRequest);
        } catch (refreshError) {
          localStorage.clear();
          alert("Login session expired. Redirecting to login...");
          window.location.href = server;
        }
      }
      // else if (localStorage.getItem("access")) {
      //   alert("No refresh token. Login session expired. Redirecting to login...");
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   window.location.href = server;
      //   // return Promise.reject(error);
      // }
    }
    // else if (error.response.status === 401 && orignalRequest._retry) {
    //   alert("Login session expired. Redirecting to login...");
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   window.location.href = server;
    //   return Promise.reject(error);
    // }
  }
);

export { apiInstace };
export default server;
