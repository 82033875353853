// import DashboardContext from "../../../Components/Context/DashboardContext";
// import { useContext } from "react";
// import AWS from "aws-sdk";
import { apiInstace } from "../../../Components/server";
import axios from "axios";
const { v4: uuidv4 } = require("uuid");

// AWS.config.update({
//   accessKeyId: "AKIAROQUFKO7UDWX2NNI",
//   secretAccessKey: "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",
//   region: "ap-south-1",
// });

// const s3 = new AWS.S3();

// export default async function handleImageUpload({ images, setImages }) {
//   //   const { s3 } = useContext(DashboardContext);

//   // console.log(images);
//   const length = images?.filter((item) => item != null)?.length;
//   let compiledUrl = "";
//   for (let i = 0; i < length; i++) {
//     const file = images[i];
//     if (!file) {
//       continue;
//     }
//     if (typeof file === "string") {
//       if (i === length - 1) {
//         compiledUrl += file;
//       } else {
//         compiledUrl += file + ", ";
//       }

//       console.log(compiledUrl);
//       continue;
//     }

//     const fileName = file.name;

//     // Configure the S3 upload parameters
//     const params = {
//       Bucket: "farmore-assets",
//       Key: `${Date.now()}.${fileName}`,
//       ContentType: file.type,
//       Body: file,
//     };

//     try {
//       // Upload the image to S3
//       const data = await s3.upload(params).promise();
//       const imageUrl = data.Location;

//       if (i === length - 1) {
//         compiledUrl += imageUrl;
//       } else {
//         compiledUrl += imageUrl + ", ";
//       }
//       console.log(compiledUrl);
//       const newImages = [...images];
//       newImages[i] = imageUrl;
//       setImages(newImages);
//     } catch (error) {
//       console.error("Error uploading image:", error);
//     }
//   }

//   return compiledUrl;
// }

export default async function handleImageUpload({ images, setImages }) {
  const length = images?.filter((item) => item != null)?.length;
  let compiledUrl = "";

  //   console.log("handleImageUpload");

  for (let i = 0; i < length; i++) {
    const file = images[i];

    if (!file) continue;
    // console.log("handleImageUpload file present");

    if (typeof file === "string") {
      if (i === length - 1) {
        compiledUrl += file;
      } else {
        compiledUrl += file + ", ";
      }

      console.log(compiledUrl);
      continue;
    }

    const fileName = file.name;

    // console.log("handleImageUpload", fileName);

    try {
      // Get presigned URL for upload
      const response = await apiInstace.get("api/get-upload-url", {
        params: { fileName: uuidv4(), fileType: file.type },
      });

      //   console.log("handleImageUpload", response);

      const uploadURL = response.data.uploadURL;
      const downloadURL = response.data.downloadURL.split("?")[0];

      console.log(uploadURL, downloadURL);

      // Upload the image to S3 using presigned URL
      await axios.put(uploadURL, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      //   const imageUrl = uploadURL.split("?")[0]; // Extract the URL without query parameters

      if (i === length - 1) {
        compiledUrl += downloadURL;
      } else {
        compiledUrl += downloadURL + ", ";
      }

      console.log(compiledUrl);

      const newImages = [...images];
      newImages[i] = downloadURL;
      setImages(newImages);
    } catch (error) {
      //   console.error("Error uploading image:", error);
      toast.error(`Error uploading image: ${JSON.stringify(error.response.data)}`);
    }
  }

  return compiledUrl;
}

export async function handleDeleteReq(url) {
  try {
    // Extract the key (filename) from the S3 URL
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];

    // Call the delete route with the extracted filename
    await apiInstace.delete("api/delete-object", {
      params: { fileName },
    });

    // Deletion successful
    return { success: true, message: "Object deleted successfully" };
  } catch (error) {
    // Error occurred during deletion
    console.error("Error deleting object:", error);
    return { success: false, message: "Failed to delete object" };
  }
}
