import React, { useEffect, useState, useRef, useContext } from "react";
// import BagTable from "../../../assets/Bag-table.svg";
// import Plus from "../../../assets/plus.svg";
// import { compareAsc, format } from "date-fns";
import PropTypes from "prop-types";
import { DownloadTableExcel } from "react-export-table-to-excel";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";

// import DeleteIcon from '@mui/icons-material/Delete';
import {
  alpha,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import server, { apiInstace } from "../../server";

import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import ModalType from "./Filter";
import { ModalFilterByDate } from "./Filter";
import Select from "@mui/material/Select";
import { CheckBox } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DashboardContext from "../../Context/DashboardContext";
import { toast } from "react-toastify";
// import Select from '@mui/material/Select';

function createData(name, orderDate, orderType, trackingID, orderTotal, action, status) {
  return {
    name,
    orderDate,
    orderType,
    trackingID,
    orderTotal,
    action,
    status,
  };
}

const rows = [
  createData(
    "Cupcake",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "completed"
  ),
  createData(
    "Donut",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "pending"
  ),
  createData(
    "Eclair",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "inprogress"
  ),
  createData(
    "Frozen yoghurt",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "completed"
  ),

  createData(
    "Gingerbread",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "completed"
  ),
  createData("Honeycomb", 408, 3.2, 87, 6.5, 3, 3),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 3, 3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0, 3, 3),
  createData("KitKat", 518, 26.0, 65, 7.0, 3, 3),
  createData("Lollipop", 392, 0.2, 98, 0.0, 3, 3),
  createData("Marshmallow", 318, 0, 81, 2.0, 3, 3),
  createData("Nougat", 360, 19.0, 9, 37.0, 3, 3),
  createData("Oreo", 437, 18.0, 63, 4.0, 3, 3),
];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "Customer_details",
    numeric: false,
    disablePadding: true,
    label: "Customer details",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: true,
    label: "Address details",
  },
  {
    id: "Order_Details",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
  },
  {
    id: "Qunatity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "trackingId",
    numeric: true,
    disablePadding: false,
    label: "Tracking ID",
  },
  {
    id: "orderTotal",
    numeric: true,
    disablePadding: false,
    label: "Order Total",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <CheckBox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {/* <TableCell>

        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const [filterType, setFilterType] = useState(false);
  const [filterModalByDate, setFilterModalByDate] = useState(false);
  // using below modal for date filter
  const handleOpenModalType = () => {
    setFilterModalByDate(!filterModalByDate);
  };

  const handlModalFilterByDate = () => {
    setFilterModalByDate(!filterModalByDate);
  };

  const handlesearch = async (e) => {
    // if (e.target.value != "") {
    //   // console.log(e.target.value);
    //   console.log(props.oriorderData);
    //   const filteredRows = await props.oriorderData.filter((row) => {
    //     return row?.order?.receiver_name?.toLowerCase()
    //       ?.includes(e.target.value.toLowerCase());
    //   });
    //   // setRows(filteredRows);
    //   console.log(filteredRows);
    //   props.setOrderData(filteredRows);
    // } else props.setOrderData(props.oriorderData);

    // console.log(props.oriorderData);

    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorderData);
      const filteredRows = await props.oriorderData.filter((row) => {
        const curruser = props.users.filter((user) => {
          return user.id === row?.order?.user_id;
        });
        // console.log(curruser[0].first_name)
        return (
          curruser[0]?.first_name.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
          curruser[0]?.last_name.toLowerCase()?.includes(e.target.value.toLowerCase())
        );
      });

      ///now also filter by order type and product

      console.log(filteredRows);
      props.setOrderData(filteredRows);
      let filteredbyordertype = filteredRows.filter((row) => {
        console.log(row?.order?.order_type?.toLowerCase());
        return (
          row?.order?.order_type?.toLowerCase() == props.order_type.toLowerCase() &&
          row?.product?.id === props.product
        );
      });
      console.log(filteredbyordertype);
      props.setOrderData(filteredbyordertype);
    } else {
      // setRows(filteredRows);
      console.log(props.order_type.toLowerCase());
      let filteredbyordertype = props.oriorderData.filter((row) => {
        // console.log(row?.order_type?.toLowerCase());
        return (
          row?.order?.order_type?.toLowerCase() == props.order_type.toLowerCase() &&
          row?.product?.id === props.product
        );
      });
      console.log(filteredbyordertype);
      props.setOrderData(filteredbyordertype);
    }
  };

  const handleDuration = async (startDate, endDate) => {
    handleOpenModalType();
    console.log("called handle duration parent");
    console.log({
      start: startDate,
      end: endDate,
    });

    const filteredRows = await props.oriorderData.filter((row) => {
      const targetDate = new Date(row?.created_at);
      console.log(targetDate);
      return targetDate >= startDate && targetDate <= endDate;
      // .toLowerCase()
      // ?.includes(e.target.value.toLowerCase());;
    });
    console.log(filteredRows);
    // props.setOrderData(filteredRows)
    let filteredbyordertype = filteredRows.filter((row) => {
      console.log(row?.order?.order_type?.toLowerCase());
      return (
        row?.order?.order_type?.toLowerCase() == props.order_type.toLowerCase() &&
        row?.product?.id === props.product
      );
    });
    console.log(filteredbyordertype);
    props.setOrderData(filteredbyordertype);
  };

  //   const
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
              Customer Orders
            </Typography>
            <div className='filter-div'>
              {/* <select
                // options={crops?.results}
                // isClearable={true}
                displayValue="name"
                placeholder="Select shipping type"
                showArrow
                // onSelect={handleCropsFilter}
                // onRemove={handleCropsFilter}
                style={{
                  width:"150px",
                  height:"3rem",
                  searchBox: {
                    border: "none",
                    "border-radius": "8px 0px 0px 8px",
                  },
                  searchWrapper: {
                    border: "none",
                    "border-radius": "8px 0px 0px 8px",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    width: "500px",
                    fontSize: "16px",
                  },
                }}
              /> */}
              {/* <select
                // options={equipment?.results}
                displayValue="name"
                placeholder="Brand"
                showArrow
                // onSelect={handleFarmEquipmentFilter}
                // onRemove={handleFarmEquipmentFilter}
                style={{
                  width:"150px",
                  height:"3rem",
                  searchBox: {
                    border: "none",
                    "border-left": "1px solid #5e6366",
                    "border-radius": "0px",
                  },
                  searchWrapper: {
                    border: "none",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    width: "150px",
                    fontSize: "16px",
                  },
                }}
              />
  */}
              {/* <Box sx={{ minWidth: 120 }}> */}
              <FormControl sx={{ marginRight: "5px" }} size='small'>
                <InputLabel id='demo-simple-select-label'>Product</InputLabel>

                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Product'
                  value={props.product}
                  onChange={(e) => {
                    console.log(props.product);
                    props.setProduct(e.target.value);
                  }}
                  style={{
                    width: "150px",
                    //   height: "3rem",
                    searchBox: {
                      border: "none",
                      "border-left": "1px solid #5e6366",
                      "border-radius": "0px",
                    },
                    searchWrapper: {
                      border: "none",
                    },
                    inputField: {
                      //   padding: "5px 0px 5px 15px",
                      width: "150px",
                      fontSize: "16px",
                    },
                  }}>
                  {props.products_data?.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* </Box> */}
              <Box sx={{ minWidth: 120 }}>
                <FormControl size='small'>
                  <InputLabel id='demo-simple-select-label'>Order Type</InputLabel>

                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Product'
                    value={props.order_type}
                    onChange={(e) => {
                      props.setOrder_type(e.target.value);
                    }}
                    // sx={{ mx: 2 }}
                    style={{
                      // width: "150px",
                      height: "3rem",
                      searchBox: {
                        border: "none",
                        "border-left": "1px solid #5e6366",
                        "border-radius": "0px",
                      },
                      searchWrapper: {
                        border: "none",
                      },
                      inputField: {
                        // padding: "5px 0px 5px 15px",
                        // width: "150px",
                        fontSize: "16px",
                      },
                    }}>
                    <MenuItem value='CASH_ON_DELIVERY'>Cash on Delivery</MenuItem>
                    <MenuItem value='HOME_DELIVERY'>Prepaid</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Box>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search'
                onChange={handlesearch}
              />
              <div style={{ display: "flex" }}>
                <div className='table-head-buttons' onClick={() => handleOpenModalType()}>
                  <img alt='img' src={Vector} />
                  <p className='filter-text'>Filter</p>
                </div>
                {/* <div
                  className="table-head-buttons"
                  onClick={() => handlModalFilterByDate()}
                >
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Send} />
                  <p className="filter-text">Share</p>
                </div> */}
                <DownloadTableExcel
                  filename='Customer Orders'
                  sheet='Customer Orders'
                  currentTableRef={props.tableRef.current}>
                  <button className='table-head-buttons'> Export excel </button>
                </DownloadTableExcel>
              </div>
              {/* <IconButton>
                <FilterListIcon />
              </IconButton> */}
            </div>
          </Tooltip>
        )}
      </div>
      {/* <div className="modaltype-div">
        <ModalType
          filterType={filterType}
          oriorderData={props.oriorderData}
          setOrderData={props.setOrderData}
          orderData={props.orderData}
        />
      </div> */}
      <div className='modalfilterbydate-div'>
        <ModalFilterByDate filterModalByDate={filterModalByDate} handleDuration={handleDuration} />
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrderTable = ({ getOrder, functionchange }) => {
  const [order_type, setOrder_type] = useState("CASH_ON_DELIVERY");
  // console.log(getOrder);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [orderData, setOrderData] = useState([]);
  const [oriorderData, setOriOrderData] = useState([...getOrder]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [products_data, setProducts_data] = useState([]);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  //this 0 is never used since we actually store the product id received frm backend in this product state
  //0 is temporary
  const [product, setProduct] = useState(0);
  const [users, setUsers] = useState([]);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    //use default prod as 0 and order type as COD and filter right here
    console.log(product);
    let filteredbyboth = getOrder.filter((row) => {
      return (
        row?.order?.order_type?.toLowerCase() == order_type?.toLowerCase() &&
        row?.product?.id === product
      );
    });
    console.log(filteredbyboth);
    setOrderData([...filteredbyboth]);

    // setOrderData(getOrder)
  }, [getOrder, product, order_type]);

  useEffect(() => {
    // console.log("get orders data");
    // console.log(getOrder);

    apiInstace
      .get(`/products/getproducts/`)
      .then((res) => {
        var products = [];
        // console.log(res.data.results);
        res.data.results.map((item) => [products.push({ id: item.id, name: item.name })]);
        setProducts_data(products);
        console.log(products);
        setProduct(products[0]?.id);
      })
      .catch((err) => console.log(err));

    apiInstace
      .get(`${server}/users/getUsers/`)
      .then((res) => {
        // console.log(res.data.users);
        setUsers([...res.data.results]);
      })
      .catch((err) => console.log(err));
    // console.log(user.data);
    // console.log(users);

    apiInstace
      .get(`${server}/address/getAddress/`)
      .then((res) => {
        // console.log(res.data);
        console.log(res.data.results);
        setAddresses([...res.data.results]);
      })
      .catch((err) => console.log(err));
    // console.log(user.data);
    // console.log(users);
  }, []);
  //  console.log(product);

  // useEffect(() => {
  //   const filteredRows = oriorderData.filter((row) => {
  //     return row?.order?.order_type.toLowerCase() === order_type.toLowerCase();
  //   });
  //   // setRows(filteredRows);
  //   console.log(filteredRows);
  //   setOrderData(filteredRows);
  // }, [order_type]);

  function GetUserDetail({ row, id, index }) {
    // setUser({});
    const user = users?.filter((item) => {
      return item.id == id;
    });
    // console.log(user.data);
    row = { ...row, order: { ...row.order, user: user[0] } };
    // console.log(row);
    orderData[index] = row;
    oriorderData[index] = row;
    // setOrderData([...orderData,[index]:row])
    // console.log(orderData);
    // console.log(user[0]);

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {user[0]?.phone ? (
          <>
            {/* <p>{`${user?.first_name} ${user?.last_name}`}</p>
      <p>{user?.phone}</p> */}
            <p>
              {user[0]?.first_name ? <>{user[0]?.first_name}</> : <>---</>}{" "}
              {user[0]?.last_name ? <>{user[0]?.last_name}</> : <>---</>}
            </p>
            <p>{user[0]?.phone}</p>
          </>
        ) : (
          <>---</>
        )}
      </div>
    );
  }
  const GetAddressdetail = ({ id }) => {
    let address = {};
    // console.log(id);
    address = addresses?.filter((item) => {
      return item.id == id;
    });
    return (
      <>
        {address.length != 0 ? (
          <>
            <p>{address[0]?.address}</p>
            <p>{`${address[0]?.city}, ${address[0]?.state}`}</p>
          </>
        ) : (
          <>---</>
        )}
      </>
    );
  };
  // useEffect(() => {
  //   // console.log(getOrder);
  //   console.log({"productchanged" : getOrder});
  //   let orders = [];
  //   getOrder?.map((item) => {
  //     if (item?.product?.id == product) orders.push(item);
  //   });
  //   console.log(orders);
  //   setOrderData(orders);
  //   setOriOrderData(orders);
  //   setOrder_type("");
  //   // console.log(product);
  // }, [product]);

  // const handleUserInfo = async () => {
  //   const arr = [];
  //   console.log("Hello");
  //   console.log(getOrder);
  //   getOrder?.forEach((order) => {
  //     getUserName(order.id).then((response) => {
  //       // arr.push({ ...order, userDet: response.first_name });
  //       console.log(response.data);
  //     });
  //   });

  //   setOrderData(arr);
  //   // };

  // console.log(getOrder);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.name);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );
  // const [disabled, setDisabled] = useState(true)

  // useEffect(() => {
  // handleUserInfo();
  // }, [getOrder]);
  const tableRef = useRef(null);
  const [Edit, setEdit] = useState({});
  const [trackingID, setTrackingID] = useState({});

  useEffect(() => {
    let edit = {};
    let trackingids = {};
    orderData.map((item, index) => {
      edit = { ...edit, [index]: true };
      trackingids = { ...trackingids, [index]: item.tracking_id };
    });
    setEdit({ ...edit });
    setTrackingID({ ...trackingids });
    console.log(orderData);
    // console.log(edit);
  }, [orderData]);

  const handletrackingid = (key, value) => {
    setTrackingID({ ...trackingID, [key]: value });
  };

  const handleSubmit = (index, id) => {
    // console.log(trackingID[index]);
    const token = `Bearer ${localStorage.getItem("access")}`;

    const order_update = { tracking_id: trackingID[index] };
    apiInstace
      .patch(`${server}/orders/update_order/${id}`, { order_update, token })
      .then((res) => {
        console.log(res);
        toast.success("Tracking ID Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
  };
  const handleEdit = (key, value) => {
    setEdit({ ...Edit, [key]: value });
  };
  const [status, setStatus] = useState({});

  useEffect(() => {
    let status = {};
    orderData.map((item, index) => {
      status = { ...status, [index]: item.order.status };
    });
    // console.log(status);
    setStatus({ ...status });
  }, [orderData]);
  // useEffect(()=>{
  //   console.log(status);
  // },[status])
  const handleStatus = (key, value, id) => {
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);
    const order_update = { status: value };
    console.log(order_update);
    axios
      .patch(`${server}/orders/update_order/${id}/`, { order_update, token })
      .then((res) => {
        // console.log(res);
        toast.success("Status Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
    // navigate("/content")

    setStatus({ ...status, [key]: value });
    functionchange();
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: 4,
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
  };

  const handleDelete = (orderid) => {
    if (orderid >= 0) {
      console.log(orderid);

      const requestOptions = {
        headers: {
          method: "DELETE",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      axios
        .delete(`${server}/orders/deleteOrder/${orderid}/`, requestOptions)
        .then((res) => {
          console.log(res);
          toast.success("Order Deleted! It may take few minutes to reflect the changes", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false)
          handleClose();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(-1);
  const handleOpen = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div className='order-table-main'>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Are you Sure, You want to Delete?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={() => handleDelete(id)}>
                  Delete
                </button>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </Box>
          </Modal>
          <EnhancedTableToolbar
            numSelected={selected.length}
            product={product}
            setProduct={setProduct}
            products_data={products_data}
            tableRef={tableRef}
            orderData={orderData}
            setOrderData={setOrderData}
            oriorderData={getOrder}
            order_type={order_type}
            setOrder_type={setOrder_type}
            users={users}
          />

          <TableContainer>
            {" "}
            <Table
              sx={{ minWidth: 750 }}
              size={dense ? "small" : "medium"}
              exportButton={true}
              ref={tableRef}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? orderData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : orderData
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                      className={"row" + index}>
                      <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        padding='normal'
                        sx={{ minWidth: "10rem" }}>
                        {
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <GetUserDetail id={row.order?.user_id} row={row} index={index} />
                          </div>
                        }
                      </TableCell>
                      <TableCell sx={{ minWidth: "10rem" }}>
                        <GetAddressdetail id={row.order?.address} />
                      </TableCell>
                      <TableCell align='left'>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <p>OrderID : {row.order.id}</p>
                          <p>{row.order.created_at}</p>
                        </div>
                      </TableCell>
                      <TableCell align='left'>{row.quantity}</TableCell>
                      <TableCell align='left'>
                        {row.order.status == "DELIVERED" ? (
                          <>
                            <TextField
                              type='text'
                              value={row.tracking_id}
                              size='small'
                              sx={{ width: "8rem" }}
                              disabled
                            />
                            <Button
                              size='small'
                              variant='contained'
                              style={{ padding: "2px", height: "2rem" }}
                              sx={{ mx: 1 }}
                              disabled>
                              <EditIcon />
                            </Button>
                          </>
                        ) : (
                          <>
                            {
                              // disabled?
                              Edit[index] == true ? (
                                <>
                                  <TextField
                                    type='text'
                                    value={trackingID[index]}
                                    size='small'
                                    sx={{ width: "8rem" }}
                                    disabled
                                  />

                                  <Button
                                    size='small'
                                    variant='contained'
                                    style={{ padding: "2px", height: "2rem" }}
                                    sx={{ mx: 1 }}
                                    onClick={() => handleEdit(index, false)}>
                                    <EditIcon />
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <TextField
                                    type='text'
                                    value={trackingID[index]}
                                    onChange={(e) => handletrackingid(index, e.target.value)}
                                    size='small'
                                    sx={{ width: "8rem" }}
                                  />
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      size='small'
                                      variant='contained'
                                      style={{
                                        padding: "2px",
                                        height: "2rem",
                                      }}
                                      sx={{ mx: 1 }}
                                      onClick={() => {
                                        handleEdit(index, true);
                                      }}>
                                      <CloseIcon />
                                    </Button>

                                    <Button
                                      size='small'
                                      variant='contained'
                                      style={{
                                        padding: "2px",
                                        height: "2rem",
                                      }}
                                      sx={{ mx: 1 }}
                                      onClick={() => {
                                        handleSubmit(index, row.order.id);
                                        handleEdit(index, true);
                                      }}>
                                      <DoneIcon />
                                    </Button>
                                  </div>
                                </>
                              )
                            }
                          </>
                        )}
                      </TableCell>
                      <TableCell align='left'>
                        {row.total_price?.toFixed(2) || row.total_price}
                      </TableCell>
                      <TableCell align='left'>
                        <div className='table-action-select'>{status[index]}</div>
                      </TableCell>
                      <TableCell>{row.order.status}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Table
              sx={{ minWidth: 750, display: "none" }}
              // aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              exportButton={true}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component='div'
            count={orderData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    </div>
  );
};

export default OrderTable;
