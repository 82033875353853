export const multiSelect = {
  searchBox: {
    // border: "none",
    "border-radius": "8px",
    maxWidth: "200px",
    overflow: "scroll",
    display: "flex",
    flexDirection: "row-reverse",
    margin: "4px",
    "padding-right": "20px",
  },
  searchWrapper: {
    // border: "none",
    "border-radius": "8px",
  },
  inputField: {
    padding: "5px 0px 5px 15px",
    width: "150px",
    fontSize: "16px",
  },
  //   chips: {
  //     "margin-right": 12,
  //   },
};
