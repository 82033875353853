import React from "react";
import Products from "../../../assets/Folder.svg";

const Allproducts = ({ products }) => {
  let active = 0;
  // console.log(products);
  products?.map((item) => {
    // console.log(item.status)
    if (item.status == "PUBLISH") ++active;
  });
  return (
    <div className='abandoned-main' style={{ backgroundColor: "#5570f1", width: "100%" }}>
      <div className='abandoned-div'>
        <img alt='cart-img' src={Products} className='cart-image' style={{ color: "white" }} />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className='abandoned-body'>
        <div className='abandoned-body-item'>
          <p style={{ color: "white" }} className='abandoned-body-title'>
            Products
          </p>
          <p style={{ color: "white" }} className='cart-percentage'>
            {products?.length}
          </p>
        </div>
        <div className='abandoned-body-item'>
          <p style={{ color: "white" }} className='abandoned-body-title'>
            Active
          </p>
          <p style={{ color: "white" }} className='cart-percentage'>
            {active}
            {/* <span>+0.00%</span> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Allproducts;
