import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { Button, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import ProdcutImg from "../../../assets/productImg.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import server from "../../server";
import { ArrowBackOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DashboardContext from "../../Context/DashboardContext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TablePagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { useTheme } from '@mui/material/styles';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function createData(
//   orderDate,
//   OrderType,
//   TrackingID,
//   totalValue,
//   action,
//   status
// ) {
//   return {
//     orderDate,
//     OrderType,
//     TrackingID,
//     totalValue,
//     action,
//     status,
//   };
// }

// const rows = [
//   createData(
//     "12 Aug 2022 - 12:25 am",
//     "Self-Delivery",
//     "9348fjr73",
//     "$3000",
//     "completed",
//     "completed"
//   ),
//   createData(
//     "12 Aug 2022 - 12:25 am",
//     "KamaoKisan",
//     "",
//     "$3000",
//     "pending",
//     "pending"
//   ),
//   createData(
//     "12 Aug 2022 - 12:25 am",
//     "KamaoKisan",
//     "9348fjr73",
//     "$3000",
//     "inprogress",
//     "inprogress"
//   ),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const names = ["DELIVERED", "PENDING", "IN-PROGRESS"];
const headCells = [
  {
    id: "OrderDate",
    numeric: false,
    disablePadding: true,
    label: "Order Date",
  },
  {
    id: "OrderType",
    numeric: true,
    disablePadding: false,
    label: "Order Type",
  },

  {
    id: "TrackingID",
    numeric: true,
    disablePadding: false,
    label: "Tracking ID",
  },
  {
    id: "totalValue",
    numeric: true,
    disablePadding: false,
    label: "Total Order",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  //   {
  //     id: "edit",
  //     numeric: true,
  //     disablePadding: false,
  //     label: " ",
  //   },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
function MultipleSelect() {
  return <div></div>;
}
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  // const handlesearch=async (e)=>{
  //   // const search_data=[];
  //   if(e.target.value!="")
  //   {
  //   console.log(e.target.value);
  //   console.log(props.oriorders);
  //   const filteredRows = await props.oriorders.filter((row) => {

  //     return row.user.first_name?.includes(e.target.value);
  //   });
  //   // setRows(filteredRows);
  //   // console.log(filteredRows);
  //   props.setOrders(filteredRows)

  //   }
  //   else  props.setOrderData(props.oriorders)

  // }
  function getStyles(name, status_info, theme) {
    return {
      fontWeight:
        status_info.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const [status_info, setStatus_info] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatus_info(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    console.log(status_info);
    console.log(props.oriorders);
    if (status_info.length != 0) {
      // console.log(e.target.value);
      // console.log(props.oriorders);
      const filteredRows = props.oriorders.filter((row) => {
        return status_info?.includes(row.order.status);
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setOrders(filteredRows);
    } else props.setOrders(props.oriorders);
  }, [status_info]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
            Janet’s Orders
          </Typography>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id='demo-multiple-name-label'>Status</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple
                  value={status_info}
                  onChange={handleChange}
                  input={<OutlinedInput label='Name' />}
                  MenuProps={MenuProps}>
                  {names.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, status_info, theme)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ display: "flex" }}>
                <div className='table-head-buttons'>
                  <img alt='img' src={Vector} />
                  <p className='filter-text'>Filter</p>
                </div>
                <div className='table-head-buttons'>
                  <img alt='img' src={Calender} />
                  <p className='filter-text'>Filter</p>
                </div>
                <div className='table-head-buttons'>
                  <img alt='img' src={Send} />
                  <p className='filter-text'>Share</p>
                </div>
                {/* <select className='table-head-buttons'>
                  <option>Bulk Action</option>
                </select> */}
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CustomerViewTable = ({ orders, oriorders, setOrders }) => {
  console.log(orders);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = orders.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(orders, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  // React.useEffect(() => {
  //   getAllUsers();
  // }, []);

  const [Edit, setEdit] = useState({});
  const [trackingID, setTrackingID] = useState({});

  useEffect(() => {
    let edit = {};
    let trackingids = {};
    orders.map((item, index) => {
      edit = { ...edit, [index]: true };
      trackingids = { ...trackingids, [index]: item.tracking_id };
    });
    setEdit({ ...edit });
    setTrackingID({ ...trackingids });
    // console.log(trackingids);
    // console.log(edit);
  }, [orders]);

  const handletrackingid = (key, value) => {
    setTrackingID({ ...trackingID, [key]: value });
  };

  const handleSubmit = (index) => {
    console.log(trackingID?.index);
  };
  const handleEdit = (key, value) => {
    setEdit({ ...Edit, [key]: value });
  };

  return (
    <div className='order-table-main'>
      <Box sx={{ width: "100%", borderRadius: "10px" }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "10px" }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            orders={orders}
            setOrders={setOrders}
            oriorders={oriorders}
          />
          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={orders.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? orders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : orders
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      style={{ border: "1px solid transparent" }}
                      className={"rows" + index}>
                      <TableCell align='left' style={{ border: "1px solid transparent" }}>
                        {row.created_at}
                      </TableCell>
                      <TableCell align='left' style={{ border: "1px solid transparent" }}>
                        <Link to='/viewproduct' style={{ textDecoration: "none" }}>
                          {row.order.order_type}
                        </Link>
                      </TableCell>

                      <TableCell
                        align='left'
                        style={{
                          border: "1px solid transparent",
                          display: "flex",
                          alignItems: "center",
                        }}>
                        {
                          //  const [disabled1, setDisabled1] = useState(true);
                          row.order.status == "DELIVERED" ? (
                            <>
                              <TextField
                                type='text'
                                value={row.tracking_id}
                                size='small'
                                sx={{ width: "8rem" }}
                                disabled
                              />
                              <Button
                                size='small'
                                variant='contained'
                                style={{
                                  padding: "2px",
                                  height: "2rem",
                                }}
                                sx={{ mx: 1 }}
                                disabled>
                                <EditIcon />
                              </Button>
                            </>
                          ) : (
                            <>
                              {
                                // disabled?
                                Edit[index] == true ? (
                                  <>
                                    <TextField
                                      type='text'
                                      value={trackingID[index]}
                                      size='small'
                                      sx={{ width: "8rem" }}
                                      disabled
                                    />

                                    <Button
                                      size='small'
                                      variant='contained'
                                      style={{
                                        padding: "2px",
                                        height: "2rem",
                                      }}
                                      sx={{ mx: 1 }}
                                      onClick={() => handleEdit(index, false)}>
                                      <EditIcon />
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <TextField
                                      type='text'
                                      value={trackingID[index]}
                                      onChange={(e) => handletrackingid(index, e.target.value)}
                                      size='small'
                                      sx={{ width: "8rem" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                      }}>
                                      <Button
                                        size='small'
                                        variant='contained'
                                        style={{
                                          padding: "2px",
                                          height: "2rem",
                                        }}
                                        sx={{ mx: 1 }}
                                        onClick={() => {
                                          handleEdit(index, true);
                                        }}>
                                        <CloseIcon />
                                      </Button>

                                      <Button
                                        size='small'
                                        variant='contained'
                                        style={{
                                          padding: "2px",
                                          height: "2rem",
                                        }}
                                        sx={{ mx: 1 }}
                                        onClick={() => {
                                          handleSubmit(index);
                                          handleEdit(index, true);
                                        }}>
                                        <DoneIcon />
                                      </Button>
                                    </div>
                                  </>
                                )
                              }
                            </>
                          )
                        }
                      </TableCell>

                      <TableCell align='left' style={{ border: "1px solid transparent" }}>
                        {row.order.total_cost}
                      </TableCell>
                      <TableCell align='left' style={{ border: "1px solid transparent" }}>
                        <select className='table-action-select' value={row.order.status}>
                          <option>Delivered</option>
                          <option>In-Progress</option>
                          <option>Pending</option>
                        </select>
                      </TableCell>
                      <TableCell style={{ border: "1px solid transparent" }}>
                        {row.order.status === "DELIVERED" ? (
                          <p className='status-complete'>Delivered</p>
                        ) : row.order.status === "IN_PROGESS" ? (
                          <p className='status-progress'>In-Progress</p>
                        ) : (
                          <p className='status-pending'>Pending</p>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component='div'
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default CustomerViewTable;
