import React, { useContext, useEffect } from "react";
import { useState } from "react";
import DashboardContext from "./DashboardContext";
// import axios from "axios";
import { apiInstace } from "../server";
// import AWS from "aws-sdk";
import { UserContext } from "../../App";

const DashboardState = (props) => {
  const [loading, setLoading] = useState(false);
  const [crops, setCrops] = useState();
  const [brands, setBrands] = useState();
  const [customers, setCustomers] = useState();
  const [orders, setOrders] = useState();
  const [products, setProducts] = useState();
  const [posts, setPosts] = useState();

  const [equipment, setEquipment] = useState();
  const [cropsSelected, setCropsSelected] = useState();
  const [brandsSelected, setBrandsSelected] = useState();
  const [equipmentsSelected, setEquipmentsSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [tags, setTags] = useState();
  const [tagsSelected, setTagsSelected] = useState();

  const handleCropsEdit = (crops, newProduct, setNewProduct) => {
    let cropArr = [...newProduct.crops];
    const newArr = [];
    cropArr.forEach((item) => {
      let info = crops.filter((crop) => crop.id === item);
      newArr.push(info[0]);
    });

    setCropsSelected(newArr);
  };

  const handleBrandsEdit = (brands, newProduct, setNewProduct) => {
    let brandArr = [...newProduct.brands];
    const newArr = [];
    brandArr.forEach((item) => {
      let info = brands.filter((brand) => brand.id === item);
      newArr.push(info[0]);
    });

    setBrandsSelected(newArr);
  };

  const handleTagsEdit = (tags, newProduct, setNewProduct) => {
    let tagArr = [...newProduct.tags];
    const newArr = [];
    tagArr.forEach((item) => {
      let info = tags.filter((tag) => tag.id === item);
      newArr.push(info[0]);
    });

    setTagsSelected(newArr);
  };

  const handleEquipmentsEdit = (equipment, newProduct, setNewProduct) => {
    let equipmentsArr = [...newProduct.farm_equipments];
    const newArr = [];
    equipmentsArr.forEach((item) => {
      let info = equipment.filter((crop) => crop.id === item);
      newArr.push(info[0]);
    });

    setEquipmentsSelected(newArr);
  };

  const getEquipment = (isEdit, newProduct, setNewProduct) => {
    apiInstace
      .get(`/equipment/getequipment`, requestOptions)
      .then((result) => {
        if (isEdit) {
          handleEquipmentsEdit(result.data.results, newProduct, setNewProduct);
        }
        setEquipment(result.data.results);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getBrands = (isEdit, newProduct, setNewProduct) => {
    apiInstace
      .get(`/brands/getBrands`, requestOptions)
      .then((result) => {
        if (isEdit) {
          handleBrandsEdit(result.data.results, newProduct, setNewProduct);
        }

        setBrands(result.data.results);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getTags = (isEdit, newProduct, setNewProduct) => {
    apiInstace
      .get(`/tags/getTags`, requestOptions)
      .then((result) => {
        if (isEdit) {
          handleTagsEdit(result.data.results, newProduct, setNewProduct);
        }

        setTags(result.data.results);
      })
      .catch((err) => {
        console.log(`/tags/getTags`, err.message);
      });
  };

  const getCrops = (isEdit, newProduct, setNewProduct) => {
    apiInstace
      .get(`/crops/getcrops`, requestOptions)
      .then((res) => {
        // console.log(res.data);
        if (isEdit) {
          handleCropsEdit(res.data.results, newProduct, setNewProduct);
        }
        setCrops(res.data.results);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const getCustomers = async (params) => {
    try {
      const response = await apiInstace.get("/users/getUsers", { ...requestOptions, params });
      setCustomers(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getOrders = async (params) => {
    try {
      const response = await apiInstace.get(`/orders/getOrders`, {
        ...requestOptions,
        params,
      });
      if ("size" in params) setOrders(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getProducts = () => {
    apiInstace
      .get(`/products/getproducts`, requestOptions)
      .then((res) => {
        // console.log(res.data.results);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setProducts(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPosts = () => {
    apiInstace
      .get(`/posts/getPosts`, requestOptions)
      .then((res) => {
        // console.log(res.data);
        // if (isEdit) {
        //   handleCropsEdit(res.data.results, newProduct, setNewProduct);
        // }
        setPosts(res.data.posts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const [s3, setS3] = useState();
  const [languages, setLanguages] = useState(
    JSON.parse(localStorage.getItem("languages")) ?? false
  );

  const fetchandsetlanguages = () => {
    apiInstace
      .get(`/translation/languages`, requestOptions)
      .then((res) => {
        setLanguages(res.data.results);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const [networkMsg, setNetworkMsg] = useState();

  const fetchandsetNetworkMsg = () => {
    apiInstace
      .get(`/translation/network_message/${localStorage.getItem("id")}`, requestOptions)
      .then((res) => {
        setNetworkMsg(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // let tempData = [];
    // languages.forEach((element) => {
    //   tempData.push({
    //     [element.language]: element.id,
    //   });
    // });
    // console.log(!localStorage.getItem("languages"), languages);
    !localStorage.getItem("languages") &&
      languages &&
      localStorage.setItem("languages", JSON.stringify(languages));
  }, [languages]);

  const context = useContext(UserContext);
  useEffect(() => {
    // apiInstace
    //   .get(`auth/config`)
    //   .then((res) => {
    //     AWS.config.update(res.data);
    //     setS3(new AWS.S3());
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   });

    !languages && fetchandsetlanguages();
    fetchandsetNetworkMsg();
  }, [context.user]);

  useEffect(() => {
    // console.log("s3", s3);
    // s3 &&
    //   s3.listBuckets((err, data) => {
    //     if (err) {
    //       console.log("Error listing S3 buckets:", err.message);
    //     } else {
    //       console.log("AWS S3 is configured.");
    //     }
    //   });
  }, [s3]);

  return (
    <DashboardContext.Provider
      value={{
        setLoading,
        loading,
        getBrands,
        brands,
        tags,
        setTags,
        getTags,
        getCrops,
        tagsSelected,
        crops,
        getEquipment,
        equipment,
        handleCropsEdit,
        cropsSelected,
        brandsSelected,
        equipmentsSelected,
        refresh,
        setRefresh,
        customers,
        getCustomers,
        orders,
        getOrders,
        getProducts,
        products,
        posts,
        getPosts,
        s3,
        languages,
        networkMsg,
        setNetworkMsg,
        fetchandsetNetworkMsg,
      }}>
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardState;
