import React,{useState,useEffect} from "react";
import CustomerDetail from "./Layout/CustomerDetail";
import CustomerAddress from "./Layout/CustomerAddress";
import TotalOrder from "../Inventory/Layout/TotalOrder";
import AllOrders from "../Dashboard/Layout/AllOrders";
import Canceled from "../Orders/Layout/Canceled";
import Abandoned from "../Dashboard/Layout/Abandoned";
import CustomerViewTable from "./Layout/CustomerViewTable";
import { useLocation } from "react-router-dom";
import {apiInstace} from "../server";
import axios from "axios";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CustomerOrder = () => {
  const location=useLocation();
  const data=location.state?.data
  console.log(data);
  const navigate=useNavigate();
  const [orders, setOrders] = useState([])
  const [oriorders, setOriOrders] = useState([])
  console.log(data.id);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  useEffect(()=>{
   let order_data=[]    
   apiInstace.get(`/orders/getorders`,requestOptions)
   .then((res)=>{
    console.log(res.data.results);
    // console.log(data.id);
    res.data.results.map((item)=>
     {  if (item.order.user_id==data.id)
      order_data.push(item);
     }
    )
    console.log(order_data);
    setOrders(order_data);
    setOriOrders(order_data)
   })
  },[])
  return (
    <div>
      <ArrowBackOutlined onClick={()=>navigate("/customers")} />
      <div className="customer-order-main-div">
        <div className="customer-detail-card">
          <CustomerDetail data={data} />
        </div>
        <div className="customer-address-card">
          <CustomerAddress data={data}/>
        </div>
        <div className="cutomer-order-card">
          <TotalOrder orders={orders} />
        </div>
      </div>
      <div className="customer-order-main-div">
        <div className="customer-detail-card">
          <AllOrders orders={orders} />
        </div>
        {/* <div className="customer-address-card">
          <Canceled />
        </div>
        <div className="cutomer-order-card">
          <Abandoned />
        </div> */}
      </div>
      <CustomerViewTable orders={orders} oriorders={oriorders} setOrders={setOrders} id={data.id}/>
    </div>
  );
};

export default CustomerOrder;
