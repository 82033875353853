import { apiInstace } from "../../server"


const getOrderDetails = async (id) => {
    try {
        const data = await apiInstace.get(`/orders/order_profits/${id}`)
      //  console.log("Order", data.data);
        return (data.data)

    } catch (error) {
        console.log(error)
    } finally {

    }
}

export default getOrderDetails