// src/locationService.js
import { apiInstace } from "../Components/server";

// Mimic the structure of country-state-city
const State = {
  getStatesOfCountry: async (countryCode) => {
    try {
      const response = await apiInstace.get(`api/states/${countryCode}`);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching states:", error);
      return [];
    }
  },
};

const City = {
  getCitiesOfState: async (countryCode, stateCode) => {
    if (!stateCode) return [];
    try {
      const response = await apiInstace.get(`api/cities/${countryCode}/${stateCode}`);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching cities:", error);
      return [];
    }
  },
};

export { State, City };
