import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Input, Tab, Tabs, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import Filter from "../../../assets/sort.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import server from "../../server";
import DashboardContext from "../../Context/DashboardContext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import { apiInstace } from "../../server";
import getOrderDetails from "../Utils/getOrderDetails";
import getUserDetails from "../Utils/getUserDetails";
import NetworkRow from "./NetworkRow";
import UsersRow from "./UsersRow";
import { DownloadTableExcel } from "react-export-table-to-excel";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Order No.",
  },
  {
    id: "order_date",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
  },
  {
    id: "User ID",
    numeric: true,
    disablePadding: false,
    label: "User Name",
  },
  {
    id: "order_total",
    numeric: true,
    disablePadding: false,
    label: "Order Total",
  },
  {
    id: "profits_made",
    numeric: true,
    disablePadding: false,
    label: "Profits Made",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
];

const UserheadCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "number",
    numeric: true,
    disablePadding: false,
    label: "Number",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "relation",
    numeric: true,
    disablePadding: false,
    label: "Relation",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "joining_date",
    numeric: false,
    disablePadding: false,
    label: "Joining Date",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headRow } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headRow?.length > 0 &&
          headRow.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    setTabValue,
    tabValue,
    userProfit,
    setUserProfit,
    allUsers,
    setFilteredUsers,
  } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    console.log(e.target.value);
    if (!e.target.value) setFilteredUsers(allUsers);
    if (e.target.value != "") {
      const filteredRows = await allUsers.filter((row) => {
        return row?.first_name?.toLowerCase()?.includes(e.target.value.toLowerCase());
      });
      // console.log(filteredRows);
      setFilteredUsers(filteredRows);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired,
  // };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const tableName = `${props.user?.first_name}'s Networks`;

  const basicFlex = { display: "flex", justifyContent: "space-between", alignItems: "center" };

  const [refToUse, setRefToUse] = useState();

  useEffect(() => {
    setRefToUse(tabValue == 0 ? props.tableRef_0.current : props.tableRef_1.current);
  }, [tabValue]);

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
          ...basicFlex,
        }}>
        <div>
          <Typography
            sx={{ flex: "1 1 100%", textTransform: "capitalize" }}
            variant='h6'
            id='tableTitle'
            component='div'>
            {tableName}
            <span style={{ color: "#90EE90" }}>{`(${props.user?.profits_made ?? "0"} INR)`}</span>
          </Typography>
        </div>

        <div style={{ ...basicFlex, gap: 16 }}>
          {tabValue == 0 ? (
            <DownloadTableExcel
              filename={tableName + ` ${tabValue == 0 ? "Orders" : "Users"}`}
              sheet={tableName}
              currentTableRef={refToUse}>
              <button className='table-head-buttons'> Export excel </button>
            </DownloadTableExcel>
          ) : (
            <DownloadTableExcel
              filename={tableName + ` ${tabValue == 0 ? "Orders" : "Users"}`}
              sheet={tableName}
              currentTableRef={refToUse}>
              <button className='table-head-buttons'> Export excel </button>
            </DownloadTableExcel>
          )}
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : tabValue ? (
            <Tooltip>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  style={{
                    width: "240px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  placeholder='Search'
                  onChange={handlesearch}
                />
                {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      </Toolbar>
      <div>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label='basic tabs example'
            TabIndicatorProps={{
              sx: { backgroundColor: "#5570f1", height: "2px" },
            }}
            sx={{
              "& button": {
                padding: "12px 16px 0px 16px",
                color: "#000",
                fontWeight: "400",
              },
              "& button.Mui-selected": {
                color: "#5570f1",
                fontWeight: "500",
              },
            }}
            variant='scrollable'
            scrollButtons='auto'>
            <Tab label='Orders' {...a11yProps(0)} />
            <Tab label='Users' {...a11yProps(1)} />
          </Tabs>
        </Box>
      </div>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function UserNetworkTable() {
  const location = useLocation();
  const { user } = location.state;
  // console.log("user", user);
  const [formattedOrder, setFormattedOrder] = useState();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = React.useState([]);
  const [oriusers, setOriUsers] = React.useState([]);
  const [orders, setOrders] = React.useState();
  const [userProfit, setUserProfit] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const navigate = useNavigate();
  const [reload, setReload] = React.useState(false);
  const [userArr, setUserArr] = React.useState();
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [tabValue, setTabValue] = React.useState(0);
  const [count, setCount] = useState();
  const [userProfitCount, setUserProfitCoount] = useState(0);

  const getUserNetwork = async () => {
    setLoading(true);
    if (page == 0) {
      const data = await apiInstace.get(`/orders/user_profits`, {
        params: { user: user?.id, page: page + 1, size: rowsPerPage },
      });
      setUserProfit(data?.data?.results);
      setUserProfitCoount(data?.data?.count);
    }
    const users = await apiInstace.get(`/users/getUsers`, {
      params: { user_id: user.id, page: page + 1, size: rowsPerPage },
    });
    setCount(users.data.count);
    const filteredData = users.data.results
      .filter((item) => {
        if (
          item.bdo === user.id ||
          item.parent === user.id ||
          item.grand_parent === user.id ||
          item.dealer === user.id
        )
          return item;
      })
      .map((item) => {
        let relation = [];
        if (item.bdo === user.id) relation.push("bdo");
        if (item.parent === user.id) relation.push("parent");
        if (item.grand_parent === user.id) relation.push("grand parent");
        if (item.dealer === user.id) relation.push("dealer");
        return { ...item, relation };
      });
    // console.log("filtered", filteredData)
    setFilteredUsers(filteredData);
    setAllUsers(filteredData);
    setLoading(false);
  };

  const getUserProfits = async () => {
    setLoading(true);
    const data = await apiInstace.get(`/orders/user_profits`, {
      params: { user: user?.id || undefined, page: page + 1, size: rowsPerPage || undefined },
    });

    setUserProfit(data?.data?.results);
    setUserProfitCoount(data?.data?.count);
    setLoading(false);
  };
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (tabValue == 1 || !init) {
      getUserNetwork();
      setInit(true);
    } else getUserProfits();
  }, [page, tabValue]);
  //  console.log("User Profit", userProfit);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableRef_0 = useRef(null);
  const tableRef_1 = useRef(null);

  // useEffect(() => {
  //   console.log(userProfit);
  // }, [userProfit]);

  return (
    <div>
      <button className='back-button' onClick={() => navigate(-1)}>
        BACK
      </button>
      <div className='order-table-main'>
        <Box sx={{ width: "100%", borderRadius: 10 }}>
          <Paper sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              row={users}
              setFilteredUsers={setFilteredUsers}
              allUsers={allUsers}
              userProfit={userProfit}
              setUserProfit={setUserProfit}
              user={user}
              tabValue={tabValue}
              setTabValue={setTabValue}
              tableRef_0={tableRef_0}
              tableRef_1={tableRef_1}
            />
            <TableContainer style={{ padding: "0px 20px" }}>
              {tabValue == 0 ? (
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby='tableTitle'
                  size={dense ? "small" : "medium"}
                  ref={tableRef_0}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    tabValue={tabValue}
                    headRow={headCells}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={10}
                  />
                  <TableBody>
                    {userProfit
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => {
                        return (
                          <NetworkRow profitData={item} index={index} key={item.order_profit} />
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby='tableTitle'
                  size={dense ? "small" : "medium"}
                  ref={tableRef_1}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    headRow={UserheadCells}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={10}
                  />
                  <TableBody>
                    {filteredUsers.map((item, index) => {
                      return <UsersRow userData={item} index={index} key={index} />;
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25, 100]}
              component='div'
              count={tabValue == 0 ? userProfitCount : count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default UserNetworkTable;
