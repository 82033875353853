import React, { useContext, useEffect, useState } from "react";
import BagBlack from "../../../assets/Bag-black.svg";
import DashboardContext from "../../Context/DashboardContext";

const AllOrders = (props) => {
  // const [active, setActive] = useState(0)
  // const [numpending, setpending] = useState(0);
  // const [numdelivered, setdelivered] = useState(0);
  const context = useContext(DashboardContext);
  const { getOrders } = context;

  const [ordersCount, setOrderCount] = useState({
    pending: 0,
    IN_PROGESS: 0,
    complete: 0,
    reject: 0,
    all: props?.orders?.count,
  });

  useEffect(() => {
    const fetchOrdersCounts = async () => {
      const pending = await getOrders({ order_status: "PENDING" });
      const inProgress = await getOrders({ order_status: "IN_PROGESS" });
      const delivered = await getOrders({ order_status: "DELIVERED" });
      const rejected = await getOrders({ order_status: "REJECTED" });

      console.log({
        pending: pending?.count,
        IN_PROGESS: inProgress.count,
        complete: delivered.count,
        reject: rejected.count,
        all: pending.count + inProgress.count + delivered.count + rejected.count,
      });

      setOrderCount({
        pending: pending?.count,
        IN_PROGESS: inProgress.count,
        complete: delivered.count,
        reject: rejected.count,
        all: pending.count + inProgress.count + delivered.count + rejected.count,
      });
    };

    fetchOrdersCounts();
  }, []);

  // setActive(c);
  return (
    <div className='abandoned-main'>
      <div className='abandoned-div'>
        <img alt='cart-img' src={BagBlack} className='cart-image' />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className='abandoned-body'>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>All Orders</p>
          <p className='cart-percentage'>{ordersCount.all}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Pending</p>
          <p className='cart-percentage'>{ordersCount.pending}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>In Progress</p>
          <p className='cart-percentage'>{ordersCount.IN_PROGESS}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Delivered</p>
          <p className='cart-percentage'>
            {ordersCount.complete}
            {/* <span>+0.00%</span> */}
          </p>
        </div>
        {/* <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Rejected</p>
          <p className='cart-percentage'>
            {ordersCount.reject}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default AllOrders;
