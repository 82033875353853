import React, { useContext, useEffect } from "react";
// import Modal from "react-modal";
import { Modal } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { apiInstace } from "../../server";
import server from "../../server";
import { toast } from "react-toastify";
// import AWS from "aws-sdk";
import DashboardContext from "../../Context/DashboardContext";
import { Box } from "@mui/material";
import { logDOM } from "@testing-library/react";
import handleImageUpload from "../Utils/HandleImageUpload";
function AddModal({ modalIsOpen, closeModal, modalFor }) {
  const context = useContext(DashboardContext);
  const { getCrops, getBrands, getEquipment, crops, s3 } = context;
  const [cropData, setCropData] = useState({
    image: "",
    icon: "",
    name: "",
    description: "",
    requirements: "FERTILIZER",
  });
  const [brandData, setBrandData] = useState({
    image: "",
    name: "",
  });
  const [equipmentData, setEquipmentData] = useState({
    name: "",
    image: "",
    icon: "",
    crop_id: "",
  });

  //   AWS.config.update({
  //     accessKeyId: "AKIAROQUFKO77IGQYJEL",
  //     secretAccessKey: "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",
  //     region: "ap-south-1",
  //   });

  //   const s3 = new AWS.S3();

  const handleInput = (field, value) => {
    if (modalFor === "Crops") {
      setCropData({
        ...cropData,
        [field]: value,
      });
    }
    if (modalFor === "Brands") {
      setBrandData({
        ...brandData,
        [field]: value,
      });
    }
    if (modalFor === "Equipment") {
      setEquipmentData({
        ...equipmentData,
        [field]: value,
      });
    }
  };

  const uploadImage = async (file) => {
    const fileName = file.name;
    console.log(file.name);
    // Configure the S3 upload parameters
    const params = {
      Bucket: "farmore-assets",
      Key: `${Date.now()}.${fileName}`,
      ContentType: file.type,
      Body: file,
    };

    try {
      // Upload the image to S3
      const data = await s3.upload(params).promise();
      const imageUrl = data.Location;
      console.log(imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const onSubmitBrand = async () => {
    // let imageUrl = await uploadImage(brandData.image);
    const imageUrl = await handleImageUpload({
      images: [brandData.image],
      setImages: (imgs) => console.log("imgs", imgs),
    });
    const brand_data = { ...brandData, image: imageUrl };
    apiInstace
      .post(`/brands/postBrands`, {
        brand_data,
      })
      .then(() => {
        toast.success("Brand Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getBrands();
        closeModal();
      });
  };

  const onSubmit = async () => {
    // let imageUrl = await uploadImage(cropData.image);
    // let iconUrl = await uploadImage(cropData.icon);
    const imageUrl = await handleImageUpload({
      images: [cropData.image],
      setImages: (imgs) => console.log("imgs", imgs),
    });
    const iconUrl = await handleImageUpload({
      images: [cropData.icon],
      setImages: (imgs) => console.log("imgs", imgs),
    });
    setCropData({ ...cropData, image: imageUrl, icon: iconUrl });
    apiInstace
      .post(`/crops/postcrops`, {
        ...cropData,
        image: imageUrl,
        icon: iconUrl,
      })
      .then(() => {
        toast.success("Crop Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getCrops();
        closeModal();
      })
      .catch(() => {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    getCrops();
  }, []);

  const onSubmitEquipment = async () => {
    // let imageUrl = await uploadImage(equipmentData.image);
    // let iconUrl = await uploadImage(equipmentData.icon);
    const imageUrl = await handleImageUpload({
      images: [equipmentData.image],
      setImages: (imgs) => console.log("imgs", imgs),
    });
    const iconUrl = await handleImageUpload({
      images: [equipmentData.icon],
      setImages: (imgs) => console.log("imgs", imgs),
    });

    apiInstace
      .post(`/equipment/postequipment`, {
        ...equipmentData,
        image: imageUrl,
        icon: iconUrl,
      })
      .then(() => {
        toast.success("Farm Equipment Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getEquipment();
        closeModal();
      })
      .catch(() => {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <div>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <h2>Add {modalFor}</h2>

          <form
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}>
            {modalFor === "Crops" && (
              <>
                <div className='input-field'>
                  <label htmlFor='crop-name'>Crop Name</label>
                  <input
                    onChange={(e) => {
                      handleInput("name", e.target.value);
                    }}
                    className='modal-input'
                    type='text'
                    name=''
                    id='crop-name'
                    placeholder='Add Crop'
                  />
                </div>
                <div className='input-field'>
                  <label htmlFor='crop-name'>Crop Description</label>
                  <textarea
                    name=''
                    id='crop-description'
                    cols='30'
                    rows='10'
                    onChange={(e) => handleInput("description", e.target.value)}
                  />
                </div>

                <div className='upload-group'>
                  <div>
                    <label htmlFor='icon-upload' id='icon-name' className='upload-btn'>
                      Icon Upload
                    </label>
                    <input
                      type='file'
                      name=''
                      id='icon-upload'
                      onChange={(e) => {
                        const displayName = document.getElementById("icon-name");
                        displayName.innerText = e.target.files[0].name;

                        setCropData({
                          ...cropData,
                          icon: e.target.files[0],
                        });
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div>
                    <label htmlFor='image-upload-crop' id='crop-image-name' className='upload-btn'>
                      Image Upload
                    </label>
                    <input
                      type='file'
                      name=''
                      id='image-upload-crop'
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const displayName = document.getElementById("crop-image-name");
                        displayName.innerText = e.target.files[0].name;

                        setCropData({
                          ...cropData,
                          image: e.target.files[0],
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='input-field'></div>
              </>
            )}
            {modalFor === "Brands" && (
              <>
                <div className='input-field'>
                  <label htmlFor='brand-name'>Brand Name</label>
                  <input
                    onChange={(e) => {
                      handleInput("name", e.target.value);
                    }}
                    className='modal-input'
                    type='text'
                    name=''
                    id='crop-name'
                    placeholder='Add Brand'
                  />
                </div>

                <div className='upload-group'>
                  <div>
                    <label
                      htmlFor='image-upload-brand'
                      id='brand-image-name'
                      className='upload-btn'>
                      Image Upload
                    </label>
                    <input
                      type='file'
                      name=''
                      id='image-upload-brand'
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const displayName = document.getElementById("brand-image-name");
                        displayName.innerText = e.target.files[0].name;

                        setBrandData({
                          ...brandData,
                          image: e.target.files[0],
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {modalFor === "Equipment" && (
              <>
                <div className='input-field'>
                  <label htmlFor='brand-name'>Name</label>
                  <input
                    onChange={(e) => {
                      handleInput("name", e.target.value);
                    }}
                    className='modal-input'
                    type='text'
                    name=''
                    id='crop-name'
                    placeholder='Add Equpment Name'
                  />
                </div>
                <div className='input-field'>
                  <label htmlFor='crop-dropdown' className='for-crop'>
                    For Crop
                  </label>
                  <select
                    className='for-crop-dropdown'
                    name=''
                    id='crop-dropdown'
                    onChange={(e) => {
                      handleInput("crop_id", e.target.value);
                    }}>
                    <option value='' selected disabled>
                      Select Crops
                    </option>
                    {crops?.map((item, index) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
                <div className='upload-group'>
                  <div>
                    <label htmlFor='equipment-upload' id='equipment-name' className='upload-btn'>
                      Icon Upload
                    </label>
                    <input
                      type='file'
                      name=''
                      id='equipment-upload'
                      onChange={(e) => {
                        const displayName = document.getElementById("equipment-name");
                        displayName.innerText = e.target.files[0].name;

                        setEquipmentData({
                          ...equipmentData,
                          icon: e.target.files[0],
                        });
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='image-upload-equipment'
                      id='equipment-image-name'
                      className='upload-btn'>
                      Image Upload
                    </label>
                    <input
                      type='file'
                      name=''
                      id='image-upload-equipment'
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const displayName = document.getElementById("equipment-image-name");
                        displayName.innerText = e.target.files[0].name;

                        setEquipmentData({
                          ...equipmentData,
                          image: e.target.files[0],
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className='button-group'>
              <button
                className='modal-btn'
                onClick={(e) => {
                  e.preventDefault();
                  if (modalFor === "Crops") {
                    onSubmit();
                  }
                  if (modalFor === "Brands") {
                    onSubmitBrand();
                  }
                  if (modalFor === "Equipment") {
                    onSubmitEquipment();
                  }
                }}>
                Add
              </button>
              <button className='modal-btn' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default AddModal;
