import React, { useContext, useEffect, useRef, useState } from "react";
// import Plus from "../../assets/plus.svg";
// import AllOrders from "../Dashboard/Layout/AllOrders";
// import Abandoned from "../Dashboard/Layout/Abandoned";
// import Canceled from "./Layout/Canceled";
import OrderTable from "./Layout/OrderTable";
// import axios from "axios";
import { apiInstace } from "../server";
import DashboardContext from "../Context/DashboardContext";
// import * as React from 'react';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// export default function BasicTabs() {

//   return (

//   );
// }
const All_Orders = () => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const [fetchedOrders, setFetchedOrders] = useState([]);
  // const [origetOrder, setOriGetOrder] = useState([]);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [value, setValue] = React.useState(0);
  const [pageData, setPageData] = useState({ next: "/orders/getorders?page=2", count: 50 });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const fetchOrders = async (params = {}) => {
    setLoading(true);
    try {
      const res = await apiInstace.get(`/orders/getorders`, { ...requestOptions, params });
      setPageData({ count: res.data.count, next: res.data.next });
      setFetchedOrders([...res.data.results]);
    } catch (err) {
      console.log(err?.response?.data || err?.data || err);
      setPageData({ count: 0, next: null });
      setFetchedOrders([]);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchOrders();
  // }, [fetchAgain]);

  // useEffect(() => {
  //   // console.log(origetOrder);
  //   let orders = [];
  //   if (value === 0)
  //     orders = origetOrder.filter((item) => {
  //       return item?.order?.status === "PENDING";
  //     });
  //   if (value === 1) {
  //     orders = origetOrder.filter((item) => {
  //       return item?.order?.status === "IN_PROGESS";
  //     });
  //     //   console.log(orders);
  //   }
  //   if (value === 2)
  //     orders = origetOrder.filter((item) => {
  //       return item?.order?.status === "DELIVERED";
  //     });
  //   if (value === 3)
  //     orders = origetOrder.filter((item) => {
  //       return item?.order?.status === "REJECTED";
  //     });
  //   if (value === 4) {
  //     orders = origetOrder;
  //   }
  //   // console.log("filtererd orders");
  //   // console.log(orders);
  //   // console.log(origetOrder);

  //   setGetOrder([...orders]);
  // }, [origetOrder, value]);

  const tableUseRefs = Array.from({ length: 5 }, () => useRef(null));

  return (
    <div>
      <div className='order-head'>
        <p className='order-title'>All Orders</p>
        {/* <button disabled style={{opacity:"0.6"}}>
          <img alt="plus" src={Plus} />
          Create a New Order
        </button> */}
      </div>
      {/* <div className="order-grid"> */}
      {/* <div className="allorders">
          <AllOrders orders={getOrder} />
        </div> */}
      {/* <div className="canceled">
          <Canceled />
        </div>
        <div className="abandoned">
          <Abandoned />
        </div> */}
      {/* </div> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            <Tab label='PENDING' {...a11yProps(0)} />
            <Tab label='IN PROGRESS' {...a11yProps(1)} />
            <Tab label='DELIVERED' {...a11yProps(2)} />
            <Tab label='REJECTED' {...a11yProps(3)} />
            <Tab label='ALL' {...a11yProps(4)} />
          </Tabs>
        </Box>

        {Array.from({ length: 5 }, (_, index) => index).map((tabIndex) => (
          <CustomTabPanel value={value} index={tabIndex}>
            <OrderTable
              getOrder={fetchedOrders}
              value={value}
              fetchAgain={fetchAgain}
              setFetchAgain={setFetchAgain}
              fetchOrders={fetchOrders}
              tableUseRefs={tableUseRefs}
              status={["PENDING", "IN_PROGESS", "DELIVERED", "REJECTED"][value]}
              pageData={pageData}
            />
          </CustomTabPanel>
        ))}
      </Box>
      {/* <div>
        <OrderTable getOrder={getOrder} />
      </div> */}
    </div>
  );
};

export default All_Orders;
