import React, { useEffect, useState } from "react";
import UploadImage from "../../../assets/upload-image.svg";
import UploadCloud from "../../../assets/upload-cloud.svg";
import DeleteIcon from "../../../assets/Delete.png";
import handleImageUpload, { handleDeleteReq } from "../Utils/HandleImageUpload";

const ImageUpload = ({ newProduct, setNewProduct, setImageFile, imagesSave, isEdit }) => {
  // console.log(imagesSave.length);
  const imagesSaveDB = [...imagesSave];
  // imagesSaveDB.pop();
  // console.log(imagesSaveDB);
  for (let i = imagesSave.length; i < 5; i++) {
    imagesSaveDB.push(null);
  }
  // imagesSaveDB.concat(Array(5 - imagesSaveDB.length).fill(null)) ||
  //   Array(5).fill(null)

  const [activeIndex, setActiveIndex] = useState(
    imagesSave?.length !== 0 ? imagesSave?.length - 1 : 0
  );
  const [mainImage, setMainImage] = useState(imagesSaveDB?.length !== 0 ? imagesSaveDB[0] : "");

  // console.log(imagesSaveDB);

  const [images, setImages] = useState([...imagesSaveDB]);

  useEffect(() => {
    console.log(images);
  }, [images]);

  const handleDeleteImage = async (index) => {
    try {
      // Call handleDeleteReq to delete the image
      await handleDeleteReq(images[index]);

      // Update state after successful deletion
      const newFile = images.map((item, i) => (i === index ? null : item));
      const withoutNull = newFile.filter((item) => item !== null).length;

      if (index !== 0) {
        // Set active index
        setActiveIndex(withoutNull - 1);
      } else {
        // Handle deletion of main image
        setActiveIndex(withoutNull);
        setMainImage(null);
      }

      // Update images state
      setImages(newFile);
      setImageFile(newFile);
    } catch (error) {
      // Handle error if deletion fails
      console.error("Error deleting image:", error);
    }
  };

  useEffect(() => {
    console.log(activeIndex);
  }, [activeIndex]);
  useEffect(() => {
    console.log(mainImage);
  }, [mainImage]);
  const [image1, setImage1] = useState([]);
  const handleImageupload = async (event, index, main) => {
    if (main) {
      const file = event.target.files[0];
      setImage1([file]);

      const file_url = await handleImageUpload({ images: [file], setImages: setImage1 });
      console.log(file_url);
      const newImages = [...images];
      newImages[0] = file_url;
      setImageFile(newImages);
      console.log(newImages);
      setImages(newImages);

      setMainImage(file);
    } else {
      const file = event.target.files[0];
      setImage1([file]);
      const file_url = await handleImageUpload({ images: [file], setImages: setImage1 });
      // console.log(file_url);
      let newImages = images;
      newImages[index + 1] = file_url;
      console.log(newImages);

      setImages(newImages);
      setImageFile(newImages);

      // Activate the next box
      if (index + 1 < images.length - 1) {
        setActiveIndex(index + 1);
      }
    }
  };

  const handleInput = (key, value) => {
    setNewProduct({
      ...newProduct,
      [key]: value,
    });
  };

  // useEffect(() => {
  //   if (typeof newProduct.image === "string") {
  //     const array = newProduct.image.split(", ");
  //     // console.log("In use Effect Hook");
  //     console.log(array);
  //     setImages(
  //       array.concat(Array(5 - array.length).fill(null)) || Array(5).fill(null)
  //     );
  //     setMainImage(array[0]);
  //   }
  // }, []);
  return (
    <div className='imageUpload-div'>
      <div>
        <div className='upload-main-div'>
          <input
            type='file'
            id={`image-upload`}
            accept='image/*'
            style={{ display: "none" }}
            onChange={(event) => handleImageupload(event, 0, true)}
          />
          {mainImage && (
            <div className='img-container'>
              <div className='deleteImage'>
                <button className='delete-btn' onClick={() => handleDeleteImage(0)}>
                  <img src={DeleteIcon} alt='' />
                </button>
              </div>
              <img
                src={typeof mainImage !== "string" ? URL.createObjectURL(mainImage) : mainImage}
                alt={`Preview `}
              />
            </div>
          )}
          {!mainImage && (
            <label htmlFor='image-upload'>
              <div>
                <img alt='upload-img' src={UploadImage} className='upload-image' />
                <div className='upload-cloud-div'>
                  <img alt='upload-img' src={UploadCloud} className='upload-cloud' />
                  <p className='upload-text'>Upload Image</p>
                </div>
                <p className='upload-desc'>Upload a cover image for your product.</p>
                <p className='upload-desc'>
                  File Format <span>jpeg, png</span> Recommened Size
                  <span>600x600 (1:1)</span>
                </p>
              </div>
            </label>
          )}
        </div>
        <p className='add-image-text'>Additional Images</p>
        <div className='new-image-section'>
          {images
            ?.filter((item, index) => index !== 0)
            .map((image, index) => {
              return (
                <div
                  className={`${index === activeIndex ? "new-image-item" : "empty-image-item"}`}
                  style={{ margin: "5px", padding: "20px 19px" }}>
                  {image && (
                    <div className='img-container'>
                      <div className='deleteImage'>
                        <button className='delete-btn' onClick={() => handleDeleteImage(index + 1)}>
                          <img src={DeleteIcon} alt='' />
                        </button>
                      </div>
                      <img
                        src={typeof image === "string" ? image : URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                  )}
                  <div className='upload-box-inner'>
                    <input
                      type='file'
                      id={`image-upload-${index}`}
                      accept='image/*'
                      style={{ display: "none" }}
                      onChange={(event) => handleImageupload(event, index)}
                      disabled={index !== activeIndex}
                    />
                    {!image && image !== "" && (
                      <label htmlFor={`image-upload-${index}`}>
                        <div className='upload-image-div'>
                          <img alt='upload-img' src={UploadImage} className='upload-image' />
                        </div>
                        <div className='upload-cloud-div'>
                          <img alt='upload-img' src={UploadCloud} className='upload-cloud' />
                          <p className='upload-text'>Upload Image</p>
                        </div>
                      </label>
                    )}
                  </div>
                </div>
              );
            })}
          {/* <div
            className="empty-image-item"
            style={{ margin: "0px 0px 0px 10px" }}
          ></div> */}
        </div>
        {/* <div className="new-image-section"> */}
        {/* <div className="empty-image-item"></div>
          <div
            className="empty-image-item"
            style={{ margin: "0px 0px 0px 10px" }}
          ></div> */}
        {/* </div> */}
        <p className='add-video-text'>Add Video Link</p>
        <input
          value={newProduct?.video}
          placeholder='Enter Youtube Video Link'
          className='product-input'
          style={{ width: "100%" }}
          onChange={(e) => {
            handleInput("video", e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default ImageUpload;
