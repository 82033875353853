import React from "react";
import BagBlack from "../../../assets/Bag-black.svg";
import { useState, useEffect } from "react";

const Canceled = (props) => {
  const [numinprogress, setinprogress] = useState(0);
  const [numcancelled, setcancelled] = useState(0);

  useEffect(() => {
    console.log(props.orders);
    let c = 0;
    let i = 0;
    props.orders?.map((item) => {
      if (item.order?.status == "REJECTED") c++;
      else if (item.order?.status == "IN_PROGESS") i++;
    });
    setinprogress(i);
    setcancelled(c);
  }, [props.orders]);

  return (
    <div className='abandoned-main'>
      <div className='abandoned-div'>
        <img alt='cart-img' src={BagBlack} className='cart-image' />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className='abandoned-body'>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Canceled</p>
          <p className='cart-percentage'>{numcancelled}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>In Progress</p>
          <p className='cart-percentage'>{numinprogress}</p>
        </div>
        {/* <div className="abandoned-body-item">
          <p className="abandoned-body-title">Damaged</p>
          <p className="cart-percentage">
            0 <span>+0.00%</span>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Canceled;
