import { apiInstace } from "../../server"


const getUserDetails = async (id) => {
    try {
        const data = await apiInstace.get(`users/getUser/${id}`)
       // console.log("User ", data.data);
        return (data.data)

    } catch (error) {
        console.log(error)
    } finally {

    }
}

export default getUserDetails;