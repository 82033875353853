import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import getOrderDetails from "../Utils/getOrderDetails";
import getUserDetails from "../Utils/getUserDetails";

const NetworkRow = ({ profitData, index }) => {
  //console.log(profitData)
  const [orderData, setOrderData] = useState({});
  const [userData, setUserData] = useState({});

  const fetchData = async (order_profit_id) => {
    if (order_profit_id) {
      try {
        const order = await getOrderDetails(order_profit_id);
        const userDetail = await getUserDetails(order.order.user_id);
        setOrderData(order);
        setUserData(userDetail);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData(profitData?.order_profit);
  }, []);

  return (
    orderData?.id &&
    userData?.id && (
      <TableRow
        role='checkbox'
        tabIndex={-1}
        key={index + 1}
        sx={{ cursor: "pointer" }}
        style={{ border: "1px solid transparent" }}>
        <TableCell
          component='th'
          // id={labelId}
          scope='row'
          padding='none'
          style={{ border: "1px solid transparent" }}>
          {orderData?.order?.id}
        </TableCell>
        <TableCell align='left' style={{ border: "1px solid transparent" }}>
          {orderData?.order.created_at?.split("T")[0]}
        </TableCell>
        <TableCell align='left' style={{ border: "1px solid transparent" }}>
          {userData?.first_name}
        </TableCell>
        <TableCell align='left' style={{ border: "1px solid transparent" }}>
          {orderData?.order?.total_cost?.toFixed(2) || orderData?.order?.total_cost}
        </TableCell>
        <TableCell align='left' style={{ border: "1px solid transparent" }}>
          {profitData?.profit_amount?.toFixed(2) || profitData?.profit_amount}
        </TableCell>
        <TableCell align='left' style={{ border: "1px solid transparent" }}>
          {profitData?.profit_role}
        </TableCell>
      </TableRow>
    )
  );
};

export default NetworkRow;
