import React, { useEffect, useState } from 'react'
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import getOrderDetails from '../Utils/getOrderDetails';
import getUserDetails from '../Utils/getUserDetails';
import moment from 'moment';

const UsersRow = ({ userData , index}) => {

    return (
        userData.id &&
        <TableRow
            role="checkbox"
            tabIndex={-1}
            key={index + 1}
            sx={{ cursor: "pointer" }}
            style={{ border: "1px solid transparent" }}
        >
            <TableCell
                component="th"
                // id={labelId}
                scope="row"
                padding="none"
                style={{ border: "1px solid transparent" }}
            >
                {userData?.id}
            </TableCell>
            <TableCell
                align="left"
                style={{ border: "1px solid transparent" }}
            >
                {userData?.phone}
            </TableCell>
            <TableCell
                align="left"
                style={{ border: "1px solid transparent" }}
            >
                {userData?.first_name}
            </TableCell>
            <TableCell
                align="left"
                style={{ border: "1px solid transparent" }}
            >
                {userData?.relation?.join(" , ")?.toUpperCase()}
            </TableCell>
            <TableCell
                align="left"
                style={{ border: "1px solid transparent" }}
            >
                {userData?.role}
            </TableCell>
            <TableCell
                align="left"
                style={{ border: "1px solid transparent" }}
            >
                {moment(userData?.date_joined).format('DD/MM/YY')}
            </TableCell>
        </TableRow>
    );
}

export default UsersRow