import React, { useState, useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import { apiInstace } from "../server";
import axios from "axios";
import { Box, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { toast } from "react-toastify";
import DashboardContext from "../Context/DashboardContext";

function ProfileUpdate(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    p: 2,
    backdropfilter: "blur(4px)",
    gap: 16,
  };
  const handleClose = () => {
    props.setOpen(false);
  };
  // const [profile_data, setProfile_data] = useState({})
  const [profile, setProfile] = useState({
    // first_name: "",
    // last_name: "",
    phone: "",
  });

  const { setLoading, languages, networkMsg, setNetworkMsg, fetchandsetNetworkMsg } =
    useContext(DashboardContext);

  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3ODgyMjg0LCJpYXQiOjE2ODc0MzU0OTMsImp0aSI6IjgyNmIxMGVlNzc2YTQwNzA4MjE2NmMwMDIzYjQzMTQyIiwidXNlcl9pZCI6OTB9.lsoyuOy800HQAPW35BY7SN5HiX6wQREE4e8Y1XKcKCM`,
      },
    };
    apiInstace
      .get(`/users/getUser/${localStorage.getItem("id")}`)
      .then((user) => {
        const { first_name, last_name, phone } = user.data;
        setProfile({ first_name, last_name, phone });
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(()=>{
  //     console.log(profile);
  // },[profile])

  const handleInput = (key, value) => {
    setProfile({ ...profile, [key]: value });
  };
  const handleSubmit = () => {
    console.log(profile);
    const token = `Bearer ${localStorage.getItem("access")}`;
    apiInstace
      .patch(`/auth/user_update/${localStorage.getItem("id")}`, {
        cust_update: profile,
        token,
      })
      .then((res) => {
        // console.log(res);
        toast.success("Profile Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchandsetNetworkMsg();
      })
      .catch((err) => {
        toast.error(`${err?.response?.data?.message ?? "Something Went Wrong"}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
      });
    handleUpdateNetworkMsg();
    handleClose();
  };

  const [selectedLang, setSelectedLang] = useState({ id: 3, language: "Hindi" });

  //   const [languages, setLanguages] = useState([]);
  //   const fetchandsetlanguages = () => {
  //     const requestOptions = {
  //       headers: {
  //         method: "GET",
  //         Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       },
  //     };
  //     apiInstace
  //       .get(`/translation/languages`, requestOptions)
  //       .then((res) => {
  //         setLanguages(res.data.results);
  //       })
  //       .catch((err) => {
  //         console.log(err.data);
  //       });
  //   };

  //   useEffect(() => {
  //     console.log("lan", networkMsg);

  //     languages.map((lang) => {
  //       console.log(lang.id, lang.language);
  //     });
  //   }, [networkMsg]);

  //   const [networkMsg, setNetworkMsg] = useState({});

  const handleNetworkMsg = (key, value) => {
    let langName = selectedLang?.language?.toLowerCase() + "_message";
    setNetworkMsg({ ...networkMsg, [langName]: value });
  };
  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  //   const fetchandsetNetworkMsg = () => {
  //     apiInstace
  //       .get(`/translation/network_message/${localStorage.getItem("id")}`, requestOptions)
  //       .then((res) => {
  //         setNetworkMsg(res?.data[0]);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const handleUpdateNetworkMsg = () => {
    apiInstace
      .patch(
        `/translation/network_message/${localStorage.getItem("id")}/`,
        networkMsg,
        requestOptions
      )
      .then((res) => {
        setNetworkMsg(res?.data[0]);
      })
      .catch((err) => {
        toast.error(`${JSON.stringify(err?.response?.data) ?? "Something Went Wrong"}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err?.response?.data);
      });
  };

  useEffect(() => {
    // fetchandsetlanguages();
    // fetchandsetNetworkMsg();
  }, []);

  //   useEffect(() => {
  //     //   console.log(
  //     //     selectedLang?.language?.toLowerCase() + "_message",
  //     //     networkMsg,
  //     //     networkMsg[selectedLang?.language?.toLowerCase() + "_message"]
  //     //   );
  //     console.log("selectedLang", selectedLang);
  //   }, [selectedLang]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className='modal-head'>
            <p className='modal-title'>Edit Profile</p>
            <button className='close-button' onClick={handleClose}>
              X
            </button>
          </div>
          {/* <p className='user-info'>First Name</p>
          <input
            placeholder='First Name'
            className='product-input user-name'
            style={{ width: "100%" }}
            value={profile?.first_name}
            onChange={(e) => handleInput("first_name", e.target.value)}
          />

          <p className='user-info'>Last Name</p>
          <input
            placeholder='Last Name'
            className='product-input user-name'
            style={{ width: "100%" }}
            value={profile?.last_name}
            onChange={(e) => handleInput("last_name", e.target.value)}
          /> */}

          <p className='user-info'>Phone Number</p>
          <input
            placeholder='+919822221876'
            className='product-input'
            style={{ width: "100%" }}
            value={profile?.phone}
            onChange={(e) => handleInput("phone", e.target.value)}
          />

          <p className='user-info'>Grow Your Network Message</p>

          <Box
            sx={{
              display: "flex",
              py: 2,
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}>
            <ToggleButtonGroup
              color='primary'
              value={selectedLang?.id}
              exclusive
              onChange={(e, value) => {
                // console.log("value", value, selectedLang);
                value != null &&
                  setSelectedLang((prev) => languages.find((lang) => lang.id == value));
              }}
              aria-label='Platform'
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
              }}>
              {languages &&
                languages?.map((lang) => (
                  <ToggleButton
                    key={lang.id}
                    value={lang.id}
                    sx={{ display: "flex", width: "100%" }}>
                    {lang.language}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </Box>
          {/* <p className='user-info'>Phone Number</p> */}

          <TextField
            // label='Network Message'
            value={networkMsg?.[selectedLang?.language?.toLowerCase() + "_message"] ?? ""}
            className=' desc-textarea'
            multiline
            rows={8}
            placeholder='Network Message'
            onChange={(e) => {
              handleNetworkMsg("Network Message", e.target.value);
            }}
            sx={{ display: "flex", width: "100%", fontSize: 2 }}
          />

          <div className='section-button-add' style={{ marginTop: "10px" }}>
            <button className='cancel-add-button' onClick={handleClose}>
              Cancel
            </button>
            <button
              className='add-add-button'
              onClick={() => {
                handleSubmit();
              }}>
              Update
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ProfileUpdate;
