import React, { useContext, useEffect, useState } from "react";
import NewProductForm from "./Layout/NewProductForm";
import ImageUpload from "./Layout/ImageUpload";
import server from "../server";
import axios from "axios";
import { apiInstace } from "../server";
import handleImageUpload from "./Utils/HandleImageUpload";
import { toast } from "react-toastify";
import DashboardContext from "../Context/DashboardContext";
import { useLocation, useNavigate } from "react-router-dom";

const NewProduct = () => {
  const context = useContext(DashboardContext);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.data || {};
  // console.log(data);
  // console.log(data.id);
  //   const id = ;
  const isEdit = location?.state.isEdit;
  const [id, setId] = useState(location.state?.id || -1);
  // console.log(data);
  const { setLoading } = context;
  const [newProduct, setNewProduct] = useState({
    name: data?.name || "",
    price: data?.price || "",
    mrp: data?.mrp || "",
    base_price: data?.base_price || "",
    // rating: data?.rating || 0,
    // description: data?.description || "",
    // sharing_message: data?.sharing_message || "",
    crops: data?.crops || [],
    brands: data?.brands || [],
    farm_equipments: data?.farm_equipments || [],
    // weight: data?.weight || 0,
    no_of_shares: data?.no_of_shares || "",
    amount_after_shares: data?.amount_after_shares || "",
    shipping_type: data?.shipping_type || "KAMAOKISAN",
    bdo_share: data?.bdo_share || "",
    dealer_share: data?.dealer_share || "",
    parent_share: data?.parent_share || "",
    grand_parent_share: data?.grand_parent_share || "",
    tags: data?.tags || [],
    image: data?.image || [],
    video: data?.video || "",
    length: data?.length || 0,
    breadth: data?.breadth || 0,
    height: data?.height || 0,
    status: data?.status || "ACTIVE",
    // address: data?.address || [],
    cost_per_share: data?.cost_per_share || "",
    cash_on_delivery: data?.cash_on_delivery || false,
    weight: data?.weight || 0.0,
    is_featured: data?.is_featured,
  });
  // console.log(newProduct);
  const [add_data, setAdd_data] = useState({
    address: "",
    state: "",
    city: "",
    pincode: "",
  });

  const [addUpdated, setAddUpdated] = useState(false);
  useEffect(() => {
    if (id >= 0) {
      const requestOptions = {
        headers: {
          method: "GET",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      apiInstace
        .get(`/products/getaddress`)
        .then((res) => {
          // console.log(res.data.results);
          let address = res.data.results.filter((item) => item.id == data?.address[0]);
          //   console.log(address);
          let valid = {};
          for (const key in address[0]) {
            // if (add_data[key] != undefined) add_data[key] = address[0][key];
            if (add_data[key] != undefined) valid[key] = address[0][key];
          }
          //   console.log("add_data valid", valid);
          setAdd_data(valid);
          //   setAdd_data(address[0]);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  //   useEffect(() => {
  //     console.log("add_data", add_data);
  //   }, [add_data]);
  const [add_id, setAdd_id] = useState(0);
  const [imageFile, setImageFile] = useState(data.image?.split(", ") || []);

  useEffect(() => {
    console.log("imageFile", imageFile);
    setNewProduct({ ...newProduct, image: imageFile });
  }, [imageFile]);

  const handleNewProduct = async () => {
    let validData = true;
    const kamaokisanFields = ["length", "breadth", "height", "address", "weight"];
    const validByDefault = ["tags", "cost_per_share", "cash_on_delivery", "description"];

    const invalidValues = [null, undefined, ""];
    Object.keys(newProduct)
      .filter((field) =>
        newProduct?.shipping_type == "KAMAOKISAN"
          ? !validByDefault.includes(field)
          : !kamaokisanFields.concat(validByDefault).includes(field)
      )
      .forEach((key) => {
        if (invalidValues.includes(newProduct[key])) {
          validData = false;
          toast.error("No fields should be left blank");
          console.log(key, newProduct[key]);
        }
      });

    newProduct.shipping_type == "KAMAOKISAN" &&
      Object.keys(add_data).forEach((key) => {
        if (!add_data[key] || add_data[key] == "") {
          validData && toast.error("No fields to be left blank");
          validData = false;
          console.log(key);
        }
      });

    if (!validData) console.log(add_data);

    if (!validData) return;

    // updateProductDesc();

    console.log(
      "isEdit condns",
      imageFile[0] !== null,
      add_data?.address?.length != 0,
      newProduct?.shipping_type == "KAMAOKISAN"
    );
    if (imageFile[0] !== null) {
      setLoading(true);
      // console.log(imageFile);
      let images = [];
      imageFile.map((item) => {
        if (item != null) images.push(item);
      });
      // console.log(images);
      // let result=""
      // images.map((item)=>{
      //   console.log(item);
      //   result=result.concat(item,",");
      //   console.log(result);
      // })
      const result = images.toString();
      // console.log(result);

      // handleImageUpload({images:imageFile, setImages:setImageFile})
      //   .then((result) => {
      console.log(result);

      //   console.log(product);
      // setNewProduct({...product})

      if (isEdit === false) {
        console.log("isEdit is false");
        if (newProduct?.shipping_type == "KAMAOKISAN") {
          createaddress(result);
          // console.log(id);
          // setNewProduct({...newProduct,address:[add_id]})
        } else {
          postProduct(result, -1, id);
        }
      } else {
        if (add_data?.address?.length != 0) updateaddress(result, add_data?.address);
        else {
          if (newProduct?.shipping_type == "KAMAOKISAN") {
            createaddress(result);
            // console.log(id);
            // setNewProduct({...newProduct,address:[add_id]})
          } else {
            console.log("isEdit final");
            updateProduct(result, -1);
          }
          // setNewProduct({...newProduct,address:[add_id]})
        }
        // updateProduct(result);
      }
      // console.log(result);
      setLoading(false);
      // })
      // .catch((err) => {
      //   console.log(err);
      //   setLoading(false);

      //   toast.error("Something went wrong!", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      // });
    } else {
      toast.error("Add a main image first!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // const saveDarft = () => {
  //   if (imageFile[0]) {
  //     setLoading(true);
  //     handleImageUpload({images:imageFile, setImages:setImageFile})
  //       .then((result) => {

  //         if (isEdit === false) {
  //           postProduct("UNPUBLISH", result);
  //         } else {
  //           updateProduct(result);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toast.error("Something went wrong!", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       });
  //   } else {
  //     toast.error("Add a main image first!", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };

  const updateProduct = (result, id) => {
    console.log("isEdit updating");
    let product = {
      ...newProduct,
      image: result,
      status: "PUBLISH",
      cost_per_share:
        (newProduct?.price - newProduct?.amount_after_shares) / newProduct?.no_of_shares,
    };

    if (id >= 0) product = { ...product, address: [id] };

    console.log(product);
    const token = `Bearer ${localStorage.getItem("access")}`;
    apiInstace
      .patch(`/products/updateProduct/${data.id}`, {
        newProduct: product,
      })
      .then((result) => {
        setLoading(false);
        toast.success("Product Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        updateProductDesc(data.id);
        // const state = { ...result.data };
        // navigate("/viewproduct", { state });
        navigate("/inventory");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(`Something went wrong: ${JSON.stringify(err?.response?.data)}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const postProduct = async (result, id, productID) => {
    let product = {
      ...newProduct,
      image: result,
      status: "PUBLISH",
      cost_per_share:
        (newProduct?.price - newProduct?.amount_after_shares) / newProduct?.no_of_shares,
    };
    console.log(id);
    if (id >= 0) {
      product = { ...product, address: [id] };
    }

    console.log(product);
    const token = `Bearer ${localStorage.getItem("access")}`;
    apiInstace
      .post(`/products/createProduct`, { newProduct: product })
      .then((res) => {
        setLoading(false);
        toast.success("Product Uploaded!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        updateProductDesc(res.data.id);
        navigate("/inventory");
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);

        toast.error(`${JSON.stringify(err?.response?.data)}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const createaddress = (result) => {
    console.log(add_data);
    const token = `Bearer ${localStorage.getItem("access")}`;
    apiInstace
      .post(`/products/address_register`, { add_data })
      .then((res) => {
        setAdd_id(res.data.id);
        setLoading(false);
        toast.success("Address Created!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("isEdit", isEdit);

        if (isEdit) updateProduct(result, res.data.id);
        else postProduct(result, res.data.id, id);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response?.data);
        toast.error(`Something went wrong!:${JSON.stringify(err?.response?.data)}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const updateaddress = (result, id) => {
    const token = `Bearer ${localStorage.getItem("access")}`;
    addUpdated
      ? apiInstace
          .patch(`/products/address_update/${id}`, {
            add_update: add_data,
            token,
          })
          .then((res) => {
            setLoading(false);
            toast.success("Address Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log("isEdit updating after update address");
            updateProduct(result, id);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Something went wrong!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          })
      : updateProduct(result, id);
  };

  const [productDesc, setProductDesc] = useState({});

  const updateProductDesc = (productID) => {
    const toastStyles = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    };

    setLoading(true);

    apiInstace
      .post(
        `/translation/product/${productID}/`,
        {
          ...productDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        toast.success("Product Description Updated!", toastStyles);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        toast.error(
          `Something went wrong with Product Description Update! ${err.message}`,
          toastStyles
        );
      });
  };

  useEffect(() => {
    setNewProduct({
      ...newProduct,
      description: productDesc?.[1]?.["DESCRIPTION"]?.["content"] ?? "",
    });
  }, [productDesc]);

  //   useEffect(() => {
  //     console.log("newProduct", newProduct);
  //   }, [newProduct]);

  return (
    <div>
      <div className='newProduct-head'>
        <p className='newProduct-title'>
          {isEdit ? <>Update Product</> : <>New Product Registration</>}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {/* {!isEdit && (
            <button className="save-select" onClick={saveDarft}>
              Save as Draft
            </button>
          )} */}
          <button className='save-button' onClick={() => navigate(-1)}>
            Back
          </button>
          {isEdit && (
            <button className='save-button' onClick={handleNewProduct}>
              Update Product
            </button>
          )}
          {!isEdit && (
            <button className='save-button' onClick={handleNewProduct}>
              Save & Publish
            </button>
          )}
        </div>
      </div>
      <div className='new-product-flex'>
        <NewProductForm
          newProduct={newProduct}
          setNewProduct={setNewProduct}
          isEdit={isEdit}
          add_data={add_data}
          setAdd_data={setAdd_data}
          addUpdated={addUpdated}
          setAddUpdated={setAddUpdated}
          productDesc={productDesc}
          setProductDesc={setProductDesc}
        />
        <ImageUpload
          newProduct={newProduct}
          setNewProduct={setNewProduct}
          setImageFile={setImageFile}
          imagesSave={typeof newProduct?.image === "string" ? newProduct?.image.split(",") : []}
          isEdit={isEdit}
        />
      </div>
    </div>
  );
};

export default NewProduct;
