import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import axios from "axios";
import DashboardContext from "../../Context/DashboardContext";
import { apiInstace } from "../../server";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import handleImageUpload from "../../Inventory/Utils/HandleImageUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowBackOutlined, UploadRounded, Visibility } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { Button } from "@mui/material";
import DefaultCrop from "../../../assets/defaultuploadcrop.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { invalid } from "moment";

function Crop_add() {
  const location = useLocation();
  const [id, setId] = useState(location.state?.id);
  // console.log(id);
  const [alignment, setAlignment] = useState(1);
  const context = useContext(DashboardContext);
  const { setLoading, languages } = context;
  const handleChange1 = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const [crop_data_trans, setCrop_data_trans] = useState([]);
  const [crop_data, setCrop_data] = useState([]);

  const navigate = useNavigate();
  const [new_crop, setNew_crop] = useState({
    1: {
      name: "",
      watering: "",
      cultivation: "",
      harvesting: "",
      labour: "",
      sunlight: "",
      ph_value: "",
      temperature: "",
      fertilization: "default",
      // equipments:"",
      language: "1",

      description: "<p><br></p>",
    },
    2: {
      name: "",
      watering: "",
      cultivation: "",
      harvesting: "",
      labour: "",
      sunlight: "",
      ph_value: "",
      temperature: "",
      fertilization: "default",
      // equipments:"",
      language: "2",

      description: "<p><br></p>",
    },
    3: {
      name: "",
      watering: "",
      cultivation: "",
      harvesting: "",
      labour: "",
      sunlight: "",
      ph_value: "",
      temperature: "",
      fertilization: "default",
      // equipments:"",
      language: "3",

      description: "<p><br></p>",
    },
    // type:"",
  });
  const [new_crop1, setNew_crop1] = useState({
    name: "",
    icon: "",
    image: "",
  });
  const [new_cropupdate, setNew_cropupdate] = useState({});
  const [new_crop1update, setNew_crop1update] = useState({});

  const [images, setImages] = useState([]);
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    if (id >= 0) {
      // console.log(id);
      apiInstace
        .get(`/crops/getCrops_trans/${id}`, requestOptions)
        .then((res) => {
          // console.log(res.data.results);
          const crops_data = [];
          res.data.results.map((item) => {
            if (item.crop == id) crops_data.push({ ...item });
          });
          // console.log(crops_data);
          if (!Boolean(crops_data.length)) setIsNew(true);
          setCrop_data_trans([...crops_data]);
        })
        .catch((err) => {
          console.log(err);
        });

      apiInstace
        .get(`/crops/getcrops`, requestOptions)
        .then((res) => {
          // console.log(res.data.results);
          const crops_data = [];
          res.data.results.map((item) => {
            if (item.id == id)
              crops_data.push({
                name: item.name,
                image: item.image,
                icon: item.icon,
              });
          });
          //   console.log(crops_data);
          // setCrop_data([...crops_data])
          setNew_crop1({ ...crops_data[0] });
          setNew_crop1update({ ...crops_data[0] });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const fetchSetByLang = () => {};

  useEffect(() => {
    // console.log(id, new_crop1, new_crop);

    if (id >= 0 && languages) {
      const tempNewCrop = {};
      const tempNewCropUpdate = {};

      languages?.forEach((language) => {
        const cropDataTransLang = crop_data_trans.find((item) => item.language === language.id);

        if (language.language == "Marathi") setAlignment(language.id);

        // console.log(cropDataTransLang);

        if (cropDataTransLang) {
          const updatedCrop = { ...new_crop[language.id] };

          for (const key in cropDataTransLang) {
            if (updatedCrop[key] !== undefined) {
              updatedCrop[key] = cropDataTransLang[key];
            }
          }

          tempNewCrop[language.id] = { ...cropDataTransLang, new: false };
          tempNewCropUpdate[language.id] = { ...updatedCrop, id: cropDataTransLang.id, new: false };
        } else {
          tempNewCropUpdate[language.id] = {};
          tempNewCrop[language.id] = {
            description: "<p><br></p>",
            fertilization: 1,
            language: language.id,
            new: true,
          };
        }
      });
      // console.log("tempNewCrop", tempNewCrop);

      setNew_crop({ ...tempNewCrop });
      setNew_cropupdate({ ...tempNewCropUpdate });
    }
  }, [id, languages, crop_data_trans]);

  // useEffect(() => {
  //   console.log(`new_crop?.[${alignment}]?.description`, new_crop?.[alignment]?.description);
  // }, [new_crop, alignment]);

  // console.log(alignment);
  const handleInput = (lang, key, value) => {
    // console.log(new_crop);
    setNew_crop({
      ...new_crop,
      [lang]: { ...new_crop[lang], [key]: value },
    });
    // console.log("new_crop update", lang, key, value);
  };

  const [isNew, setIsNew] = useState(false);

  const handlesubmit1 = () => {
    console.log(new_crop1);
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);

    if (id >= 0) {
      let crop1_update = {};
      for (const key in new_crop1) {
        if (new_crop1update[key] != new_crop1[key])
          crop1_update = { ...crop1_update, [key]: new_crop1[key] };
      }
      if (Object.keys(crop1_update).length != 0) {
        console.log(crop1_update);
        apiInstace
          .patch(`/crops/updatecrop/${id}`, { crop1_update, token })
          .then((res) => {
            toast.success(`Crop Updated!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            // handleClose1();
            setLoading(false);

            console.log(res);
          })
          .catch((err) => {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setLoading(false);
            console.log(err);
          });
      }
      handleClose1();
    } else {
      apiInstace
        .post(`/crops/createcrop`, { new_crop1, token })
        .then((res) => {
          setNew_crop({
            ...new_crop,
            [1]: { ...new_crop[1], crop: res.data.id },
            [2]: { ...new_crop[2], crop: res.data.id },
            [3]: { ...new_crop[3], crop: res.data.id },
          });
          setLoading(false);
          toast.success("Crop Created!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setId(res.data.id);
          //   setIsNew(true);
          handleClose1();

          //   console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleClose1();

          console.log(err);
        });
    }
  };

  //   useEffect(() => {
  //     console.log("isNew", isNew);
  //   }, [isNew]);

  const handlesubmit = (lang, name) => {
    // console.log(
    //   "new_cropupdate[lang]",
    //   new_cropupdate,
    //   lang,
    //   Object.keys(new_cropupdate[lang]).length,
    //   "Updating:",
    //   id >= 0,
    //   Object.keys(new_cropupdate[lang]).length != 0
    // );
    // return;
    // console.log(new_crop1);
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);
    if (typeof new_cropupdate[lang] != typeof {}) {
      setLoading(false);
      return;
    }
    if (
      id >= 0 &&
      // && new_cropupdate[lang]?.id
      Object.keys(new_cropupdate[lang]).length != 0
    ) {
      let crop_update = {};
      console.log(new_cropupdate[lang]);
      const trans_id = new_cropupdate[lang].id;
      // console.log(trans_id);
      for (const key in new_crop[lang]) {
        if (new_cropupdate[lang][key] != new_crop[lang][key])
          crop_update = { ...crop_update, [key]: new_crop[lang][key] };
      }

      console.log(crop_update, trans_id);
      if (Object.keys(crop_update ?? {}).length != 0) {
        apiInstace
          .patch(`/crops/updatecrop_trans/${trans_id}`, { crop_update, token })
          .then((res) => {
            toast.success(`Crop Translation Updated in ${name}!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            // console.log(res);
          })
          .catch((err) => {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            // console.log(err);
          });
      }
      setLoading(false);
    } else {
      let crop_trans;
      if (id >= 0) crop_trans = { ...new_crop[lang], crop: id };
      else crop_trans = { ...new_crop[lang] };

      const compfields = [
        "name",
        "watering",
        "cultivation",
        "harvesting",
        "labour",
        "sunlight",
        "ph_value",
        "temperature",
        "fertilization",
        "language",
        "description",
      ];

      let inValid = false;

      compfields.forEach((field) => {
        if (!crop_trans?.[field]) inValid = field;
      });

      if (inValid) {
        toast.error(`Complete all fields in ${name}`);
        console.log(inValid);
        setLoading(false);
        return;
      }

      const data = { crop_trans };

      //   console.log(data);
      //   setNew_cropupdate((prevCrop) => ({
      //     ...prevCrop,
      //     [lang]: { ...crop_trans },
      //   }));
      //   return;
      apiInstace
        .post(`/crops/createcrop_trans`, { data, token })
        .then((res) => {
          setLoading(false);
          toast.success(`Crop translation created in ${name}!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          console.log(res);
          setNew_cropupdate((prevCrop) => ({
            ...prevCrop,
            [lang]: { ...crop_trans },
          }));
        })
        .catch((err) => {
          setLoading(false);
          toast.error(
            `${languages.find(({ id }) => lang == id)?.language}${JSON.stringify(
              err.response.data
            )}`,
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          console.log(err.response.data);
        });
    }
    // navigate("/content")
  };

  const handleupload = () => {
    const imginput = document.getElementById(`crop_img`);
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      //  console.log(file)
      if (!file) return;
      setImages([file]);
    };
    imginput.addEventListener("change", geturl);
  };

  useEffect(() => {
    handleImageUpload({ images: images, setImages: setImages })
      .then((res) => {
        const img_url = res;
        // console.log(img_url);
        setNew_crop1({
          ...new_crop1,
          image: img_url,
        });
        document.querySelector(`.crop_img`).src = img_url;
      })
      .catch(
        (err) => {}
        //   console.log(err)
      );
  }, [images]);

  useEffect(() => {
    handleImageUpload({ images: icons, setImages: setIcons })
      .then((res) => {
        const img_url = res;
        // console.log(img_url);
        setNew_crop1({
          ...new_crop1,
          icon: img_url,
        });
        document.querySelector(`.crop_icon`).src = img_url;
      })
      .catch(
        (err) => {}
        //    console.log(err)
      );
  }, [icons]);

  const handleupload1 = () => {
    const imginput = document.getElementById(`crop_icon`);
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      if (!file) return;
      setIcons([file]);
    };
    imginput.addEventListener("change", geturl);
  };
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffffff",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
    // height: "60vh",
    padding: 4,
  };

  //   useEffect(() => {
  //     console.log(new_crop);
  //   }, [new_crop]);

  //   const [languages, setLanguages] = useState(JSON.parse(localStorage.getItem("languages")) ?? []);
  //   const fetchandsetlanguages = () => {
  //     const requestOptions = {
  //       headers: {
  //         method: "GET",
  //         Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       },
  //     };
  //     apiInstace
  //       .get(`/translation/languages`, requestOptions)
  //       .then((res) => {
  //         setLanguages(res.data.results);
  //       })
  //       .catch((err) => {
  //         console.log(err.data);
  //       });
  //   };
  //   useEffect(() => {
  //     fetchandsetlanguages();
  //   }, []);

  const [selectedLang, setSelectedLang] = useState(1);

  useEffect(() => {
    id < 0 && handleOpen1();
  }, [id]);

  const [displayDesc, setDisplayDesc] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 5,
        minHeight: "100vh",
      }}>
      <ArrowBackOutlined
        onClick={() => navigate("/content")}
        style={{ position: "absolute", left: "5rem", top: "8rem" }}
      />

      {/* <div onClick={()=>{navigate("/content")}}><ArrowBackIcon /></div> */}
      <p style={{ padding: "10px" }}>{new_crop1?.name}</p>
      <Modal
        open={open1}
        onClose={id > -1 && handleClose1}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div className='modal-head krishi-modal-head'>
              <p className='modal-title'>
                {id >= 0 ? <>Edit Base Name/Image</> : <> Create New Crop</>}
              </p>
              <button
                className='close-button-krishi'
                onClick={() => (id > -1 ? handleClose1() : history.go(-1))}>
                X
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 8,
              }}>
              <label>Base Name</label>
              <input
                className='product-input'
                placeholder='Enter Title/Name'
                value={new_crop1?.name}
                onChange={(e) => {
                  // const prevData = { ...new_crop };
                  // prevData[1].name = e.target.value;
                  // setNew_crop(prevData);
                  setNew_crop1({ ...new_crop1, name: e.target.value });
                }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className='flex-div'
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: 2,
                  alignItems: "start",
                }}>
                <label>Crop Image</label>

                <img
                  style={{ maxWidth: "3rem", maxHeight: "3rem" }}
                  className='crop_img'
                  src={new_crop1?.image}
                  // alt= "Upload Image"
                />

                <button
                  className='upload-logo-button'
                  style={{ marginTop: "10px" }}
                  onClick={() => handleupload()}>
                  <input id='crop_img' type='file' accept='image/*' hidden />
                  {/* Upload Image */}
                  <UploadRounded />
                </button>
              </Box>
              <Box
                className='flex-div'
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: 2,
                  alignItems: "start",
                }}>
                <label>Crop Icon</label>

                <img
                  style={{ maxWidth: "3rem", maxHeight: "3rem" }}
                  className='crop_icon'
                  src={new_crop1?.icon}
                />

                <button
                  className='upload-logo-button'
                  style={{ marginTop: "10px" }}
                  onClick={() => handleupload1()}>
                  <input id='crop_icon' type='file' accept='image/*' hidden />
                  {/* Upload Icon */}
                  <UploadRounded />
                </button>
              </Box>
            </div>
            <button onClick={handlesubmit1} className='upload-logo-button'>
              Save and Publish
            </button>
          </Box>
        </Box>
      </Modal>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          padding: "0 32px",
        }}>
        <button
          onClick={handleOpen1}
          className='upload-logo-button'
          style={{ cursor: "pointer", margin: 0 }}>
          {id >= 0 ? <>Edit Base Name/Image</> : <>Create Crop</>}
        </button>
        <Box
          sx={{
            display: "flex",
            py: 2,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 1,
          }}>
          <ToggleButtonGroup
            color='primary'
            defaultValue={alignment}
            value={alignment}
            exclusive
            onChange={(e, value) => {
              //   let changed = false;
              //   Object.keys(new_crop).forEach((key) => {
              //     if (new_crop?.[key] != new_cropupdate?.[key]) changed = true;
              //   });
              //   if changed
              setAlignment((prev) => value ?? prev);
            }}
            aria-label='Platform'
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
            }}
            defaultChecked={1}>
            {languages &&
              languages?.map((lang) => (
                <ToggleButton
                  key={lang.id}
                  value={lang.id}
                  sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
                  {lang.language}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
            }}>
            {languages?.map((lang) => (
              <Button>
                {" "}
                <UploadRounded
                  onClick={() => {
                    handlesubmit(lang.id, lang.language);
                  }}
                />
              </Button>
            ))}
          </div> */}
        </Box>
        <Button
          onClick={() => {
            //   languages &&
            // languages?.forEach(({ id, language }) => {
            //   handlesubmit(id, language);
            // });
            handlesubmit(alignment, languages.find((lan) => lan.id == alignment).language);
            //   history.go(0);
          }}>
          {"Update"}
          <UploadRounded />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          bgcolor: "white",
          gap: 2,
          width: "85%",
        }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <label>Ttile/Name</label>
            <input
              className='product-input'
              placeholder='Enter Title/Name'
              value={new_crop[alignment]?.name ?? ""}
              onChange={(e) => handleInput(alignment, "name", e.target.value)}
            />
          </Box>
          <p style={{ fontSize: "1.3rem" }}>Plant Requirements</p>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <label style={{ textAlign: "left" }}>Requirements</label>
            <div className='flex-div'>
              <label className='product-input'>Water</label>
              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.watering ?? ""}
                onChange={(e) => handleInput(alignment, "watering", e.target.value)}
              />
            </div>
            <div className='flex-div'>
              <label className='product-input'>Cultivation</label>

              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.cultivation ?? ""}
                onChange={(e) => handleInput(alignment, "cultivation", e.target.value)}
              />
            </div>
            <div className='flex-div'>
              <label className='product-input'>Harvesting</label>

              {/* <input className="product-input" placeholder="Harvesting" /> */}
              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.harvesting ?? ""}
                onChange={(e) => handleInput(alignment, "harvesting", e.target.value)}
              />
            </div>
            <div className='flex-div'>
              <label className='product-input'>Labour</label>

              {/* <input className="product-input" placeholder="" /> */}
              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.labour ?? ""}
                onChange={(e) => handleInput(alignment, "labour", e.target.value)}
              />
            </div>
            <div className='flex-div'>
              <label className='product-input'>Sunlight</label>

              {/* <input className="product-input" placeholder="" /> */}
              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.sunlight ?? ""}
                onChange={(e) => handleInput(alignment, "sunlight", e.target.value)}
              />
            </div>
            <div className='flex-div'>
              <label className='product-input'>Ph-value</label>

              {/* <input className="product-input" placeholder="" /> */}
              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.ph_value ?? ""}
                onChange={(e) => handleInput(alignment, "ph_value", e.target.value)}
              />
            </div>
            <div className='flex-div'>
              <label className='product-input'>Temperature</label>

              {/* <input className="product-input" placeholder="" /> */}
              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.temperature ?? ""}
                onChange={(e) => handleInput(alignment, "temperature", e.target.value)}
              />
            </div>
            {/* <button
              className='upload-logo-button'
              style={{ marginTop: 20 }}
              onClick={() => {
                languages.forEach(({ id, language }) => {
                  handlesubmit(id, language);
                });
                //   handlesubmit(3);
              }}>
              Save and Publish
            </button> */}
            {/* <div className='flex-div'>
              <label className='product-input'>Fertilization</label>

              <input
                className='product-input'
                placeholder='Enter Requirements'
                value={new_crop[alignment]?.fertilization}
                onChange={(e) => handleInput(alignment, "fertilization", e.target.value)}
              />
            </div> */}
            {/* <div className="flex-div">
                <label className="product-input">Fertilization</label>

                <input
                  className="product-input"
                  placeholder="Enter Requirements"
                  value={new_crop[alignment]?.fertilization}
                  onChange={(e) => handleInput(1, "fertilization", e.target.value)}
                />
              </div> */}
          </Box>
          {/* <Box className='flex-div'sx={{display:"flex",flexDirection:"column",my:4,py:3}} >
        <label>Necessary Equipments</label>
            <select className='product-select1' >
            <option>Farm Equipments</option>
            <option>Farm Equipments</option>
            <option>Farm Equipments</option>
            </select>

    </Box> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "60%" }}>
          <Box className='flex-div' sx={{ display: "flex", flexDirection: "column" }}>
            <label>Description</label>
            {/* <textarea
                style={{ width: "25rem", height: "10rem" }}
                className='product-input'
                placeholder='Enter Title/Name'
                value={new_crop[alignment]?.description}
                onChange={(e) => handleInput(1, "description", e.target.value)}
              /> */}
            {displayDesc || id < 0 || isNew ? (
              <ReactQuill
                theme='snow'
                style={{ width: "100%", height: "35rem" }}
                // className='product-input'
                placeholder='Enter Title/Name'
                value={
                  new_crop?.[alignment]?.description ??
                  `<p>Sample Description of crop in language ${alignment}</p>`
                }
                onChange={(value) => handleInput(alignment, "description", value)}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30vh",
                }}>
                <Button
                  endIcon={<Visibility />}
                  onClick={() => setDisplayDesc((prev) => !prev)}
                  sx={{ textTransform: "none" }}>
                  {" "}
                  View Description
                </Button>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Crop_add;
