import React, { useContext, useEffect, useState } from "react";
import Plus from "../../assets/plus.svg";
import AllOrders from "../Dashboard/Layout/AllOrders";
// import Abandoned from "../Dashboard/Layout/Abandoned";
import Canceled from "./Layout/Canceled";
import OrderTable from "./Layout/OrderTable";
import axios from "axios";
import { apiInstace } from "../server";
import server from "../server";
import DashboardContext from "../Context/DashboardContext";
// import * as React from 'react';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// export default function BasicTabs() {

//   return (

//   );
// }
const Orders = () => {
  const [getOrder, setGetOrder] = useState([]);
  const [origetOrder, setOriGetOrder] = useState([]);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const dataOrders = async () => {
    // let orders=[];
    setLoading(true);
    const res = await apiInstace.get(`/orders/getorders`, requestOptions);
    // console.log(res.data);
    // console.log("dataOrder");
    console.log(res.data.results);
    setGetOrder([...res.data.results]);
    setOriGetOrder([...res.data.results]);
    setLoading(false);
  };

  useEffect(() => {
    dataOrders();
  }, []);

  useEffect(() => {
    // console.log(value);
    let orders = [];
    if (value === 0)
      orders = origetOrder.filter((item) => {
        return item.order.status == "PENDING";
      });
    else if (value === 1)
      orders = origetOrder.filter((item) => {
        return item.order.status == "IN_PROGESS";
      });
    else if (value === 2)
      orders = origetOrder.filter((item) => {
        return item.order.status == "REJECTED";
      });
    else if (value === 3)
      orders = origetOrder.filter((item) => {
        return item.order.status === "DELIVERED";
      });
    else {
      orders = origetOrder;
    }
    // console.log("filter order");
    // console.log(orders);
    setGetOrder([...orders]);
  }, [origetOrder, value]);

  return (
    <div>
      <div className='order-head'>
        <p className='order-title'>Orders Summary</p>
        {/* <button disabled style={{opacity:"0.6"}}>
          <img alt="plus" src={Plus} />
          Create a New Order
        </button> */}
      </div>
      <div className='order-grid'>
        <div className='allorders'>
          <AllOrders orders={origetOrder} />
        </div>
        <div className='canceled'>
          <Canceled orders={origetOrder} />
        </div>
        {/*} <div className="abandoned">
          <Abandoned />
        </div> */}
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            <Tab label='PENDING' {...a11yProps(0)} />
            <Tab label='IN PROGRESS' {...a11yProps(1)} />
            <Tab label='REJECTED' {...a11yProps(2)} />
            <Tab label='DELIVERED' {...a11yProps(3)} />
            <Tab label='ALL' {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <OrderTable getOrder={getOrder} functionchange={dataOrders} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <OrderTable getOrder={getOrder} functionchange={dataOrders} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <OrderTable getOrder={getOrder} functionchange={dataOrders} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <OrderTable getOrder={getOrder} functionchange={dataOrders} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <OrderTable getOrder={getOrder} functionchange={dataOrders} />
        </CustomTabPanel>
      </Box>
      {/* <div>
        <OrderTable getOrder={getOrder} />
      </div> */}
    </div>
  );
};

export default Orders;
