import React, { useState } from "react";
import BagBlack from "../../../assets/Bag-black.svg";

const Sales = (props) => {
  const orders = props.orders;
  let c = 0;
  let d = 0;
  orders?.map((item) => {
    c += item?.total_price;
    d++;
  });
  // setActive(c);
  return (
    <div className='abandoned-main'>
      <div className='abandoned-div'>
        <img alt='cart-img' src={BagBlack} className='cart-image' />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className='abandoned-body'>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Sales</p>
          <p className='cart-percentage'>{Math.ceil(c)}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Volume</p>
          <p className='cart-percentage'>{d}</p>
        </div>
        {/* <div className="abandoned-body-item">
          <p className="abandoned-body-title">Delivered</p>
          <p className="cart-percentage">
            {c}<span>+0.00%</span>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Sales;
