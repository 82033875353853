import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Input, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import Filter from "../../../assets/sort.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiInstace } from "../../server";
import DashboardContext from "../../Context/DashboardContext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "profit made",
    numeric: true,
    disablePadding: false,
    label: "Profit",
  },
  {
    id: "post shares",
    numeric: true,
    disablePadding: false,
    label: "Post shares",
  },
  {
    id: "sales made",
    numeric: true,
    disablePadding: false,
    label: "Sales made",
  },
  {
    id: "new joiners",
    numeric: true,
    disablePadding: false,
    label: "New joiners",
  },
  {
    id: "value",
    numeric: true,
    disablePadding: false,
    label: "Value Generated",
  },
  {
    id: "lastRevenue",
    numeric: true,
    disablePadding: false,
    label: "Last Revenue Date",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorder);
      const filteredRows = await props.orirows.filter((row) => {
        return (row.first_name + row.last_name)
          .toLowerCase()
          ?.includes(e.target.value.toLowerCase().replace(" ", ""));
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Summary
          </Typography>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                placeholder="Search"
                onChange={handlesearch}
              />
              {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
            </div>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DealerTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = React.useState([]);
  const [oriusers, setOriUsers] = React.useState([]);

  const [reload, setReload] = React.useState(false);
  const [userArr, setUserArr] = React.useState();
  const context = useContext(DashboardContext);
  const { setLoading } = context;

  const getAllUsers = async () => {
    try {
      setLoading(true);
      let user_info = [];

      const userData = await apiInstace.get(`/users/getUsers`);
      // console.log(userData.data);
      let arr = await Promise.all(
        userData.data.results.map(async (user) => {
          const userResponse = await apiInstace.get(
            `/users/getUserNetwork/${user.id}`
          );
          user = { ...user, network: userResponse.data };
          user_info.push(user);
          return user;
        })
      );

      // const userResults = await Promise.all(userPromises);
      // console.log(user_info);
      setUsers(arr);
      setOriUsers(arr);
      // console.log(user_info);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // useEffect(()=>{
  //   console.log(users);
  // },[users])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  React.useEffect(() => {
    getAllUsers();
  }, []);
  const [address, setaddress] = useState([]);
  useEffect(() => {
    apiInstace
      .get(`/address/getAddress`)
      .then((res) => {
        setaddress(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const GetAddress = ({ id }) => {
    const rowaddress = address.filter((item) => {
      return item.id == id;
    });
    // console.log(rowaddress);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <p>{rowaddress[0]?.city}</p> */}
        <p>{rowaddress[0]?.state}</p>
        <p>{rowaddress[0]?.city}</p>
      </div>
    );
  };


  useEffect(() => {
    console.log({ "users " : users})
  }, [users])
  
  return (
    <div className="order-table-main">
      <Box sx={{ width: "100%", borderRadius: 10 }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            row={users}
            setRows={setUsers}
            orirows={oriusers}
          />
          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {/* in all rows i have(arun) changed from */}
                {/* row?.network.reviews.no_of etc to row.no_of */}
                {(rowsPerPage > 0
                  ? users?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : users
                )?.map((row, index) => {
                  // console.log(row)
                  const isItemSelected = isSelected(row.first_name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // console.log(row);
                  return (

                    row.is_superuser ? <></> : <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row?.first_name)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index + 1}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      style={{ border: "1px solid transparent" }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ border: "1px solid transparent" }}
                      >
                        {/* <Link
                        to="/viewusernetwork"
                        style={{ textDecoration: "none" }}
                        state={{data:row}}
                      > */}
                        {row.first_name + " " + row.last_name}
                        {/* </Link> */}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {/* {row.address[0]?.address} */}
                        <GetAddress id={row.address[0]} />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.phone}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row?.profits_made}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row?.no_of_post_shares}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row?.no_of_sales_made}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row?.no_of_new_joiners}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.balance_amount}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.lastRevenue}
                      </TableCell>
                      <TableCell style={{ border: "1px solid transparent" }}>
                        {row?.network?.status ? (
                          <p className="status-active">Active</p>
                        ) : (
                          <p className="status-stopped">Stopped</p>
                        )}
                      </TableCell>
                    </TableRow>


                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default DealerTable;
