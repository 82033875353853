import React, { useEffect, useState } from "react";



import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, format , subWeeks, subMonths, subYears} from 'date-fns';

export default function ModalType(props) {
  const [checked, setChecked] = useState(false)
  const [checked1, setChecked1] = useState(false)
  const [minamt, setMinamt] = useState(0)
  const [maxamt, setMaxamt] = useState(10000000)
  const handlecheck = () => {

    setChecked(!checked);
    setChecked1(false);
    // console.log(checked,checked1);
  }
  const handlecheck1 = () => {
    setChecked1(!checked1);
    setChecked(false);
  }
  useEffect(() => {
    let filteredRows = [];
    // console.log(checked);
    if (checked) {
      filteredRows = props.oriorderData?.filter((row) => {
        // console.log(row.product?.shipping_type);
        return row.product?.shipping_type?.toLowerCase() == ("SELF_SHIPPING".toLowerCase());
      })

    }
    if (checked1) {
      filteredRows = props.oriorderData?.filter((row) => {
        // console.log(row.product?.shipping_type);
        return row.product?.shipping_type?.toLowerCase() == ("KAMOKISHAN".toLowerCase());
      })

    }
    // console.log(filteredRows,props.orderData,props.oriorderData);
    if (filteredRows) props.setOrderData(filteredRows)
    else props.setOrderData(props.oriorderData)

  }, [checked, checked1])


  // const handlestatus=(e)=>{
  //   let filteredRows=[];
  //   console.log(props.orderData);
  //   if(props.orderData.length)
  //  { console.log(1);
  //   filteredRows = props.orderData?.filter((row) => {
  //     console.log(row.order?.status,e.target.value);
  //     return row.order?.status.toLowerCase().includes(e.target.value.toLowerCase());
  //   });
  // }
  //   else {
  //     console.log(props.oriorderData);      
  //     filteredRows = props.oriorderData?.filter((row) => {
  //       console.log(row.order?.status,e.target.value);
  //       return row.order?.status.toLowerCase().includes(e.target.value.toLowerCase());
  //     });

  //   }
  //   // setRows(filteredRows);
  //   // console.log(filteredRows);
  //   props.setOrderData(filteredRows)
  // }
  useEffect(() => {
    let filteredRows = [];
    // console.log(props.orderData);
    if (props.orderData.length) {
      filteredRows = props.orderData?.filter((row) => {
        // console.log(row.order?.status,e.target.value);
        return row.price >= minamt && row.price <= maxamt
      });
    }
    else {
      // console.log(props.oriorderData);      
      filteredRows = props.oriorderData?.filter((row) => {
        // console.log(row.order?.status,e.target.value);
        return row.price >= minamt && row.price <= maxamt
      });

    }
    // setRows(filteredRows);
    // console.log(filteredRows);
    props.setOrderData(filteredRows)
  }, [minamt, maxamt])

  return (
    <div>
      {props.filterType ? (
        <div className="filter-modal-main">
          <div className="filter-modal">
            <p className="modal-filter-title">Filter</p>
            <p className="filter-order-type">Order Type</p>
            <div className="checkbox-main">
              <div className="checkbox-div">
                <input checked={checked} onChange={handlecheck} type="checkbox" />
                <p className="filter-label" >Self-Delivery</p>
              </div>
              <div className="checkbox-div">
                <input checked={checked1} onChange={handlecheck1} type="checkbox" />
                <p className="filter-label" >Kamokishan</p>
              </div>
            </div>
            {/* <p className="filter-status">Status</p>
            <select className="filter-select" onChange={handlestatus}>
              <option value="Delivered">Delivered</option>
              <option value="In-progress">In-progress</option>
              <option value="Pending">Pending</option>



            </select> */}

            <p className="filter-status">Customer</p>
            <select className="filter-select">
              <option>All</option>
            </select>

            <p className="filter-status">Amount</p>
            <div className="filter-amount">
              <input
                className="filter-select filter-input1"
                placeholder="0.00"
                value={minamt}
                onChange={(e) => setMinamt(e.target.value)}

              />
              <p className="form-to">From</p>
              <input
                className="filter-select filter-input2"
                placeholder="0.00"
                value={maxamt}
                onChange={(e) => setMaxamt(e.target.value)}

              />
              <p className="form-to">To</p>
            </div>
            <button className="filter-modal-button">Filter</button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export function ModalFilterByDate({ filterModalByDate , handleDuration }) {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const handleRadioChange = (duration) => {
    const today = new Date();

    switch (duration) {
      case 'thisWeek':
        setStartDate(startOfWeek(today));
        setEndDate(endOfWeek(today));
        break;
      case 'thisMonth':
        setStartDate(startOfMonth(today));
        setEndDate(endOfMonth(today));
        break;
      case 'thisYear':
        setStartDate(startOfYear(today));
        setEndDate(endOfYear(today));
        break;
      case 'lastWeek':
        setStartDate(subWeeks(startOfWeek(today), 1));
        setEndDate(subWeeks(endOfWeek(today), 1));
        break;
      case 'lastMonth':
        setStartDate(subMonths(startOfMonth(today), 1));
        setEndDate(subMonths(endOfMonth(today), 1));
        break;
      case 'lastYear':
        setStartDate(subYears(startOfYear(today), 1));
        setEndDate(subYears(endOfYear(today), 1));
        break;
      default:
        setStartDate(null);
        setEndDate(null);
        break;
    }
  };


  const submitDates = (e)=>{
    e.preventDefault()
      if(startDate == null || endDate == null){
        alert("Please select valid duration")
      }
      else handleDuration(startDate , endDate)
  }



  return (
    <div>
      {filterModalByDate ? (
        <div className="filter-modal-main">
          <div className="filter-modal">
            <p className="modal-filter-title">By Date</p>
            {/* <p className="filter-order-type">Order Type</p> */}
            <div className="checkbox-main-filter">
              <div className="checkbox-div-filter">
                <input type="radio" name="duration" onClick={() => handleRadioChange('thisWeek')} />
                <p className="filter-label">This Week</p>
              </div>
              <div className="checkbox-div-filter">
                <input type="radio" name="duration" onClick={() => handleRadioChange('lastWeek')}/>
                <p className="filter-label">Last Week</p>
              </div>
            </div>
            <div className="checkbox-main-filter">
              <div className="checkbox-div-filter">
                <input type="radio" name="duration" onClick={() => handleRadioChange('thisMonth')} />
                <p className="filter-label">This Month</p>
              </div>
              <div className="checkbox-div-filter">
                <input type="radio" name="duration" onClick={() => handleRadioChange('lastMonth')}/>
                <p className="filter-label">Last Month</p>
              </div>
            </div>
            <div className="checkbox-main-filter bottom-border">
              <div className="checkbox-div-filter">
                <input type="radio" name="duration" onClick={() => handleRadioChange('thisYear')} />
                <p className="filter-label">This Year</p>
              </div>
              <div className="checkbox-div-filter">
                <input type="radio" name="duration" onClick={() => handleRadioChange('lastYear')}/>
                <p className="filter-label">Last Year</p>
              </div>
            </div>
            <div className="checkbox-div-filter">
              <input type="radio" name="duration" onClick={ () => {
                setStartDate(null)
                setEndDate(null)
              }}/>
              <p className="filter-label">Date Range</p>
            </div>
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />

            <button className="filter-modal-button" onClick = {(e) => submitDates(e)}>Filter</button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
