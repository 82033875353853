import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import ProdcutImg from "../../../assets/productImg.svg";
import { Link } from "react-router-dom";
import Plus from "../../../assets/plus.svg";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
import { apiInstace } from "../../server";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import DashboardContext from "../../Context/DashboardContext";
import EditModal from "./EditModal";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// function createData(name, brandLogo, location, postType, title, link, edit) {
//   return {
//     name,
//     brandLogo,
//     location,
//     postType,
//     title,
//     link,
//     edit,
//   };

// const rows = [
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Video",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Video",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Post",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Video",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),

//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Post",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Post",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Post",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "Video",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "$2000.00",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "$2000.00",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "$2000.00",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "$2000.00",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
//   createData(
//     "Farmore",
//     "Farmore_logo.png",
//     "Pune, Maharashtra",
//     "$2000.00",
//     "How to use Swasti Auto...",
//     "Dnjwcfncofn./link",
//     "Edit"
//   ),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "likes",
    numeric: true,
    disablePadding: true,
    label: "Likes",
  },
  {
    id: "shares",
    numeric: true,
    disablePadding: true,
    label: "Shares",
  },
  {
    id: "product",
    numeric: true,
    disablePadding: true,
    label: "Product",
  },

  {
    id: "link",
    numeric: false,
    disablePadding: true,
    label: "Video/Image URL",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Created Date",
  },
  {
    id: "featured",
    numeric: true,
    disablePadding: true,
    label: "Featured",
  },

  //   {
  //     id: "edit",
  //     numeric: true,
  //     disablePadding: false,
  //     label: " ",
  //   },
];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ p: 2 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const [checked, setChecked] = React.useState(false);
  // const [checkVideo, setCheckVideo] = React.useState(false);
  const context = useContext(DashboardContext);
  const { setLoading } = context;

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  //   setPost_data({ ...post_data, content_type: "POST" });
  //   setCheckVideo(false);
  // };

  // const handleChangeVideo = (event) => {
  //   setCheckVideo(event.target.checked);
  //   setPost_data({ ...post_data, content_type: "VIDEO" });
  //   setChecked(false);
  // };
  // const [personName, setPersonName] = React.useState([]);
  // const [cropsid, setCropsid] = useState([])
  // const [post_data, setPost_data] = useState({
  //   title: "",
  //   description: "",
  //   content_type: "",
  //   location: "",
  //   share_link: "https://www.youtube.com/",
  //   video: "https://www.youtube.com/",
  //   tags: [],
  //   likes: 0,
  //   dislikes: 0,
  //   visibility: "PUBLIC",
  //   brand_id: 0,
  //   user: localStorage.getItem("id"),
  //   crops: [],
  // });
  // const handleInput = (key, value) => {
  //   setPost_data({ ...post_data, [key]: value });
  // };
  // const [brands, setBrands] = useState([]);
  // const [crops, setCrops] = useState([]);
  // const [tags, setTags] = useState([]);
  // // var brands=[];
  // // var crops=[];

  // useEffect(() => {
  //   const requestOptions = {
  //     headers: {
  //       method: "GET",
  //       Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3OTUzMDI5LCJpYXQiOjE2ODc4ODc5NjYsImp0aSI6ImUxYmY4NTU3NTM1MDQyM2M4YWVmMWI0ZjI0MTJlZGM1IiwidXNlcl9pZCI6OTB9.6wXrSPDLVIa_wI0UjjMVJgT2_zOffPhsomEGCLBgfMk`,
  //     },
  //   };
  //   axios
  //     .get(`/crops/getcrops`, requestOptions)
  //     .then((res) => {
  //       const crops_data = res.data.results;
  //       var crop_ids = [];
  //       crops_data.map((item) => {
  //         // console.log(item.id);
  //         // setCrops([...crops,item.id]);
  //         crop_ids.push({"id":item.id,"name":item.name});
  //         // console.log(crops);
  //       });
  //       setCrops(...crops, crop_ids);
  //     })
  //     .catch((err) => console.log(err));

  //   axios
  //     .get(`/brands/getbrands`, requestOptions)
  //     .then((res) => {
  //       const brands_data = res.data.results;
  //       // console.log(brands_data);
  //       var brand_ids = [];
  //       brands_data.map((item, i) => {
  //         // console.log(item.id);
  //         // setBrands([...brands,item.id]);
  //         brand_ids.push(item.id);
  //         // console.log(brands)
  //       });
  //       setBrands(...brands, brand_ids);
  //     })
  //     .catch((err) => console.log(err));

  //   axios
  //     .get(`/tags/getTags`, requestOptions)
  //     .then((res) => {
  //       const tags_data = res.data.results;
  //       // console.log(brands_data);

  //       setTags(...tags, tags_data);
  //     })
  //     .catch((err) => console.log(err));

  //   // console.log(brands);

  //   // console.log("hi");
  // }, []);

  // const handlesubmit = () => {
  //   console.log(post_data);
  //   const token = `Bearer ${localStorage.getItem("access")}`;
  //   axios
  //     .post(`/posts/createPost`, { post_data, token })
  //     .then((res) => {console.log(res); props.handleClose();})
  //     .catch((err) => console.log(err));
  // };
  // const theme = useTheme();

  // const handleChange2 = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   // console.log(event.target);
  //   setPersonName(typeof value === "string" ? value.split(",") : value);

  // };
  // const handleChange3 = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   // console.log(event.target);
  //   setCropsid(typeof value === "string" ? value.split(",") : value);

  // };
  // useEffect(()=>{
  //   // console.log(personName);
  //   handleInput("tags",personName);
  // },[personName])
  // useEffect(()=>{
  //   // console.log(personName);
  //   handleInput("crops",cropsid);
  // },[cropsid])

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 550,
  //   bgcolor: "#ffffff",
  //   border: "none",
  //   borderRadius: "12px",
  //   boxShadow: 24,
  //   backdropfilter: "blur(4px)",
  //   height: "80vh",
  // };

  const mainBody = {
    p: 2,
  };

  // const handlePost = () => {
  //   setLoading(true);
  //   axios
  //     .post(
  //       `/post/addpost`,
  //       { ...krishiData, user: myData.id },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("access")}`,
  //         },
  //       }
  //     )
  //     .then((result) => {
  //       toast.success("Product Uploaded!", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       setLoading(true);
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong!", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       setLoading(false);
  //     });
  // };

  // const [myData, setMyData] = React.useState();

  // const getMyData = () => {
  //   axios
  //     .get(`/user/mydetails`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       },
  //     })
  //     .then((result) => {
  //       console.log(result.data.user);
  //       setMyData(result.data.user);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const [krishiData, setKrishiData] = React.useState({
  //   title: "",
  //   desciption: "testing",
  //   likes: 0,
  //   dislikes: 0,
  //   share: 0,
  //   visibility: "PUBLIC",
  //   crops: [],
  //   tags: 1,
  //   status: "ACTIVE",
  //   brand_id: 1,
  //   share_link: "",
  //   location: "",
  //   content_type: checkVideo ? "VIDEO" : "POST",
  //   user: myData?.id,
  // });

  // const handleCropFilter = (selectedList, selectedValue, isRemove) => {
  //   if (!isRemove) {
  //     let arr = [...krishiData.crops];
  //     arr.push(selectedValue.id);
  //     setKrishiData({
  //       ...krishiData,
  //       crops: arr,
  //     });
  //   } else {
  //     let arr = [...krishiData.crops];
  //     const newArr = arr.filter((item) => item !== selectedValue.id);
  //     console.log(newArr);
  //     setKrishiData({
  //       ...krishiData,
  //       crops: newArr,
  //     });
  //   }
  // };

  // const handleInput = (field, value) => {
  //   setKrishiData({
  //     ...krishiData,
  //     [field]: value,
  //   });
  // };

  // React.useEffect(() => {
  //   getMyData();
  //   getCrops();
  // }, []);
  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorder);
      const filteredRows = await props.orirows.filter((row) => {
        const prodName = props.products?.find(({ id }) => id == row.product_id)?.name;
        return prodName.toLowerCase()?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Box>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className='krishi-video-title'>
        <div>
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color='inherit'
              variant='subtitle1'
              component='div'>
              {numSelected} selected
            </Typography>
          ) : (
            <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
              Krishi Videos / Posts
            </Typography>
          )}
        </div>

        <div>
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  {/* <button
                    className="create-new-post-button"
                    onClick={() => props.handleOpen1()}
                    style={{ marginLeft: "10px" }}
                  >
                    <img className="create-post-plus" alt="plus" src={Plus} />
                    Create New Tag
                  </button> */}
                  <button
                    className='create-new-post-button'
                    onClick={() => props.handleOpen(-1, undefined)}
                    style={{ marginLeft: "10px" }}>
                    <img className='create-post-plus' alt='plus' src={Plus} />
                    Create New Post
                  </button>
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      </Toolbar>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}>
        <div>
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color='inherit'
              variant='subtitle1'
              component='div'>
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              sx={{
                flex: "1 1 100%",
                fontSize: 17,
                fontWeight: 500,
              }}
              id='tableTitle'
              component='div'>
              All Posts
            </Typography>
          )}
        </div>

        <div>
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip>
              <div style={{ display: "flex" }}>
                <TextField
                  style={{
                    width: "300px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  placeholder='Search by Product Name'
                  onChange={handlesearch}
                />
                <div style={{ display: "flex" }}></div>
              </div>
            </Tooltip>
          )}
        </div>
      </Toolbar>

      {/* <KrishiVideoTable /> */}
    </Box>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const KrishiVideoTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [orirows, setOriRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [sendData, setSendData] = useState();
  const context = useContext(DashboardContext);
  // const {loading}=context;
  // import Modal from '@mui/material/Modal';

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const { loading, setLoading, refresh, products, getProducts } = context;

  //  const [open, setOpen] = React.useState(false);
  const [id, setID] = useState();
  // const handleOpen = () => {
  //   setOpen(true)
  // };
  // const handleClose = () => setOpen(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // const token=`Bearer ${localStorage.getItem("access")}`;
  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    apiInstace
      .get(`/posts/getPosts`, requestOptions)
      .then((res) => {
        // console.log(res.data);
        setRows(...rows, res?.data?.posts ?? []);
        setOriRows(res.data.posts);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  // console.log(rows);

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  // useEffect(()=>{
  // var visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  // const [postData, setPostData] = React.useState();

  // const getData = () => {
  //   setLoading(true);
  //   axios
  //     .get(`/post/getpost`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       },
  //     })
  //     .then((result) => {
  //       setPostData(result.data.results);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // };

  // React.useEffect(() => {
  //   getData();
  // }, [refresh]);
  const [tag, setTag] = useState("");
  const handlesubmit = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        //   method: "DELETE",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    const tag_data = { name: tag };

    apiInstace
      .post(`/tags/createTag`, { tag_data }, requestOptions)
      .then((res) => {
        console.log(res);
        setLoading(false);
        toast.success("Tag Created!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleClose1();
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        console.log(err);
      });
  };
  const handleDelete = (orderid) => {
    if (orderid >= 0) {
      console.log(orderid);

      const requestOptions = {
        headers: {
          method: "GET",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      apiInstace

        .delete(`/posts/deletePost/${orderid}/`, requestOptions)
        .then((res) => {
          console.log(res);
          toast.success("Post Deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false)
          handleClose2();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [open2, setOpen2] = React.useState(false);
  const [postid, setPostId] = useState(-1);
  const handleOpen2 = (id) => {
    setPostId(id);
    setOpen2(true);
  };
  const handleClose2 = () => setOpen2(false);

  const updateStatus = (id, state) => {
    apiInstace
      .patch(`posts/updatePost/${id}/`, {
        post_update: { is_featured: state },
        headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    getProducts();
  }, []);
  //   console.log("prod1", products, products?.find(({ id }) => id == 1)?.name);

  //   useEffect(() => {
  //     // console.log("rows", typeof rows, rows);
  //   }, [rows]);
  return (
    <Box>
      <Box sx={{ width: "100%", borderRadius: 10 }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            open={props.open}
            handleOpen={props.handleOpen}
            handleClose={props.handleClose}
            handleOpen1={handleOpen1}
            rows={rows}
            setRows={setRows}
            orirows={orirows}
            products={products}
          />
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Are you Sure, You want to Delete?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={() => handleDelete(postid)}>
                  Delete
                </button>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={handleClose2}>
                  Cancel
                </button>
              </div>
            </Box>
          </Modal>
          <div className='filter-div'></div>
          {/* <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="modal-head krishi-modal-head">
                  <p className="modal-title">Create New Tag</p>
                  <button
                    className="close-button-krishi"
                    onClick={handleClose1}
                  >
                    X
                  </button>
                </div>
                <p className="Customer-info">Tag Name</p>
                <input
                  placeholder="Enter Tag Name"
                  className="product-input"
                  style={{ width: "350px", margin: "5px 0px" }}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />

                <button
                  className="upload-image-button"
                  style={{ width: "15rem" }}
                  onClick={handlesubmit}
                >
                  Save & Publish
                </button>
              </Box>
            </Modal> */}
          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                id={id}
              />
              <TableBody>
                {/* {console.log(rows)} */}
                {(rowsPerPage > 0 && ![{}, undefined, null].includes(rows)
                  ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows ?? []
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer", px: 1 }}
                      style={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                      <TableCell
                        // component='th'
                        id={labelId}
                        scope='row'>
                        {row?.title}
                      </TableCell>
                      <TableCell align='left'>
                        {/* <Link
                            to="/viewproduct"
                            state={{isEdit: false, data: }}
                            style={{ textDecoration: "none" }}
                          >
                            <p className="bg-krishi-table">{row.likes}</p>
                          </Link>
                            
                          > */}
                        <p className='bg-krishi-table'>{row?.likes}</p>
                        {/* </Link> */}
                      </TableCell>
                      <TableCell align='left'>{row?.shares}</TableCell>
                      {/* <TableCell
                          align="left"
                          
                        >
                          <p className="bg-krishi-table">{row.content_type}</p>
                        </TableCell> */}
                      <TableCell
                        align='left'
                        sx={{ textOverflow: "ellipsis", maxHeight: "16px", overflow: "hidden" }}>
                        {/* <p className='bg-krishi-table'> */}
                        {
                          // row.product_id +"-" +
                          products?.find(({ id }) => id == row.product_id)?.name
                        }
                        {/* </p> */}
                      </TableCell>

                      {/* <TableCell align='left' >
                          {row.visibility}
                        </TableCell> */}
                      <TableCell align='left'>
                        <p className='bg-krishi-table'>
                          <Link to={row.video}>{row.video}</Link>
                        </p>
                      </TableCell>
                      <TableCell align='left'>
                        {row.created_at}
                        {/* <button className='edit-button'></button> */}
                      </TableCell>
                      <TableCell
                        style={{
                          //   display: "flex",
                          //   flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "stretch",
                          //   border: "1px solid transparent",
                          justifySelf: "flex-end",
                        }}>
                        <label class='switch'>
                          <input
                            type='checkbox'
                            defaultChecked={row?.is_featured}
                            onClick={(e) => {
                              updateStatus(row.id, e.target.checked);
                            }}
                          />
                          <span class='slider round'></span>
                        </label>
                      </TableCell>
                      <TableCell align='left'>
                        <button
                          className='edit-button'
                          onClick={() => props.handleOpen(row.id, row)}>
                          Edit
                        </button>
                      </TableCell>

                      <TableCell>
                        <DeleteIcon onClick={() => handleOpen2(row.id)} />
                      </TableCell>
                      <EditModal
                        open={open}
                        handleClose={() => setOpen(false)}
                        data={sendData}
                        setData={setSendData}
                      />
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default KrishiVideoTable;
