import React from "react";
import { useLocation } from "react-router-dom";
import CustomerDetail from "../Customers/Layout/CustomerDetail";
import CustomerAddress from "../Customers/Layout/CustomerAddress";
import TotalOrder from "../Inventory/Layout/TotalOrder";
import AllOrders from "../Dashboard/Layout/AllOrders";
import Canceled from "../Orders/Layout/Canceled";
import Abandoned from "../Dashboard/Layout/Abandoned";
// import CustomerViewTable from "../Customers/Layout/CustomerViewTable";
import DealerTable from "./Layout/DealerTable";
const ViewUserNetwork = () => {
  const location=useLocation();
  const data=location.state.data;
  console.log(data);

  return (
    <div>
      <div>
        <div className="customer-order-main-div">
          <div className="customer-detail-card">
            <CustomerDetail data={data} />
          </div>
          <div className="customer-address-card">
            <CustomerAddress data={data} />
          </div>
          {/* <div className="cutomer-order-card">
            <TotalOrder da />
          </div> */}
        </div>
        <div className="customer-order-main-div">
          {/* <div className="customer-detail-card">
            <AllOrders />
          </div> */}
        </div>
       <DealerTable />

      </div>
    </div>
  );
};

export default ViewUserNetwork;
