import { toast } from "react-toastify";

export async function copyTextToClipboard(text) {
  toast.info("Text copied to clipboard successfully!", { autoClose: 2000 });
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
