import React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import ProdcutImg from "../../../assets/productImg.svg";
import { Link } from "react-router-dom";

function createData(orderDate, OrderType, unitPrice, quantity, discount, totalValue, status) {
	return {
		orderDate,
		OrderType,
		unitPrice,
		quantity,
		discount,
		totalValue,
		status,
	};
}

const rows = [
	createData(
		"12 Aug 2022 - 12:25 am",
		"Self-Delivery",
		"$2000.00",
		"2",
		"50%",
		"$3000",
		"completed"
	),
	createData("12 Aug 2022 - 12:25 am", "KamaoKisan", "$2000.00", "4", "30%", "$3000", "pending"),
	createData(
		"12 Aug 2022 - 12:25 am",
		"KamaoKisan",
		"$2000.00",
		"5",
		"40%",
		"$3000",
		"inprogress"
	),
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "OrderDate",
		numeric: false,
		disablePadding: true,
		label: "Order Date",
	},
	{
		id: "OrderType",
		numeric: true,
		disablePadding: false,
		label: "Order Type",
	},

	{
		id: "unitPrice",
		numeric: true,
		disablePadding: false,
		label: "Unit Price",
	},
	{
		id: "quantity",
		numeric: true,
		disablePadding: false,
		label: "Qty",
	},
	{
		id: "discount",
		numeric: true,
		disablePadding: false,
		label: "Discount",
	},
	{
		id: "totalValue",
		numeric: true,
		disablePadding: false,
		label: "Total Order",
	},
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "Status",
	},
	//   {
	//     id: "edit",
	//     numeric: true,
	//     disablePadding: false,
	//     label: " ",
	//   },
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "left" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}>
			<div>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: "1 1 100%" }}
						color="inherit"
						variant="subtitle1"
						component="div">
						{numSelected} selected
					</Typography>
				) : (
					<Typography
						sx={{ flex: "1 1 100%" }}
						variant="h6"
						id="tableTitle"
						component="div">
						Purchases
					</Typography>
				)}
			</div>

			<div>
				{numSelected > 0 ? (
					<Tooltip title="Delete">
						<IconButton>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip>
						<div style={{ display: "flex", alignItems: "center" }}>
							<TextField
								style={{
									width: "240px",
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment>
											<IconButton>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
								size="small"
								placeholder="Search"
							/>
							<div style={{ display: "flex" }}>
								<div className="table-head-buttons">
									<img alt="img" src={Vector} />
									<p className="filter-text">Filter</p>
								</div>
								<div className="table-head-buttons">
									<img alt="img" src={Calender} />
									<p className="filter-text">Filter</p>
								</div>
								<div className="table-head-buttons">
									<img alt="img" src={Send} />
									<p className="filter-text">Share</p>
								</div>
								{/* <select className="table-head-buttons">
									<option>Bulk Action</option>
								</select> */}
							</div>
						</div>
					</Tooltip>
				)}
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const ViewOrderTable = () => {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = React.useMemo(
		() =>
			stableSort(rows, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage]
	);
	return (
		<div className="order-table-main">
			<Box sx={{ width: "100%" }}>
				<Paper sx={{ width: "100%", mb: 2 }}>
					<EnhancedTableToolbar numSelected={selected.length} />
					<TableContainer style={{ padding: "0px 20px" }}>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={dense ? "small" : "medium"}>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{visibleRows.map((row, index) => {
									const isItemSelected = isSelected(row.name);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											// onClick={(event) => handleClick(event, row.name)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.name}
											selected={isItemSelected}
											sx={{ cursor: "pointer" }}
											style={{ border: "1px solid transparent" }}>
											<TableCell
												align="left"
												style={{ border: "1px solid transparent" }}>
												{row.orderDate}
											</TableCell>
											<TableCell
												align="left"
												style={{ border: "1px solid transparent" }}>
												<Link
													to="/viewproduct"
													style={{ textDecoration: "none" }}>
													{row.OrderType}
												</Link>
											</TableCell>

											<TableCell
												align="left"
												style={{ border: "1px solid transparent" }}>
												{row.unitPrice}
											</TableCell>
											<TableCell
												align="left"
												style={{ border: "1px solid transparent" }}>
												{row.quantity}
											</TableCell>
											<TableCell
												align="left"
												style={{ border: "1px solid transparent" }}>
												{row.discount}
											</TableCell>
											<TableCell
												align="left"
												style={{ border: "1px solid transparent" }}>
												{row.totalValue}
											</TableCell>
											<TableCell style={{ border: "1px solid transparent" }}>
												{row.status === "completed" ? (
													<p className="status-complete">Delivered</p>
												) : row.status === "inprogress" ? (
													<p className="status-progress">In-Progress</p>
												) : (
													<p className="status-pending">Pending</p>
												)}
											</TableCell>
										</TableRow>
									);
								})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: (dense ? 33 : 53) * emptyRows,
										}}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Box>
		</div>
	);
};

export default ViewOrderTable;
