import React from "react";
import Location from "../../../assets/Location.svg";

const CustomerAddress = (props) => {
  const data=props.data;
  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={Location} className="profile-bg" />
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="total-order-text">Home Address</p>
          <p className="address-text">
        {data.address[0]?    (
          <>
           {data.address[0]?.address}, {data.address[0]?.city}, <br></br>{data.address[0]?.city}
            </>
         )
          :(
            <>--- , --- ,<br></br>---</>
          )}
          </p>
        </div>
        <div className="abandoned-body-item">
          <p className="total-order-text">Billing Address</p>
          <p className="address-text">
          {data.address[0]?    (
          <>
           {data.address[0]?.address}, {data.address[0]?.city}, <br></br>{data.address[0]?.city}
            </>
         )
          :(
            <>--- , --- ,<br></br>---</>
          )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerAddress;
