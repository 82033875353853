import React from "react";

const PublishedDetails = ({ productItem }) => {
  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <p className="last-order-date">
          Last Order <span>12 Sept 2022</span>
        </p>
        <p className="published-text">Published</p>
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="publish-text">Price</p>
          <p className="publish-price">₹{productItem?.base_price}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="publish-text">In-Stock</p>
          <p className="publish-price">20</p>
        </div>
      </div>
    </div>
  );
};

export default PublishedDetails;
