import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiInstace } from "../../server";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@mui/material";
import { Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Plus from "../../../assets/plus.svg";
import { Modal } from "@mui/material";
import { toast } from "react-toastify";
import EditCropModal from "./EditCropModal";

const headCells = [
  {
    id: "crop",
    numeric: false,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Icon",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "desc1",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "desc2",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorder);
      const filteredRows = await props.orirows.filter((row) => {
        return row.name.toLowerCase()?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
            Crops
          </Typography>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search Crop'
                onChange={handlesearch}
              />
              {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
            </div>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Crop_table({ rows, setRows, orirows, getCrop }) {
  // const [rows, setRows] = useState([])
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [active_users, setActive_users] = React.useState(0)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleDelete = (orderid) => {
    if (orderid >= 0) {
      console.log(orderid);

      const requestOptions = {
        headers: {
          method: "DELETE",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      apiInstace
        .delete(`/crops/deleteCrop/${orderid}/`, requestOptions)
        .then((res) => {
          console.log(res);
          toast.success("Crop Deleted! It may take few minutes to reflect the changes", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false)
          handleClose1();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [open1, setOpen1] = React.useState(false);
  const [cropid, setCropid] = useState(-1);
  const handleOpen1 = (id) => {
    setCropid(id);
    setOpen1(true);
  };
  const handleClose1 = () => setOpen1(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [editItem, setEditItem] = useState({});
  return (
    <div className='order-table-main'>
      <EditCropModal
        open1={openEdit}
        setOpen1={setOpenEdit}
        data={editItem}
        id={cropid}
        getCrop={getCrop}
      />
      <Box sx={{ width: "100%" }}>
        <div className='order-head'>
          <p className='order-title' style={{ marginTop: "10px", marginLeft: "10px" }}>
            All Crops
          </p>
          <Link to='/crop_add' state={{ id: -1 }}>
            <button style={{ marginTop: "10px", marginRight: "10px" }}>
              <img alt='plus' src={Plus} />
              Add a New Crop
            </button>
          </Link>
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Are you Sure, You want to Delete?
              </Typography>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={() => handleDelete(cropid)}>
                  Delete
                </button>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={handleClose1}>
                  Cancel
                </button>
              </div>
            </Box>
          </Modal>
          {/* <button
                    className="create-new-post-button"
                    
                    // onClick={()=>props.handleOpen(-1)}
                  >
                    <img className="create-post-plus" alt="plus" src={Plus} />
                    Create New Crop
                  </button> */}
          {/* <div> */}

          <EnhancedTableToolbar
            numSelected={selected.length}
            rows={rows}
            setRows={setRows}
            orirows={orirows}
          />
          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows
                )?.map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // console.log(row)
                  // if(row?.is_active=="true")
                  // props.setActive_users(props.active_users+1);

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role='checkbox'
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}>
                      <TableCell component='th' id={labelId} scope='row' padding='none'>
                        {row.name}
                      </TableCell>
                      <TableCell align='left'>
                        <img src={row.image} style={{ width: "4rem", height: "4rem" }} />
                      </TableCell>
                      <TableCell align='left'>
                        <img src={row.icon} style={{ width: "4rem", height: "4rem" }} />
                      </TableCell>

                      <TableCell align='left'>{row.role}</TableCell>

                      <TableCell padding='none' align='center'>
                        <Button
                          onClick={() => {
                            setEditItem(row);
                            setCropid(row.id);
                            setOpenEdit(true);
                          }}
                          variant='outlined'
                          sx={{ textTransform: "none" }}>
                          Edit
                        </Button>
                      </TableCell>

                      <TableCell padding='none' align='center'>
                        <Link to='/crop_add' state={{ id: row.id }}>
                          <button className='edit-button'>Edit Description</button>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <DeleteIcon onClick={() => handleOpen1(row.id)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
}

export default Crop_table;
