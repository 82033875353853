import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Tooltip,
  LinearScale,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, CategoryScale, Tooltip, LinearScale, Legend);

const Summary = ({ orders }) => {
  // console.log(orders);
  let month_order = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 };
  console.log({ orders });
  orders?.map((item) => {
    const dateObj = new Date(item.order.created_at);
    const month = dateObj.getMonth() + 1;

    month_order = { ...month_order, [month]: month_order[month] + item.order.total_cost };
  });
  // console.log(month_order);
  let arr = [];
  for (const key in month_order) arr.push(month_order[key]);
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Sales",
        data: arr,
        backgroundColor: ["rgb(85, 111, 241)"],
        hoverOffset: 1,
        // weight:0.3,
        barThickness: 20,
      },
    ],
  };
  var options = {
    // cutout: 150
    plugins: {
      legend: {
        position: "top", // lable position left/right/top/bottom
        labels: {
          boxWidth: 0, // lable box size
        },
      },
    },

    scales: {
      x: {
        // display: false,        // show/ hide x-axis
        grid: {
          display: false, // show/hide grid line in x-axis
        },
      },
      y: {
        // display: false,      // same as x-axis
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "30rem",
        height: "30rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default Summary;
