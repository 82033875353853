import React, { useState, useEffect, useContext } from "react";
import DealerTable from "./Layout/DealerTable";
import Plus from "../../assets/plus.svg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import { apiInstace } from "../server";
import axios from "axios";
import { toast } from "react-toastify";
import DashboardContext from "../Context/DashboardContext";
import { State } from "country-state-city";
import { City } from "../../utils/country-state-city";
import { Select, MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 2,
  backdropfilter: "blur(4px)",
};

const Settings = () => {
  const [open, setOpen] = React.useState(false);
  const [user_data, setUser_data] = useState({});
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [selectedState, setSelectedState] = useState("");

  const handleOpen = (data) => {
    setOpen(true);
    if (data) {
      setUser_data({ ...data });
    }
  };
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [cust_data, setCust_data] = useState({
    first_name: user_data?.first_name || "",
    last_name: user_data?.last_name || "",
    phone: user_data?.phone || "",
    role: user_data?.role || "BDO",
    bdo: user_data?.bdo || "",
  });
  const [role, setRole] = useState();
  const [bdos, setBdos] = useState([]);
  const [addressids, setAddressids] = useState([]);
  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3ODgyMjg0LCJpYXQiOjE2ODc0MzU0OTMsImp0aSI6IjgyNmIxMGVlNzc2YTQwNzA4MjE2NmMwMDIzYjQzMTQyIiwidXNlcl9pZCI6OTB9.lsoyuOy800HQAPW35BY7SN5HiX6wQREE4e8Y1XKcKCM`,
      },
    };
    apiInstace
      .get(`/users/getUsers`, requestOptions)
      .then((res) => {
        // console.log(res.data.users);

        let bdo = [];
        res.data.results.map((item) => {
          if (item.role == "BDO") bdo.push({ id: item.id, name: item.first_name });
        });
        // console.log(bdo);
        setBdos([...bdo]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (user_data?.address?.length != 0) {
      let address = [];
      user_data?.address?.map((item) => {
        address.push(item.id);
      });
      setAddressids(address);
    }
  }, [user_data]);
  const [add_data, setAdd_data] = useState({
    address: "",
    state: "",
    city: "",
  });
  const handleInput = (key, value) => {
    setCust_data({ ...cust_data, [key]: value });
  };
  const handleInput1 = (key, value) => {
    setAdd_data({ ...add_data, [key]: value });
  };
  const handleSubmit = () => {
    setLoading(true);
    const token = `Bearer ${localStorage.getItem("access")}`;
    // apiInstace.post(`/auth/user_register`,{cust_data,token})
    // .then((res)=>{
    //   console.log(res);
    //  console.log(add_data);
    // setAdd_data({...add_data,id:res.data.id})
    apiInstace
      .post(`/auth/address_register`, { add_data, token })
      .then((res) => {
        const data = { ...cust_data, address: [res.data.id] };
        apiInstace
          .post(`/auth/user_register`, { cust_data: data, token })
          .then((res1) => {
            console.log(res1);
            toast.success("User Added!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log(res);
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            console.log(err);
            setOpen(false);
          });
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setOpen(false);
        setLoading(false);
      });

    // })

    // console.log(cust_data);
  };
  const [fetchedCities, setFetchedCities] = useState([]);

  useEffect(() => {
    const cityUp = async () =>
      setFetchedCities((await City.getCitiesOfState("IN", selectedState?.isoCode)) ?? []);
    cityUp();
  }, [selectedState]);
  return (
    <div>
      <div className='order-head'>
        <p className='order-title'>Customers Summary</p>
        {/* <button onClick={handleOpen}>
          <img alt="plus" src={Plus} />
          Add a New User
        </button> */}
      </div>
      <div>
        <DealerTable />
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <div className='modal-head'>
              <p className='modal-title'>Add New User</p>
              <button className='close-button' onClick={handleClose}>
                X
              </button>
            </div>
            <p className='user-info'>First Name</p>
            <input
              placeholder='First Name'
              className='product-input user-name'
              style={{ width: "100%" }}
              onChange={(e) => handleInput("first_name", e.target.value)}
            />

            <p className='user-info'>Last Name</p>
            <input
              placeholder='Last Name'
              className='product-input user-name'
              style={{ width: "100%" }}
              onChange={(e) => handleInput("last_name", e.target.value)}
            />

            <p className='user-info'>Phone Number</p>
            <input
              placeholder='+919822221876'
              className='product-input'
              style={{ width: "100%" }}
              onChange={(e) => handleInput("phone", e.target.value)}
            />

            <p className='user-info'>Address</p>
            <input
              placeholder='Sinhagad Road'
              className='product-input location-input'
              style={{ width: "100%" }}
              onChange={(e) => handleInput1("address", e.target.value)}
            />

            <Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <FormControl sx={{ my: 1, width: 200 }}>
                  <InputLabel id='demo-name-label'>State</InputLabel>
                  <Select
                    // className="product-select1"
                    value={add_data?.state}
                    label='State'
                    onChange={(e) => {
                      handleInput1("state", e.target.value);
                    }}>
                    {State.getStatesOfCountry("IN").map((item) => {
                      return (
                        <MenuItem value={item.name} onClick={() => setSelectedState(item)}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ my: 1, width: 200 }}>
                  <InputLabel id='demo-name-label'>City</InputLabel>
                  <Select
                    //  className="product-select2"
                    value={add_data?.city}
                    label='City'
                    onChange={(e) => handleInput1("city", e.target.value)}>
                    {fetchedCities.map((item) => {
                      return <MenuItem value={item.name}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                {/* <p className="user-info">Role</p> */}
                <FormControl sx={{ my: 1, width: 200 }}>
                  <InputLabel id='demo-name-label'>Role</InputLabel>
                  <Select
                    // className="product-select1"
                    value={user_data?.role}
                    onChange={(e) => {
                      setRole(e.target.value);
                      handleInput("role", e.target.value);
                    }}>
                    <MenuItem value='BDO'>BDO</MenuItem>
                    <MenuItem value='DEALER'>Dealer</MenuItem>
                  </Select>
                </FormControl>
                {/* <p className="user-info">Under</p> */}
                <FormControl sx={{ my: 1, width: 200 }}>
                  <InputLabel id='demo-name-label'>Under</InputLabel>
                  <Select
                    // className="product-select2"
                    value={user_data?.bdo}
                    onChange={(e) => handleInput("bdo", e.target.value)}>
                    {role == "BDO" ? (
                      <MenuItem value='null'>None</MenuItem>
                    ) : (
                      bdos.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <div className='section-button-add' style={{ marginTop: "10px" }}>
              <button className='cancel-add-button' onClick={handleClose}>
                Cancel
              </button>
              <button className='add-add-button' onClick={handleSubmit}>
                Add
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Settings;
