import React, { useContext } from "react";
import CopyText from "../../assets/copy-text.svg";
import ProductImage from "./Layout/ProductImage";
import PublishedDetails from "./Layout/PublishedDetails";
import TotalOrder from "./Layout/TotalOrder";
import ViewsOrder from "./Layout/ViewsOrder";
import Canceled from "../Orders/Layout/Canceled";
import AllOrders from "../Dashboard/Layout/AllOrders";
import ViewOrderTable from "./Layout/ViewOrderTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardContext from "../Context/DashboardContext";
import { toast } from "react-toastify";
import axios from "axios";
import server from "../server";
import { apiInstace } from "../server";

const ViewProduct = ({ row }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const productItem = location?.state;

  const updateProduct = (status) => {
    apiInstace
      .patch(
        `/products/updateproducts/${productItem.id}`,
        {
          ...productItem,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((result) => {
        setLoading(false);
        toast.success("Product Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const state = { ...result.data };
        navigate("/viewproduct", { state });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // console.log(productItem);
  return (
    <div>
      <div className="newProduct-head">
        <div className="product-details">
          <p className="newProduct-title">{productItem?.name}</p>
          <p className="date-text">
            Date Added <span>12 Sept 2022 - 12:55 pm</span>
          </p>
          {/* <p className="product-url">
            Product URL <span>1nancystores.com/polot-shirt</span>
          </p> */}
          {/* <img alt="copy" src={CopyText} /> */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/addproduct" state={{ data: productItem, isEdit: true }}>
            <button className="save-select">Edit Product</button>
          </Link>
          {productItem.status === "PUBLISH" ? (
            <button
              className="unpublish-button"
              onClick={() => updateProduct("UNPUBLISH")}
            >
              Upublish Product
            </button>
          ) : (
            <button
              className="publish-button"
              onClick={() => updateProduct("PUBLISH")}
            >
              Publish Product
            </button>
          )}
        </div>
      </div>
      <div className="product-details-div">
        <div className="product-img">
          <ProductImage productItem={productItem} />
        </div>
        <div className="publish-details">
          <PublishedDetails productItem={productItem} />
        </div>
        <div className="total-order">
          <TotalOrder />
        </div>
        {/* <div className="view-order">
          <ViewsOrder />
        </div> */}
      </div>
      <div className="all-order-canceled">
        <div className="all-order-div">
          <AllOrders />
        </div>
        <div className="canceled-order-div">
          <Canceled />
        </div>
      </div>
      <div>{/* <ViewOrderTable /> */}</div>
    </div>
  );
};

export default ViewProduct;
