import React from "react";
import Cart from "../../../assets/shopping-cart.svg";

const Abandoned = ({crops,posts}) => {
  const c=crops?.length;
  const p=posts?.length;
  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={Cart} className="cart-image" />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="cart-title">Crops</p>
          <p className="cart-percentage">
           {c}
             {/* <span>+0.00%</span> */}
          </p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Posts</p>
          <p className="cart-percentage">{p}</p>
        </div>
      </div>
    </div>
  );
};

export default Abandoned;
