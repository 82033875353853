import React, { useEffect, useState, useContext } from "react";
import Plus from "../../assets/plus.svg";
import Allproducts from "../Dashboard/Layout/Allproducts";
import Rating from "./Layout/Rating";
import InventoryTable from "./Layout/InventoryTable";
import { Link } from "react-router-dom";
import { apiInstace } from "../server";
import DashboardContext from "../Context/DashboardContext";

const Inventory = () => {
  const [products, setProducts] = useState([]);
  const context = useContext(DashboardContext);
  const { setLoading } = context;

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const dataProducts = () => {
    setLoading(true);
    apiInstace
      .get(`/products/getproducts`)
      .then((res) => {
        setProducts(res.data.results); //.reverse()
        // console.log(res.data.results[0].crops);
        setFeaturedFirst(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    dataProducts();
  }, []);

  const [featuredFirst, setFeaturedFirst] = useState(true);

  const sortedByFeatured = products.sort((a, b) => {
    if (featuredFirst) {
      return (
        (b.is_featured ? 1 : 0) - (a.is_featured ? 1 : 0)
        // -     (new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
      );
    } else return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    {
      // return (a.is_featured ? 1 : 0) - (b.is_featured ? 1 : 0);
    }
  });

  return (
    <div>
      <div className='order-head'>
        <p className='order-title'>Inventory Summary</p>
        <Link to='/addproduct' state={{ id: -1, isEdit: false }} style={{ textDecoration: "none" }}>
          <button>
            <img alt='plus' src={Plus} />
            Add a New Product
          </button>
        </Link>
      </div>
      <div className='inventory-grid'>
        <div className='allproducts'>
          <Allproducts products={products} />
        </div>
        {/* <div className="rating">
          <Rating />
        </div> */}
      </div>
      <div>
        <InventoryTable
          products={sortedByFeatured}
          setProducts={setProducts}
          featuredFirst={featuredFirst}
          setFeaturedFirst={setFeaturedFirst}
        />
      </div>
    </div>
  );
};

export default Inventory;
