import React, { useEffect, useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Input, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import Filter from "../../../assets/sort.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import server, { apiInstace } from "../../server";
import { format } from "date-fns";
import DashboardContext from "../../Context/DashboardContext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { toast } from "react-toastify";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { State } from "country-state-city";
import { City } from "../../../utils/country-state-city";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function createData(
//   name,
//   address,
//   phone,
//   basketSize,
//   orderTotal,
//   dateTime,
//   status
// ) {
//   return {
//     name,
//     address,
//     phone,
//     basketSize,
//     orderTotal,
//     dateTime,
//     status,
//   };
// }

// const rows = [
//   createData(
//     "Cupcake",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "2",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Donut",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "4",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Eclair",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "1",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Frozen yoghurt",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "3",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),

//   createData(
//     "Gingerbread",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "9",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Honeycomb",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     8,
//     6.5,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Ice cream sandwich",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     37,
//     4.3,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Jelly Bean",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     94,
//     0.0,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "KitKat",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     65,
//     7.0,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Lollipop",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     98,
//     0.0,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Marshmallow",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     81,
//     2.0,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Nougat",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     9,
//     37.0,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Oreo",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     63,
//     4.0,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "user_role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "referral_code",
    numeric: false,
    disablePadding: false,
    label: "Referral Code",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: false,
    label: "Profit Made",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "Balance Amount",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const addnExcelCells = [
  {
    id: "aadhar_number",
    numeric: false,
    disablePadding: false,
    label: "Aaadhar_No",
  },
  {
    id: "pan_number",
    numeric: false,
    disablePadding: false,
    label: "PAN_No",
  },
  {
    id: "bank_account_number",
    numeric: false,
    disablePadding: false,
    label: "Account_no",
  },
  {
    id: "bank_ifsc_code",
    numeric: false,
    disablePadding: false,
    label: "IFSC_code",
  },
];

const addCell = [
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
];
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  // const [locationQuery, setLocationQuery] = useState({ city: "", state: "" });

  const query = props.query;
  const setQuery = props.setQuery;

  const roles = props.roles;
  const setRoles = props.setRoles;

  const nonZeroCust = props.nonZeroCust;
  const setNonZeroCust = props.setNonZeroCust;

  const locationQuery = props.locationQuery;
  const setLocationQuery = props.setLocationQuery;

  // const handlesearch = async (e) => {
  //   // const search_data=[];
  //   const validRoles = Boolean(roles.length);
  //   const validQuery = locationQuery.name != "";
  //   // if (Object.values(query).some((value) => value != "")) {
  //   // console.log(query);
  //   // console.log(props.orirows);
  //   let filterObj = {
  //     name: locationQuery.name.toLowerCase().replace(" ", ""),
  //     city: locationQuery.city.toLowerCase(),
  //     state: locationQuery.state.toLowerCase(),
  //   };

  //   let filteredRows = await props.orirows.filter((row) => {
  //     const roleMatch = validRoles ? roles.includes(row.role) : true;
  //     const balanceMatch = nonZeroCust ? row.balance_amount != 0 : true;
  //     const nameMatch = validQuery
  //       ? (row.first_name + row.last_name)
  //           ?.toLowerCase()
  //           ?.includes(locationQuery.name.toLowerCase().replace(" ", ""))
  //       : true;

  //     return balanceMatch && nameMatch && roleMatch;
  //   });

  //   if (locationQuery.state || locationQuery.city)
  //     filteredRows = filteredRows.filter((row) => {
  //       let tempAddress = props.address.find((add) => add.id == row.address[0]);

  //       if (tempAddress) {
  //         let stateMatch =
  //           locationQuery.state != "" ? tempAddress.state == locationQuery.state : true;
  //         let cityMatch = locationQuery.city != "" ? tempAddress.city == locationQuery.city : true;
  //         return stateMatch && cityMatch;
  //       } else return false;
  //     });

  //   // setRows(filteredRows);
  //   // console.log(filteredRows);
  //   props.setRows(filteredRows);
  // };

  const roleOptions = ["BDO", "DEALER", "USER"];
  // const [roles, setRoles] = useState([]);

  // useEffect(() => {
  //   // handlesearch();
  // }, [nonZeroCust, roles, query]);

  const toolbarItemStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "3%",
  };

  const [fetchedCities, setFetchedCities] = useState([]);

  useEffect(() => {
    const cityUp = async () =>
      setFetchedCities(
        (await City.getCitiesOfState("IN", locationQuery.state.split("-")[0])) ?? []
      );
    cityUp();
  }, [locationQuery.state]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
            Customers
          </Typography>
        )}
      </div>

      {/* <div> */}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
            margin: "0 16px",
          }}>
          {/* <div style={{ display: "flex",  }}> */}
          <div style={{ ...toolbarItemStyles, width: 210 }}>
            <p>Filter 0 balance users</p>
            <Checkbox
              onChange={(e) => {
                setNonZeroCust(e.target.checked);
              }}
            />{" "}
          </div>

          <FormControl size='small'>
            <InputLabel>Select Roles</InputLabel>
            <Select
              multiple
              value={roles}
              onChange={(e) => setRoles(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              sx={{ width: 150 }}
              label='Select Roles'>
              {roleOptions.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={toolbarItemStyles}>
            <FormControl sx={{ my: 1, width: "150px" }} size='small'>
              <InputLabel id='demo-name-label'>State</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={locationQuery?.state}
                label='State'
                onChange={(e) => {
                  setLocationQuery({ ...locationQuery, ["state"]: e.target.value });
                }}>
                {<MenuItem value={""}>All States</MenuItem>}
                {State.getStatesOfCountry("IN").map((item) => {
                  return (
                    <MenuItem value={[item.isoCode, item.name].join("-")}>{item.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ my: 1, width: "150px" }} size='small'>
              <InputLabel id='demo-name-label'>City</InputLabel>
              <Select
                value={locationQuery?.city}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='City'
                onChange={(e) => setLocationQuery({ ...locationQuery, ["city"]: e.target.value })}>
                <MenuItem value={""}>All Cities</MenuItem>
                {fetchedCities.map((item) => {
                  return <MenuItem value={item.name}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>

          {/* <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={handlesearch}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search by City'
                onChange={(e) => setQuery({ ...query, ["name"]: e.target.value })}
              />
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={handlesearch}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search by State'
                onChange={(e) => setQuery({ ...query, ["state"]: e.target.value })}
              /> */}
          <TextField
            style={{
              width: "240px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size='small'
            placeholder='Search Customer'
            onChange={(e) => setQuery(e.target.value)}
          />
          {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
          <DownloadTableExcel
            filename='customers table'
            sheet='customers'
            currentTableRef={props.tableRef.current}>
            <button className='table-head-buttons'> Export excel </button>
          </DownloadTableExcel>
          {/* </div> */}
        </Tooltip>
      )}
      {/* </div> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CustomerTable = (props) => {
  // console.log(props.rows);

  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  // const [active_users, setActive_users] = React.useState(0)
  const count = props.tot_users;
  const tableRef = useRef(null);

  const query = props.query;
  const setQuery = props.setQuery;

  const roles = props.roles;
  const setRoles = props.setRoles;

  const nonZeroCust = props.nonZeroCust;
  const setNonZeroCust = props.setNonZeroCust;

  const page = props.page;
  const setPage = props.setPage;

  const rowsPerPage = props.rowsPerPage;
  const setRowsPerPage = props.setRowsPerPage;

  const locationQuery = props.locationQuery;
  const setLocationQuery = props.setLocationQuery;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };
  const [address, setaddress] = useState([]);
  useEffect(() => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3ODgyMjg0LCJpYXQiOjE2ODc0MzU0OTMsImp0aSI6IjgyNmIxMGVlNzc2YTQwNzA4MjE2NmMwMDIzYjQzMTQyIiwidXNlcl9pZCI6OTB9.lsoyuOy800HQAPW35BY7SN5HiX6wQREE4e8Y1XKcKCM`,
      },
    };
    axios
      .get(`${server}/address/getAddress`, requestOptions)
      .then((res) => {
        setaddress(res.data.results);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [props.rows]);
  const GetAddress = ({ id }) => {
    const rowaddress = address.filter((item) => {
      return item.id == id;
    });
    // console.log(rowaddress);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <p>{rowaddress[0]?.city}</p> */}
        <p>{rowaddress[0]?.state}</p>
      </div>
    );
  };
  const GetAddress1 = ({ id }) => {
    const rowaddress = address.filter((item) => {
      return item.id == id;
    });
    // console.log(rowaddress);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>{rowaddress[0]?.city}</p>
        {/* <p>{rowaddress[0]?.state}</p> */}
      </div>
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(props.rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );
  // console.log(visibleRows);
  const handleDelete = (cid, index) => {
    if (cid >= 0) {
      console.log(cid);

      const requestOptions = {
        headers: {
          method: "DELETE",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      axios
        .delete(`${server}/users/deleteUser/${cid}/`, requestOptions)
        .then((res) => {
          console.log(res);
          toast.success("User Deleted! It may take few minutes to reflect the changes", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(index);
          // setLoading(false)
          document.querySelector(`.delbtn${index}`).disabled = true;
          handleClose();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [ind, setIndex] = useState();
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(props.row?.is_active);
  const [custid, setCustId] = useState(-1);
  const handleOpen = (id, index) => {
    setCustId(id);
    setIndex(index);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: 4,
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
  };

  const updateStatus = async (e, id) => {
    const checked = e.target.checked;
    try {
      setLoading(true);
      const data = await apiInstace.patch(`/users/updatestatus/${id}`, {
        is_active: checked ? "True" : "False",
      });
      if (data.status === 200) {
        props.rows.forEach((row) => {
          if (row.id === id) row.is_active = checked;
          if (checked) {
            props.setActive_users(props.active_users + 1);
          } else {
            props.setActive_users(props.active_users - 1);
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const GetAddnlAddData = ({ addID }) => {
    const matchAdd = address.find(({ id }) => id == addID);
    const invalidVals = ["", null, undefined];
    return [matchAdd?.address, matchAdd?.taluka, matchAdd?.gaon]
      .filter((field) => !invalidVals.includes(field))
      .join(", ");
  };

  return (
    <div className='order-table-main'>
      {props.rows ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'>
              <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Are you Sure, You want to Delete?
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <button
                    className='create-new-post-button'
                    style={{ margin: "10px 20px" }}
                    onClick={() => handleDelete(custid, ind)}>
                    Delete
                  </button>
                  <button
                    className='create-new-post-button'
                    style={{ margin: "10px 20px" }}
                    onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </Box>
            </Modal>
            <EnhancedTableToolbar
              numSelected={selected.length}
              rows={props.rows}
              setRows={props.setRows}
              orirows={props.orirows}
              tableRef={tableRef}
              address={address}
              roles={roles}
              setRoles={setRoles}
              query={query}
              setQuery={setQuery}
              nonZeroCust={nonZeroCust}
              setNonZeroCust={setNonZeroCust}
              locationQuery={locationQuery}
              setLocationQuery={setLocationQuery}
            />
            <TableContainer style={{ padding: "0px 20px" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}
                // ref={tableRef}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.rows.length}
                  headCells={headCells}
                />
                {/* rowsPerPage > 0
                    ? props.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : */}
                <TableBody>
                  {props.rows?.map((row, index) => {
                    // console.log("row",row)
                    // const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    if (row?.is_active == "true") props.setActive_users(props.active_users + 1);

                    return row.is_superuser ? (
                      <></>
                    ) : (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role='checkbox'
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        style={{ border: "1px solid transparent" }}>
                        <TableCell
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          style={{ border: "1px solid transparent" }}>
                          {/* <Link
                          to="/viewcustomer"
                          state={{data:row}}
                          style={{ textDecoration: "none" }}
                        > */}
                          {row.first_name}

                          {/* </Link> */}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.last_name}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.phone}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {/* {row.address[0]?.state?row.address[0].state:"---"} */}
                          <GetAddress id={row.address[row.address.length - 1]} />
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          <GetAddress1 id={row.address[row.address.length - 1]} />
                        </TableCell>

                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.role}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.referral_code}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.profits_made}
                        </TableCell>
                        <TableCell align='left' style={{ border: "1px solid transparent" }}>
                          {row.balance_amount}
                        </TableCell>
                        {/* <TableCell style={{ border: "1px solid transparent" }}>
                        {row.status === "Delivered" ? (
                          <p className="status-delivered">Delivered</p>
                        ) : (
                        {format(
                          new Date(row.date_joined),
                          "dd MMMM yyyy - hh:mm a"
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid transparent" }}>
                        {row.is_active ? (
                          <p className="status-active">Active</p>
                        ) : (
                          <p className="status-delivered">Delivered</p>
                        )}
                      </TableCell> */}
                        <TableCell
                          padding='none'
                          align='center'
                          style={{ border: "1px solid transparent" }}>
                          <button
                            className='edit-button'
                            onClick={() => {
                              //   console.log(row);
                              props.handleOpen(row.id);
                            }}>
                            Edit
                          </button>
                        </TableCell>
                        <TableCell style={{ border: "1px solid transparent" }}>
                          <label class='switch'>
                            <input
                              type='checkbox'
                              checked={row?.is_active}
                              onChange={(e) => updateStatus(e, row.id)}
                            />
                            <span class='slider round'></span>
                          </label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
              <Table
                sx={{ minWidth: 750, display: "none" }}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}
                ref={tableRef}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.rows.length}
                  headCells={headCells
                    .concat(addCell)
                    .filter(({ label }) => label != "Edit")
                    .concat(addnExcelCells)}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? props.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : props.rows
                  )?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    if (row?.is_active == "true") props.setActive_users(props.active_users + 1);
                    return row.is_superuser ? (
                      <></>
                    ) : (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.name}
                        sx={{ cursor: "pointer" }}>
                        <TableCell component='th' id={labelId} scope='row' padding='none'>
                          {row.first_name}
                        </TableCell>
                        <TableCell align='left'>{row.last_name}</TableCell>
                        <TableCell align='left'>{row.phone}</TableCell>
                        <TableCell align='left'>
                          <GetAddress id={row.address[row.address.length - 1]} />
                        </TableCell>
                        <TableCell align='left'>
                          <GetAddress1 id={row.address[row.address.length - 1]} />
                        </TableCell>

                        <TableCell align='left'>{row.role}</TableCell>
                        <TableCell align='left'>{row.referral_code}</TableCell>
                        <TableCell align='left'>{row.profits_made}</TableCell>
                        <TableCell align='left'>{row.balance_amount}</TableCell>
                        <TableCell>
                          <div>{row?.is_active ? "Active" : "In-Active"} </div>
                        </TableCell>
                        <TableCell align='left'>
                          <GetAddnlAddData addID={row.address[0]} />
                        </TableCell>
                        <TableCell align='left'>{}</TableCell>
                        {addnExcelCells.map(({ id }) => (
                          <TableCell align='left'>{row?.[id]}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component='div'
              count={count - 1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomerTable;
