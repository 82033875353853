import DashboardContext from "../../Context/DashboardContext";
import React, { useContext, useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { apiInstace } from "../../../Components/server";
import handleImageUpload from "../../Inventory/Utils/HandleImageUpload";
import { UploadRounded } from "@mui/icons-material";
import { toast } from "react-toastify";

const EditCropModal = ({ open1, setOpen1, data, getCrop }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffffff",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
    // height: "60vh",
    padding: 4,
  };

  const context = useContext(DashboardContext);
  const { setLoading } = context;

  const [crop_data_trans, setCrop_data_trans] = useState([]);

  const [new_crop, setNew_crop] = useState({
    1: {
      name: "",
      watering: "",
      cultivation: "",
      harvesting: "",
      labour: "",
      sunlight: "",
      ph_value: "",
      temperature: "",
      fertilization: "",
      // equipments:"",
      language: "1",

      description: "",
    },
    2: {
      name: "",
      watering: "",
      cultivation: "",
      harvesting: "",
      labour: "",
      sunlight: "",
      ph_value: "",
      temperature: "",
      fertilization: "",
      // equipments:"",
      language: "2",

      description: "",
    },
    3: {
      name: "",
      watering: "",
      cultivation: "",
      harvesting: "",
      labour: "",
      sunlight: "",
      ph_value: "",
      temperature: "",
      fertilization: "",
      // equipments:"",
      language: "3",

      description: "",
    },
    // type:"",
  });
  const [new_crop1, setNew_crop1] = useState({
    name: "",
    icon: "",
    image: "",
  });
  const [new_crop1update, setNew_crop1update] = useState({});

  const [images, setImages] = useState([]);
  const [icons, setIcons] = useState([]);

  //   useEffect(() => {
  //     const requestOptions = {
  //       headers: {
  //         method: "GET",
  //         Authorization: `Bearer ${localStorage.getItem("access")}`,
  //       },
  //     };
  //     if (id >= 0) {
  //       apiInstace
  //         .get(`/crops/getcrops`, requestOptions)
  //         .then((res) => {
  //           // console.log(res.data.results);
  //           const crops_data = [];
  //           res.data.results.map((item) => {
  //             if (item.id == id)
  //               crops_data.push({
  //                 name: item.name,
  //                 image: item.image,
  //                 icon: item.icon,
  //               });
  //           });
  //           //   console.log(crops_data);
  //           // setCrop_data([...crops_data])
  //           setNew_crop1({ ...crops_data[0] });
  //           setNew_crop1update({ ...crops_data[0] });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   }, [id]);

  useEffect(() => {
    setNew_crop1(data);
    setNew_crop1update(data);
  }, [data]);

  const handlesubmit1 = () => {
    // console.log(new_crop1);
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);

    if (data?.id ?? -1 >= 0) {
      let crop1_update = {};
      for (const key in new_crop1) {
        if (new_crop1update[key] != new_crop1[key])
          crop1_update = { ...crop1_update, [key]: new_crop1[key] };
      }
      if (Object.keys(crop1_update).length != 0) {
        console.log(crop1_update);
        apiInstace
          .patch(`/crops/updatecrop/${data.id}`, { crop1_update, token })
          .then((res) => {
            toast.success("Crop Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            // handleClose1();
            setLoading(false);
            getCrop();

            // console.log(res);
          })
          .catch((err) => {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setLoading(false);
            // console.log(err);
          });
      }
      setOpen1(false);
    } else {
      apiInstace
        .post(`/crops/createcrop`, { new_crop1, token })
        .then((res) => {
          setNew_crop({
            ...new_crop,
            [1]: { ...new_crop[1], crop: res.data.id },
            [2]: { ...new_crop[2], crop: res.data.id },
            [3]: { ...new_crop[3], crop: res.data.id },
          });
          setLoading(false);
          toast.success("Crop Created!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getCrop();
          handleClose1();

          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleClose1();

          console.log(err);
        });
    }
  };

  const handleupload = () => {
    const imginput = document.getElementById(`crop_img`);
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      //  console.log(file)
      if (!file) return;
      setImages([file]);
    };
    imginput.addEventListener("change", geturl);
  };

  useEffect(() => {
    open1 &&
      handleImageUpload({ images: images, setImages: setImages })
        .then((res) => {
          const img_url = res;
          // console.log(img_url);
          setNew_crop1({
            ...new_crop1,
            image: img_url,
          });
          document.querySelector(`.crop_img`).src = img_url;
        })
        .catch((err) => console.log(err));
  }, [images]);

  useEffect(() => {
    open1 &&
      handleImageUpload({ images: icons, setImages: setIcons })
        .then((res) => {
          const img_url = res;
          // console.log(img_url);
          setNew_crop1({
            ...new_crop1,
            icon: img_url,
          });
          document.querySelector(`.crop_icon`).src = img_url;
        })
        .catch((err) => console.log(err));
  }, [icons]);

  const handleupload1 = () => {
    const imginput = document.getElementById(`crop_icon`);
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      if (!file) return;
      setIcons([file]);
    };
    imginput.addEventListener("change", geturl);
  };

  return (
    //   open1 ? (
    <Modal
      open={open1}
      onClose={() => setOpen1(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
      <Box sx={style}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div className='modal-head krishi-modal-head'>
            <p className='modal-title'>
              {data?.id ?? -1 >= 0 ? <>Edit Crop</> : <> Create New Crop</>}
            </p>
            <button className='close-button-krishi' onClick={() => setOpen1(false)}>
              X
            </button>
          </div>
          <label>Base Name</label>
          <input
            className='product-input'
            placeholder='Enter Title/Name'
            value={new_crop1?.name}
            onChange={(e) => {
              setNew_crop1({ ...new_crop1, name: e.target.value });
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              className='flex-div'
              sx={{
                display: "flex",
                flexDirection: "column",
                my: 2,
                alignItems: "start",
              }}>
              <label>Crop Image</label>

              <img
                style={{ maxWidth: "3rem", maxHeight: "3rem" }}
                className='crop_img'
                src={new_crop1?.image}
                // alt= "Upload Image"
              />

              <button
                className='upload-logo-button'
                style={{ marginTop: "10px" }}
                onClick={() => handleupload()}>
                <input id='crop_img' type='file' accept='image/*' hidden />
                {/* Upload Image */}
                <UploadRounded />
              </button>
            </Box>
            <Box
              className='flex-div'
              sx={{
                display: "flex",
                flexDirection: "column",
                my: 2,
                alignItems: "start",
              }}>
              <label>Crop Icon</label>

              <img
                style={{ maxWidth: "3rem", maxHeight: "3rem" }}
                className='crop_icon'
                src={new_crop1?.icon}
              />

              <button
                className='upload-logo-button'
                style={{ marginTop: "10px" }}
                onClick={() => handleupload1()}>
                <input id='crop_icon' type='file' accept='image/*' hidden />
                {/* Upload Icon */}
                <UploadRounded />
              </button>
            </Box>
          </div>
          <button onClick={handlesubmit1} className='upload-logo-button'>
            Save and Publish
          </button>
        </Box>
      </Box>
    </Modal>
    //   ) : (
    //     <></>
  );
};

export default EditCropModal;
