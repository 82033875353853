import React from 'react'
import NetworkTable from './Layout/NetworkTable'
function Network() {
  return (
    <div>
        <NetworkTable />
    </div>
  )
}

export default Network