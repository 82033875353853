import React, { useState } from "react";
import BagBlack from "../../../assets/Bag-black.svg";

const Customers = (props) => {
  // const customers = props.customers;
  // let c=0;
  // let d = 0;
  // customers?.map((item) => {
  //   if (item.is_active) d++;
  // });
  // setActive(c);
  return (
    <div className='abandoned-main'>
      <div className='abandoned-div'>
        <img alt='cart-img' src={BagBlack} className='cart-image' />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className='abandoned-body'>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Customers</p>
          <p className='cart-percentage'>{props.customersData?.count - 1}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Active</p>
          <p className='cart-percentage'>{props.activeUsersCount - 1}</p>
        </div>
        {/* <div className="abandoned-body-item">
          <p className="abandoned-body-title">Delivered</p>
          <p className="cart-percentage">
            {c}<span>+0.00%</span>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Customers;
