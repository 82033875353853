import { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  alpha,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  useTheme,
  Switch,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
import Calender from "../../../assets/Calendar.svg";
import Send from "../../../assets/Send.svg";
import ProdcutImg from "../../../assets/productImg.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiInstace } from "../../server";
import server from "../../server";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
// import axios from "axios";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import DashboardContext from "../../Context/DashboardContext";
import { Modal } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";

import { multiSelect } from "../Utils/styles";

function createData(name, productName, category, unitPrice, discount, totalValue, status) {
  return {
    name,
    productName,
    category,
    unitPrice,
    discount,
    totalValue,
    status,
  };
}

const rows = [
  createData(
    ProdcutImg,
    "Swasti Auto Soil Drenching Device",
    "Drenching",
    "$2000.00",
    "50%",
    "$3000",
    "Published"
  ),
  createData(ProdcutImg, "iPhone 12 Pro", "Gadgets", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Unpublished"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Unpublished"),

  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Unpublished"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
  createData(ProdcutImg, "Polo T-Shirt", "Fashion", "$2000.00", "$0.00", "$3000", "Published"),
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Img",
  },
  {
    id: "productName",
    numeric: true,
    disablePadding: false,
    label: "Product Name",
  },
  // {
  //   id: "category",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Category",
  // },
  {
    id: "unitPrice",
    numeric: true,
    disablePadding: false,
    label: "Unit Price",
  },
  // {
  //   id: "discount",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Discount",
  // },
  // {
  //   id: "totalValue",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Total Value",
  // },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  //   {
  //     id: "edit",
  //     numeric: true,
  //     disablePadding: false,
  //     label: " ",
  //   },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const handlesearch = async (name) => {
    if (name != "") {
      const filteredRows = await props.products.filter((row) => {
        return row.name.toLowerCase()?.includes(name.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setIProducts(filteredRows);
    } else props.setIProducts(props.products);
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant='h6' id='tableTitle' component='div'>
            Inventory Items
          </Typography>
        )}
      </div>
      <div className='flex-div'>
        {" "}
        {/* <div className='filter-div'>
          <Multiselect
            options={props?.crops?.results}
            displayValue='name'
            placeholder='Select Crop'
            showArrow
            onSelect={props?.handleCropsFilter}
            onRemove={props?.handleCropsFilter}
            style={{
              searchBox: {
                border: "none",
                "border-radius": "8px 0px 0px 8px",
              },
              searchWrapper: {
                border: "none",
                "border-radius": "8px 0px 0px 8px",
              },
              inputField: {
                padding: "5px 0px 5px 15px",
                width: "150px",
                fontSize: "16px",
              },
            }}
          />
          <Multiselect
            options={props?.equipment?.results}
            displayValue='name'
            placeholder='Equipment'
            showArrow
            onSelect={props?.handleFarmEquipmentFilter}
            onRemove={props?.handleFarmEquipmentFilter}
            style={{
              searchBox: {
                border: "none",
                "border-left": "1px solid #5e6366",
                "border-radius": "0px",
              },
              searchWrapper: {
                border: "none",
              },
              inputField: {
                padding: "5px 0px 5px 15px",
                width: "150px",
                fontSize: "16px",
              },
            }}
          />
          <Multiselect
            options={props?.brand?.results}
            displayValue='name'
            placeholder='Brand'
            showArrow
            onSelect={props?.handleBrandFilter}
            onRemove={props?.handleBrandFilter}
            style={{
              searchBox: {
                border: "none",
                "border-left": "1px solid #5e6366",
                "border-radius": "0px 8px 8px 0px",
              },
              searchWrapper: {
                border: "none",
                "border-radius": "0px 8px 8px 0px",
              },
              inputField: {
                padding: "5px 0px 5px 15px",
                width: "150px",
                fontSize: "16px",
              },
            }}
          />
        </div> */}
        <div>
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip sx={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <TextField
                  style={{
                    width: "250px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  placeholder='Search by Product Name'
                  onChange={(e) => handlesearch(e.target.value)}
                />{" "}
                <DownloadTableExcel
                  filename='Inventory Table'
                  sheet='Inventory'
                  currentTableRef={props.tableRef.current}>
                  <button className='table-head-buttons'> Export excel </button>
                </DownloadTableExcel>
                {/* <div style={{ display: "flex" }}> */}
                {/* <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Send} />
                  <p className="filter-text">Share</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const InventoryTable = ({ products, setProducts, featuredFirst, setFeaturedFirst }) => {
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [crops, setCrops] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [brand, setBrand] = useState([]);
  const [brandOptions, setBrandOptions] = useState([] || "");
  const [cropFilter, setCropFilter] = useState();
  const [iProducts, setIProducts] = useState(products);

  // const theme = useTheme();
  // const [personName, setPersonName] = useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   // setPersonName(
  //   //   // On autofill we get a stringified value.
  //   //   typeof value === "string" ? value.split(",") : value
  //   // );
  // };

  //   console.log(products);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const dataCrops = () => {
    apiInstace
      .get(`/crops/getcrops`)
      .then((res) => {
        setCrops(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataEquipment = () => {
    apiInstace
      .get(`/equipment/getequipment`)
      .then((res) => {
        setEquipment(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataBrand = () => {
    apiInstace
      .get(`/brands/getbrands`, requestOptions)
      .then((res) => {
        setBrand(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  const discount = (row) => {
    let per = ((row?.base_price - row?.price) / row?.base_price) * 100;
    return per.toFixed(2);
  };

  // const handleCropsFilter = (selectedList, selectedItem) => {
  //   if (selectedList.length === 0) {
  //     setIProducts(products);
  //     return;
  //   }
  //   setCropFilter(selectedItem.id);
  //   let filteredCrops = [];
  //   selectedList.map((item) => {
  //     filteredCrops = [...filteredCrops, ...products?.filter((pro) => item.id === pro.crops[0])];
  //   });

  //   setIProducts(filteredCrops);
  // };

  // const handleFarmEquipmentFilter = (selectedList, selectedItem) => {
  //   if (selectedList.length === 0) {
  //     setIProducts(products);
  //     return;
  //   }
  //   setCropFilter(selectedItem.id);
  //   let filteredEquipment = [];
  //   selectedList.map((item) => {
  //     filteredEquipment = [
  //       ...filteredEquipment,
  //       ...products?.filter((pro) => item.id === pro.farm_equipments[0]),
  //     ];
  //   });
  //   setIProducts(filteredEquipment);
  // };

  // const handleBrandFilter = (selectedList, selectedItem) => {
  //   if (selectedList.length === 0) {
  //     setIProducts(products);
  //     return;
  //   }
  //   console.log(selectedList);
  //   setCropFilter(selectedItem.id);
  //   let filteredBrands = [];
  //   selectedList.map((item) => {
  //     filteredBrands = [...filteredBrands, ...products?.filter((pro) => item.id === pro.brands[0])];
  //   });
  //   setIProducts(filteredBrands);
  // };

  const [filter, setFilter] = useState({ crops: [], farm_equipments: [], brands: [] });

  useEffect(() => {
    let filtered = [...products];
    Object.entries(filter).forEach(([filterKey, filterValue]) => {
      if (filterValue.length)
        filtered = filtered.filter((product) =>
          product[filterKey].some((element) => filterValue.map(({ id }) => id).includes(element))
        );
    });
    setIProducts(filtered);

    !Object.entries(filter).some(([key, value]) => value.length) && setIProducts(products);
  }, [filter]);

  useEffect(() => {
    setIProducts(products);
  }, [products]);

  useEffect(() => {
    dataCrops();
    dataEquipment();
    dataBrand();
  }, []);

  const [status, setStatus] = useState({});
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // useEffect(() => {
  //   console.log("status", status);
  // }, [status]);

  // useEffect(() => {
  //   let status = {};
  //   iProducts?.map((item, index) => {
  //     status = { ...status, [index]: item.status };
  //   });
  //   // console.log(status);
  //   setStatus({ ...status });
  // }, [iProducts]);
  // useEffect(()=>{
  //   console.log(status);
  // },[status])
  const handleStatus = (key, value, id) => {
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);
    const newProduct = { status: value };
    apiInstace
      .patch(`/products/updateProduct/${id}`, { newProduct })
      .then((res) => {
        // console.log(res);
        toast.success("Status Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
    // navigate("/content")

    setStatus({ ...status, [id]: value });
  };
  const handleDelete = (orderid) => {
    if (orderid >= 0) {
      console.log(orderid);

      const requestOptions = {
        headers: {
          method: "DELETE",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      };
      apiInstace
        .delete(`/products/deleteProduct/${orderid}/`)
        .then((res) => {
          console.log(res);
          toast.success("Product Deleted! It may take few minutes to reflect the changes", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false)
          handleClose1();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [open1, setOpen1] = useState(false);
  const [prodid, setprodid] = useState(-1);
  const handleOpen1 = (id) => {
    setprodid(id);
    setOpen1(true);
  };
  const handleClose1 = () => setOpen1(false);

  const tableRef = useRef(null);

  // useEffect(() => {
  //   console.log("featuredFirst", featuredFirst);
  //   setIProducts(
  //     iProducts.sort((a, b) => {
  //       if (featuredFirst) {
  //         return (b.is_featured ? 1 : 0) - (a.is_featured ? 1 : 0);
  //       } else {
  //         return (a.is_featured ? 1 : 0) - (b.is_featured ? 1 : 0);
  //       }
  //     })
  //   );
  // }, [featuredFirst]);

  return (
    <div className='order-table-main'>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Are you Sure, You want to Delete?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={() => handleDelete(prodid)}>
                  Delete
                </button>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={handleClose1}>
                  Cancel
                </button>
              </div>
            </Box>
          </Modal>
          <EnhancedTableToolbar
            numSelected={selected.length}
            products={products}
            setIProducts={setIProducts}
            tableRef={tableRef}
            crops={crops}
            // handleCropsFilter={handleCropsFilter}
            equipment={equipment}
            // handleFarmEquipmentFilter={handleFarmEquipmentFilter}
            brand={brand}
            // handleBrandFilter={handleBrandFilter}
          />
          <div className='filter-div' style={{ gap: 16, alignItems: "center" }}>
            <Multiselect
              options={crops?.results}
              displayValue='name'
              placeholder='Select Crop'
              showArrow
              onSelect={(selectedList, selectedItem) =>
                setFilter((prev) => ({ ...prev, crops: selectedList }))
              }
              onRemove={(selectedList, selectedItem) =>
                setFilter((prev) => ({ ...prev, crops: selectedList }))
              }
              style={multiSelect}
            />
            <Multiselect
              options={equipment?.results}
              displayValue='name'
              placeholder='Equipment'
              showArrow
              onSelect={(selectedList, selectedItem) =>
                setFilter((prev) => ({ ...prev, farm_equipments: selectedList }))
              }
              onRemove={(selectedList, selectedItem) =>
                setFilter((prev) => ({ ...prev, farm_equipments: selectedList }))
              }
              style={multiSelect}
            />
            <Multiselect
              options={brand?.results}
              displayValue='name'
              placeholder='Brand'
              showArrow
              onSelect={(selectedList, selectedItem) =>
                setFilter((prev) => ({ ...prev, brands: selectedList }))
              }
              onRemove={(selectedList, selectedItem) =>
                setFilter((prev) => ({ ...prev, brands: selectedList }))
              }
              style={multiSelect}
            />
            <div>
              Featured First:{" "}
              <Switch
                checked={featuredFirst}
                onChange={(e) => setFeaturedFirst(e.target.checked)}
              />
            </div>
          </div>

          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* .filter((crop) => crop.crops[0] === cropFilter) */}
                {(rowsPerPage > 0
                  ? iProducts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : iProducts
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //  console.log(row?.image.split(",")[0]);
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}>
                      <TableCell component='th' id={labelId} scope='row' padding='none'>
                        <img
                          alt='productImg'
                          src={row?.image.split(",")[0]}
                          style={{ width: "50px" }}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        {/* <Link
                          to="/viewproduct"
                          style={{ textDecoration: "none" }}
                          state={row}
                        > */}
                        {row?.is_featured && (
                          <span className='status-published' style={{ margin: "8px" }}>
                            Featured
                          </span>
                        )}
                        <span style={{ lineHeight: 2 }}>{row?.name}</span> {/* </Link> */}
                      </TableCell>
                      {/* <TableCell
                        align="left"
                        
                      >
                        Drenching
                      </TableCell> */}
                      <TableCell align='left'>{row?.base_price}</TableCell>
                      {/* <TableCell
                        align="left"
                        
                      >
                        {discount(row)}%
                      </TableCell>
                      <TableCell
                        align="left"
                        
                      >
                        {row?.price.toFixed(2)}
                      </TableCell> */}
                      <TableCell>
                        {/* {row.status === "PUBLISH" ? (
                          <select className="status-published">
                            <option>Published</option>
                            <option>Unpublished</option>
                          </select>
                        ) : (
                          <select className="status-unpublished">
                            <option>Unpublished</option>
                            <option>Published</option>
                          </select>
                        )} */}
                        <select
                          value={status?.[row.id] || row.status}
                          className='status-published'
                          onChange={(e) => handleStatus(index, e.target.value, row.id)}>
                          <option value='PUBLISH'>PUBLISHED</option>
                          <option value='UNPUBLISH'>UNPUBLISH</option>
                        </select>
                      </TableCell>
                      <TableCell padding='none' align='center'>
                        <Link to='/addproduct' state={{ id: row.id, data: row, isEdit: true }}>
                          <button className='edit-button'>Edit</button>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <DeleteIcon onClick={() => handleOpen1(row.id)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height:
                        (dense ? 33 : 53) *
                        (rowsPerPage -
                          iProducts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .length -
                          1),
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
            <Table
              sx={{ minWidth: 750, display: "none" }}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}
              ref={tableRef}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? iProducts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : iProducts
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}>
                      <TableCell component='th' id={labelId} scope='row' padding='none'>
                        {row?.image.split(",")[0]}
                      </TableCell>
                      <TableCell align='left'>{row?.name}</TableCell>

                      <TableCell align='left'>{row?.base_price}</TableCell>

                      <TableCell>{status[index] ? "PUBLISHED" : "UNPUBLISHED"}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height:
                        (dense ? 33 : 53) *
                        (rowsPerPage -
                          iProducts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .length),
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component='div'
            count={iProducts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default InventoryTable;
